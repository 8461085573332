import { Paper } from "@mui/material";

export const customStyles = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide the indicator separator
  }),
  control: (provided, state) => ({
    ...provided,
    background: state.isDisabled ? "#F5F6F7" : "transparent",
    height: "56px",
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? "black" : "",
  }),
  option: (provided, state) => ({
    ...provided,
    // backgroundColor: state.isSelected ? "#e0eefb" : "white", // Change background color of the selected option
    backgroundColor: state.isSelected
      ? "#e0eefb"
      : state.isFocused
      ? "#e5e3e3"
      : "white", // Change background color of the selected option
    color: state.isSelected ? "black" : "inherit", // Change text color of the selected option
    cursor: "pointer",
    // position: "absolute",
    // height: "10px",
    "&:hover": {
      // backgroundColor: "#ebe8e8",
      cursor: "pointer",
    },
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
    // position: "absolute",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    // top: "auto",
    borderRadius: "4px",
    marginTop: "0",
    border: "1px solid #8080801c",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  }),
};

export const errorCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "transparent",
    height: "56px",
    border: "1px solid red",
  }),
};
export const phoneTypeCustomStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#e0eefb" : "white", // Change background color of the selected option
    color: "rgba(0, 0, 0, 0.6)", // Change text color of the selected option
    // position: "absolute",
    // height: "10px",
    "&:hover": {
      backgroundColor: "#ebe8e8",
      cursor: "pointer",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "rgba(0, 0, 0, 0.6)", // Change text color of the selected option
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    // top: "auto",
    borderRadius: "4px",
    marginTop: "0",
    border: "1px solid #8080801c",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    cursor: "pointer",
  }),
};

// For MUI autocomplete
export const CustomPaper = (props) => {
  return <Paper elevation={10} {...props} />;
};
