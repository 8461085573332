import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TextField,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./ServiceOffered.css";
import styled from "@emotion/styled";
import ServiceTable from "./Table";
import ServiceModal from "../../Sidebar/ServiceModal";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddNewServiceModal from "../../modal/AddNewServiceModal";
import { useDispatch, useSelector } from "react-redux";
import { getServiceCategory } from "../../../redux/Account/ServiceOffered/action";
import { ClearIcon } from "@mui/x-date-pickers";
import SuccessMessageModal from "../../Common/SuccessMessage";
import { serviceCategoryData } from "../../../redux/Estimate/action";
import Loader from "../../Common/Loader";

const HeaderItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: 3,
  paddingTop: 8,
  // textAlign: "center",
  // color: theme.palette.text.secondary,
  flexGrow: 1,
  border: 0,
  boxShadow: "none",
}));

const BodyItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: "24px",
  paddingTop: 8,
  // textAlign: "center",
  // color: theme.palette.text.secondary,

  flexGrow: 1,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const ServiceOffered = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [serachInputValue, setSerachInputValue] = useState("");

  const [selectedPriceType, setSelectedPriceType] = useState([]);

  const [showLoader, setShowLoader] = useState(true);

  // const [selectedPrice, setSelectedPrice] = useState({
  //   no: false,
  //   fixed: false,
  //   range: false,
  // });

  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEl(null);
  };

  const [anchorElss, setAnchorElss] = React.useState(null);
  const openss = Boolean(anchorElss);
  const handleClickss = (event) => {
    setAnchorElss(event.currentTarget);
  };
  const handleClosess = () => {
    setAnchorElss(null);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleDelete = (value) => {
    // Filter out the selected ID that needs to be deleted
    const updatedSelectedIds = selectedIds.filter((id) => id !== value);

    // Update the state with the new array of selected IDs
    setSelectedIds(updatedSelectedIds);
  };

  const handleDeletePriceType = (value) => {
    // Filter out the selected ID that needs to be deleted
    const updatedSelectedPrice = selectedPriceType.filter(
      (dataName) => dataName !== value
    );

    // Update the state with the new array of selected IDs
    setSelectedPriceType(updatedSelectedPrice);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getServiceCategories();
  }, []);

  const getServiceCategories = async () => {
    setShowLoader(true);
    let res = await dispatch(serviceCategoryData());
    setShowLoader(false);
  };
  const Servicecategories = useSelector(
    (state) => state?.estimate?.serviceData
  );

  const handleMenuItemClick = (selectedItemId) => {
    const isSelected = selectedIds.includes(selectedItemId);

    // If selected, remove from the array; otherwise, add to the array
    const updatedIds = isSelected
      ? selectedIds.filter((id) => id !== selectedItemId)
      : [...selectedIds, selectedItemId];

    // Update the state with the new array of selected IDs
    setSelectedIds(updatedIds);
  };

  const handleMenuItemClickPrice = (priceType) => {
    const isSelected = selectedPriceType.includes(priceType);

    // If selected, remove from the array; otherwise, add to the array
    const updatedSelection = isSelected
      ? selectedPriceType.filter((type) => type !== priceType)
      : [...selectedPriceType, priceType];

    // Update the state with the new array of selected price types
    setSelectedPriceType(updatedSelection);
  };

  const debounceOnSearchChange = React.useCallback(
    debounce((value) => {
      setSearchValue(value?.target?.value);
    }, 800),
    []
  );

  if (showLoader) {
    return <Loader showLoader={showLoader} />;
  }

  return (
    <>
      <Box
        sx={{ textAlign: "left", justifyContent: "left" }}
        // padding={"24px 24px 24px 10px"}
      >
        <Stack spacing={4} sx={{ marginTop: "32px" }}>
          <HeaderItem>
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <Grid
                item
                lg={2}
                alignItems={"center"}
                style={{ display: "flex", justifyContent: "start" }}
              >
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={opens ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={opens ? "true" : undefined}
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClick}
                    style={{ color: "black" }}
                  >
                    <Typography fontSize={"14px"} fontWeight={500}></Typography>
                    SELECT CATEGORY
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={opens}
                    onClose={handleCloses}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    style={{ height: "400px" }}
                  >
                    <MenuItem>
                      {" "}
                      <Typography className="title-500-16">Category</Typography>
                    </MenuItem>
                    {Array.isArray(
                      Servicecategories.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                    ) &&
                      Servicecategories?.map((data, index) => (
                        <MenuItem
                          key={data._id}
                          // onClick={(e) => {
                          //   handleMenuItemClick(data._id);
                          // }}
                          className="menuitems-wrapper"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedIds.includes(data._id)}
                                onClick={(e) => {
                                  handleMenuItemClick(data._id);
                                }}
                              />
                            }
                            label={
                              <div className="filter-menus">{data.name}</div>
                            }
                          />
                        </MenuItem>
                      ))}
                  </Menu>
                </div>
              </Grid>
              <Grid
                item
                lg={2}
                style={{ display: "flex", justifyContent: "start" }}
              >
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={openss ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openss ? "true" : undefined}
                    endIcon={<ExpandMoreIcon />}
                    onClick={handleClickss}
                    style={{ color: "black" }}
                  >
                    <Typography fontSize={"14px"} fontWeight={500}></Typography>
                    Select PRICE TYPE
                  </Button>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElss}
                    open={openss}
                    onClose={handleClosess}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem>
                      <Typography className="title-500-16">
                        Select Price Type
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      // onClick={() => handleMenuItemClickPrice("no_price")}
                      className="menuitems-wrapper"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPriceType.includes("no_price")}
                            onClick={() => handleMenuItemClickPrice("no_price")}
                          />
                        }
                        label={<div className="filter-menus">No Price</div>}
                      />
                    </MenuItem>
                    <MenuItem className="menuitems-wrapper">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPriceType.includes("fixed")}
                            onClick={() => handleMenuItemClickPrice("fixed")}
                          />
                        }
                        label={<div className="filter-menus">Fixed Price</div>}
                      />
                    </MenuItem>
                    <MenuItem className="menuitems-wrapper">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPriceType.includes("range")}
                            onClick={() => handleMenuItemClickPrice("range")}
                          />
                        }
                        label={<div className="filter-menus">Price Range</div>}
                      />
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
              <Grid item lg={8}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-search">
                    Search
                  </InputLabel>
                  <OutlinedInput
                    style={{ lineHeight: "24px" }}
                    id="outlined-adornment-search"
                    placeholder="Name, category, etc..."
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        {searchValue && (
                          <IconButton
                            edge="end"
                            onClick={() => {
                              setSearchValue("");
                              setSerachInputValue("");
                            }}
                            tabIndex={-1}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    }
                    label="Search"
                    value={serachInputValue}
                    onChange={(e) => {
                      debounceOnSearchChange(e);
                      setSerachInputValue(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </HeaderItem>
          {selectedIds.length > 0 || selectedPriceType.length > 0 ? (
            <Grid style={{ display: "flex" }}>
              <Typography
                variant="body1"
                className="title-500-20"
                marginRight={1}
              >
                Filters:
              </Typography>
              {selectedIds.map((data, index) => {
                return (
                  <Chip
                    label={
                      [...Servicecategories].filter((row) => {
                        return row._id == data;
                      })[0].name
                    }
                    value={data}
                    // onClick={handleClickk}
                    onDelete={() => handleDelete(data)} // Pass the value to handleDelete
                    deleteIcon={
                      <CloseIcon style={{ color: "white", width: "20px" }} />
                    } // Change the delete icon here
                    style={{
                      borderRadius: "5px",
                      marginRight: "4px",
                      color: "white",
                    }} // Change the background color here
                    sx={{
                      backgroundColor: `${
                        index % 2 == 0
                          ? "#BA68C8"
                          : index % 3 == 0
                          ? "#FF9800"
                          : "#34C759"
                      }`,
                    }}
                  />
                );
              })}

              {selectedPriceType.map((data, index) => {
                return (
                  <Chip
                    label={
                      data == "no_price"
                        ? "No Price"
                        : data == "fixed"
                        ? "Fixed Price"
                        : "Price Range"
                    }
                    value={data}
                    // onClick={handleClickk}
                    onDelete={() => handleDeletePriceType(data)} // Pass the value to handleDelete
                    deleteIcon={
                      <CloseIcon style={{ color: "white", width: "20px" }} />
                    } // Change the delete icon here
                    style={{
                      borderRadius: "5px",
                      marginRight: "4px",
                      color: "white",
                    }} // Change the background color here
                    sx={{
                      backgroundColor: `${
                        index % 2 == 0
                          ? "#BA68C8"
                          : index % 3 == 0
                          ? "#FF9800"
                          : "#34C759"
                      }`,
                    }}
                  />
                );
              })}

              <Typography
                className="title-500-13 reset-filters"
                onClick={() => {
                  setSelectedIds([]);
                  setSelectedPriceType([]);
                }}
                marginLeft={1}
              >
                RESET FILTER
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          <BodyItem>
            <Grid container boxShadow={0}>
              <Grid
                container
                padding={"24px 0px 24px 0px"}
                alignItems={"center"}
                item
                direction="row"
                spacing={2}
                paddingInline={3}
                justifyContent={"space-between"}
              >
                <Grid
                  item
                  xs={4}
                  md={4}
                  xl={4}
                  style={{
                    fontWeight: "400",
                    fontSize: "24px",
                    lineHeight: "32.02px",
                    paddingLeft: "0px",
                  }}
                >
                  Services Offered
                </Grid>

                <Grid item>
                  <Button
                    className="btn-text-size-manage"
                    variant="contained"
                    color="secondary"
                    // className="primaryButton"
                    //  style={{ backgroundColor: "#2196F3" }}
                    onClick={handleOpen}
                  >
                    Add New Service
                  </Button>
                  <AddNewServiceModal
                    open={open}
                    handleClose={handleClose}
                    openFor="add"
                    reFetchParentDetails={getServiceCategories}
                    // getShopServicesListing={getShopServicesListings}
                  />
                </Grid>
              </Grid>
              <HeaderItem>
                <ServiceTable
                  selectedCategory={selectedIds}
                  selectedPriceType={selectedPriceType}
                  searchValue={searchValue}
                  selectedIds={selectedIds}
                />
              </HeaderItem>
            </Grid>
          </BodyItem>
        </Stack>
      </Box>
      <ServiceModal />
    </>
  );
};
export default ServiceOffered;

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];
