import { Visibility, VisibilityOff } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { handleLogin } from "../../redux/Auth/action";
import "./LoginPage.css";
// import { Link } from "react-router-dom";
import { backToApex } from "../../config/dataService/dataService";
import { displayError } from "../../utility/utilityFunctions";
import logo from "../../assets/Logo.svg";

const invalidPassword = {
  color: "#d32f2f",
  fontSize: "14px",
};
const LoginPage = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  // const [, setAlert] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onSubmit = async (values) => {
    values.email = values.email?.trim();
    setShowLoader(true);
    let result = await dispatch(handleLogin(values));
    if (result.status == 200) {
      //FIRST TIME LOGIN
      if (result.data.data.isFirstTimeLogin) {
        navigate(`/update-password/${localStorage.getItem("apex-saas_token")}`);
      } else {
        const authData = JSON.parse(localStorage.getItem("apex-saas_details"));
        // authData?.allowedPlatforms &&
        // authData?.allowedPlatforms.find((d) => d == "web")
        //   ? navigate("/dashboard")
        //   : navigate("/");
        navigate("/");
        setShowError(false);
      }
    } else {
      if (result?.data.message == "Invalid Password") {
        setShowError(true);
      } else {
        displayError(result?.data?.message);
      }
    }
    setShowLoader(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [alert, setAlert] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const alertState = localStorage.getItem("alert");

  setTimeout(() => {
    localStorage.removeItem("alert");
  }, 5000);

  return (
    <>
      <div id="auth_container">
        <div className="auth_logo_container">
          <img src={logo} alt="logo" />
        </div>

        <div className="auth_component_container">
          {alertState && (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                fontSize: "14px",
                backgroundColor: "#EDF7ED",
                padding: "8px 16px",
                color: "rgba(30, 70, 32, 1)",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 400,
              }}
            >
              <AlertTitle>Password Reset Successful!</AlertTitle>
              Your new password is all set! Please log in again to get back to
              managing your repair shop!
            </Alert>
          )}
          <div className="formTitle"> Log In </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box component="form" noValidate autoComplete="off">
              <div className="email">
                <Typography
                  fontSize={"14px"}
                  fontWeight={500}
                  lineHeight={"150%"}
                  fontStyle={"normal"}
                  color={"#000"}
                  sx={{ marginBottom: "8px" }}
                >
                  Email<span className="asterik">*</span>
                </Typography>

                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      className="auth_input_field"
                      sx={{ height: "48px" }}
                      required
                      id="outlined"
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                      {...field}
                      onKeyPress={handleKeyPress}
                    />
                  )}
                />
              </div>

              <div className="password">
                <Typography
                  fontSize={"14px"}
                  fontWeight={500}
                  lineHeight={"150%"}
                  fontStyle={"normal"}
                  color={"#000"}
                  sx={{ marginBottom: "8px" }}
                >
                  Password<span className="asterik">*</span>
                </Typography>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: "Password is required" }}
                  render={({ field }) => (
                    <TextField
                      required
                      className="auth_input_field"
                      sx={{ height: "48px" }}
                      variant="outlined"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      onChange={() => setShowError(false)}
                      // <-- This is where the magic happens
                      // onChange={someChangeHandler}

                      error={!!errors.password}
                      helperText={
                        errors.password ? errors.password.message : ""
                      }
                      {...field}
                      onKeyPress={handleKeyPress}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                {showError && (
                  <Typography style={invalidPassword}>
                    Invalid Password
                  </Typography>
                )}
              </div>
            </Box>
            <div className="forgot">
              <Link
                onClick={() => navigate("/forgot-password")}
                to={"/forgot-password"}
                variant="body1"
                fontWeight={500}
                className="forgotPassLink"
              >
                Forgot Password?
              </Link>
            </div>
            <Button
              className="log_in_btn"
              variant="contained"
              color="secondary"
              type="submit"
              disableElevation
              fullWidth
            >
              Log In
            </Button>
          </form>
          <Box className="policy">
            <Typography
              variant="subtitle1"
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"175%"}
              fontStyle={"normal"}
              color={"#000"}
              letterSpacing={"0.15px"}
            >
              By signing in, you agree to our&nbsp;
              <span
                className="links"
                onClick={() =>
                  window.location.replace(
                    "https://apexmechanic.com/terms-and-conditions/"
                  )
                }
              >
                Terms and Conditions
              </span>
              <span>{", and "}</span>
              <span
                className="links"
                onClick={() =>
                  window.location.replace(
                    "https://apexmechanic.com/privacy-policy/"
                  )
                }
              >
                Privacy Policy.
              </span>
            </Typography>
          </Box>

          <Box className="sign_up">
            <Typography
              variant="subtitle1"
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"175%"}
              fontStyle={"normal"}
              color={"#000"}
              letterSpacing={"0.15px"}
              textAlign={"center"}
            >
              New to Apex Mechanic?{" "}
              <Link
                variant="body1"
                sx={{
                  fontWeight: "medium",
                  color: "primary",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
          <Dialog fullScreen={fullScreen} open={showLoader}>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
