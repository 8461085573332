import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import "./style.css";
import { styled } from "@mui/material";
import ServiceOffered from "./ServiceOffered/ServiceOffered";
import ShopDetails from "./ShopDetails/ShopDetails";
import Billing from "./Billing/Billing";
import PasswordAndSecurity from "./PasswordAndSecurity/PasswordAndSecurity";
import Notification from "./Notification/Notification";
import ShopHours from "./ShopHours/ShopHours";
import { REQUIRED_RED } from "./../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Shop() {
  const [value, setValue] = React.useState(0);
  const { pathname } = useLocation();

  const navigate = useNavigate();
  console.log("pathname", pathname);

  useEffect(() => {
    const path = pathname.split("/");
    if (path.includes("personal-information")) setValue(0);
    if (path.includes("shop-details")) setValue(1);
    if (path.includes("shop-hours")) setValue(2);
    if (path.includes("service-offered")) setValue(3);
    if (path.includes("billing")) setValue(4);
    if (path.includes("password-security")) setValue(5);
    if (path.includes("notifications")) setValue(6);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 0) navigate("/account-settings/personal-information");
    else if (newValue == 1) navigate("/account-settings/shop-details");
    else if (newValue == 2) navigate("/account-settings/shop-hours");
    else if (newValue == 3) navigate("/account-settings/service-offered");
    else if (newValue == 4) navigate("/account-settings/billing");
    else if (newValue == 5) navigate("/account-settings/password-security");
    else if (newValue == 6) navigate("/account-settings/notifications");
    else navigate("/account-settings/personal-information");
  };

  return (
    // <Box sx={{ width: '100%' }} >
    <>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader /> */}
      <Box padding={"32px 24px"} sx={{ bgcolor: "background.main" }}>
        <Box
          style={{ display: "flex", fontSize: "24px", marginBottom: "32px" }}
          paddingLeft={"10px"}
        >
          <Typography variant="h4" fontWeight={400}>
            Account
          </Typography>
        </Box>
        <Box overflow={"auto"}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="primary"
            variant="standard"
            className="overflow-tabs"
          >
            <Tab label="Personal Information" {...a11yProps(0)} />
            <Tab label="Shop Details" {...a11yProps(1)} />
            <Tab label="Shop Hours" {...a11yProps(2)} />
            <Tab label="SERVICES OFFERED" {...a11yProps(3)} />
            <Tab label="Billing" {...a11yProps(4)} />
            <Tab label="Password And Security" {...a11yProps(5)} />
            <Tab label="Notifications" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <PersonalInfo />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ShopDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ShopHours />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <ServiceOffered />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Billing />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <PasswordAndSecurity />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <Notification />
        </CustomTabPanel>
        {/* </Box> */}
      </Box>
    </>
  );
}
