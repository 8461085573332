import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

export const getNotification = (limits, pages, forPopup) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.notification.notificationList}?limit=${limits}&page=${pages}`
      );
      if (response.data.status) {
        dispatch({
          type: API.notification.notificationList,
          notificationData: response.data.data,
          forPopup: forPopup,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const markAsRead = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(API.notification.markAsRead);
      if (response.data.status) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const markAsReadById = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(API.notification.markAsReadById+id);
      if (response.data.status) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};