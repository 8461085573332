import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

// Personal details fetch
export const estimateDetails = ({
  page,
  limit,
  status,
  sortType,
  sortBy,
  text,
  type,
  year,
  makeId,
  modelId,
  date,
}) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${
          API.estimate.estimateList
        }?limit=${limit}&page=${page}&status=${status}&sortType=${sortType}&sortBy=${sortBy}&searchText=${text}&type=${type}&vehicleYear=${year}&vehicleMakeId=${makeId}&vehicleModelId=${modelId}&date=${date}&timezone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`
      );
      if (!response.data.error) {
        dispatch({
          type: API.estimate.estimateList,
          estimateData: response.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const estimateData = (estimateId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.estimate.estimateDetail}/${estimateId}`
      );
      if (!response.data.error) {
        dispatch({
          type: API.estimate.estimateDetail,
          estimateDetailData: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const estimateRequestData = (estimateRequestId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.estimate.getEstimateRequestDetails}/${estimateRequestId}`
      );
      if (!response.data.error) {
        // dispatch({
        //     type: API.estimate.estimateDetail,
        //     estimateDetailData: response.data.data,
        //     loading: false,
        // });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const deleteDraftEstimate = (estimateId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        `${API.estimate.deleteDraft}/${estimateId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const serviceCategoryData = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.estimate.servicesCategory);
      if (!response.data.error) {
        dispatch({
          type: API.estimate.servicesCategory,
          serviceData: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const serviceSubcategoryData = (page, limit, id, canceltoken) => {
  return async (dispatch) => {
    try {
      let qury = `?page=${page}&limit=${limit}`;

      if (id) {
        qury += `&serviceCategoryId=${id}`;
      }

      const response = await DataService.get(
        `${API.estimate.servicesSubCategory}${qury}`,
        {},
        canceltoken
      );
      if (!response.data.error) {
        dispatch({
          type: API.estimate.servicesSubCategory,
          serviceSubData: response.data.data,
          loading: false,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const termsAndWarrantyData = () => {
  return async () => {
    try {
      const response = await DataService.get(API.estimate.termsAndWarranty);
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const createEstimateData = (payload) => {
  return async () => {
    try {
      const response = await DataService.post(
        API.estimate.createEstimate,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const createEstimateDraftData = (payload) => {
  return async () => {
    try {
      const response = await DataService.post(
        API.estimate.createDraftEstimate,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const sendEstimate = (estimateId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        `${API.estimate.sentEstimateDetails}/${estimateId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
