import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import "./send.css";
import DownloadIcon from "@mui/icons-material/Download";
import SendIcon from "@mui/icons-material/Send";
// import CheckCircleOutlined from "@mui/icons-material/CheckCircle";
import { useTheme } from "@emotion/react";
import { CheckCircleOutlined } from "@mui/icons-material";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  ESTIMATE_INVOICE_SENT,
  ESTIMATE_INVOICE_TABLE_ROW,
} from "../../../App";
import FormatNumber, {
  FormatNumberForKm,
  FormatTotalNumbers,
} from "../../../components/FormatNumber/FormatNumber";
import { estimateData, sendEstimate } from "../../../redux/Estimate/action";
import { formatPhoneNumber } from "../../Common/FormatPhoneNumber";
import SuccessMessageModal from "../../Common/SuccessMessage";

function createData(no, disc, quantity, cost, amount) {
  return { no, disc, quantity, cost, amount };
}

const rows = [
  createData(
    "1",
    <Typography variant="body2">Labor</Typography>,
    1,
    "$0.00",
    "$0.00"
  ),
  createData(
    "2",
    <Box>
      <Typography variant="body2" fontWeight={400}>
        Brakes
      </Typography>
      <Typography fontWeight={400} fontSize={12}>
        Part Provider: OEM
      </Typography>
      <Typography variant="body2" fontWeight={400} fontSize={12}>
        Part Number: 000000
      </Typography>
      <Typography variant="body2" color={"#848484"}>
        Optional Description: Morem ipsum dolor sit amet, consectetur elit.
        Morem ipsum dolor sit amet, consectetur elit. Morem ipsum dolor sit
        amet, consectetur elit. Morem ipsum dolor sit amet, consectetur elit.
      </Typography>
    </Box>,
    "2",
    "$000.00",
    "$000.00"
  ),
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const EstimatesSend = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const { id } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [getEstimateData, setGetEstimateData] = React.useState("");
  const open1 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    estimate();
  }, []);

  const estimate = async () => {
    setShowLoader(true);
    const data = await dispatch(estimateData(id));
    if (data) {
      setGetEstimateData(data);
      setShowLoader(false);
    }
  };
  const clickDownload = async () => {
    // const url = window.URL.createObjectURL(new Blob([getEstimateData?.pdfUrl]));
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", "Apex-Estimate.pdf");
    // document.body.appendChild(link);
    // link.click();

    // const link = document.createElement("a");
    // link.href = getEstimateData?.pdfUrl;
    // link.setAttribute("download", "Apex-Estimate.pdf");
    // document.body.appendChild(link);
    // link.click();

    try {
      const fileResponse = await fetch(getEstimateData?.pdfUrl);
      const fileBlob = await fileResponse.blob();
      const url = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Apex-Estimate.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handlePreviewPdf = () => {
    const pdfUrl = getEstimateData?.pdfUrl;
    window.open(pdfUrl, "_blank");
  };

  const handleSend = async () => {
    setShowLoader(true);
    const data = await dispatch(sendEstimate(id));
    if (data) {
      setShowLoader(false);
      setOpenSuccessModal(true);
      setSuccessMessage("Estimate Pdf Send to Customer Successfully");
      handleClose1();
    }
  };
  return (
    <>
      <Box
        padding={"32px 24px"}
        sx={{
          bgcolor: "background.main",
          width: "fit-content",
          minWidth: "100%",
        }}
      >
        {" "}
        <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/estimates/all"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Estimates
                </Link>
                <Link
                  to="/estimates/sents"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Sent
                </Link>
                <Link
                  to="/estimates/all"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {getEstimateData && getEstimateData?.estimateNumber}
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" fontWeight={500}>
              {getEstimateData && getEstimateData?.estimateNumber}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="end">
          <Grid item>
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<VisibilityIcon />}
                size="large"
                onClick={handlePreviewPdf}
              >
                PREVIEW Estimate
              </Button>
              {/* <Button variant="contained">more actions</Button> */}
              {/* <Button variant="contained" onClick={handleOpen}>more actions</Button> */}
              <Button
                variant="contained"
                color="secondary"
                id="demo-positioned-button"
                aria-controls={open1 ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
                size="large"
              >
                more actions
              </Button>
              <Menu
                style={{ width: "fitContent" }}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={clickDownload}>
                  <DownloadIcon style={{ marginRight: "10px" }} /> Download PDF
                </MenuItem>
                <MenuItem onClick={handleSend}>
                  <SendIcon style={{ marginRight: "10px" }} />
                  Send to Customer
                </MenuItem>
              </Menu>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          padding={"24px"}
          margin={"32px 0"}
          boxShadow={2}
          gap={2}
          bgcolor={"white"}
        >
          <Grid container item spacing={2} justifyContent="space-between">
            <Grid
              item
              style={{ color: "white" }}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              xl={12}
            >
              <Stack
                direction={"row"}
                spacing={2}
                style={{
                  backgroundColor: ESTIMATE_INVOICE_SENT,
                  fontSize: "16px",
                }}
                alignItems={"center"}
                padding={"8px 8px"}
                borderRadius={"4px"}
                color="black"
              >
                <Stack>
                  <CheckCircleOutlined />
                </Stack>
                <Stack direction={"column"} spacing={0}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    Sent
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            justifyContent="space-between"
            padding={"24px 0"}
          >
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Typography variant="h3" fontWeight={400}>
                Estimate{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Stack spacing={0}>
                <Typography variant="body1" fontWeight={500} fontSize={"24px"}>
                  {getEstimateData && getEstimateData?.estimateNumber}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  Estimate Prepared By:{" "}
                  {getEstimateData && getEstimateData?.estimateCreatedBy}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Divider style={{ width: "100%" }} />
          <Grid container item padding={"24px 0"}>
            <Grid item>
              <Stack>
                <Typography variant="h6">
                  {getEstimateData && getEstimateData?.shop?.shopName}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  {getEstimateData &&
                    getEstimateData?.shop?.address +
                      ", " +
                      getEstimateData?.shop?.city?.city +
                      ", " +
                      getEstimateData?.shop?.province?.province +
                      ", " +
                      getEstimateData?.shop?.postalCode}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  {formatPhoneNumber(getEstimateData?.shop?.phoneNumber)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyItems={"space-between"}
            padding={"24px 0"}
          >
            <Grid
              container
              item
              justifyItems={"space-between"}
              spacing={{ lg: 2, md: 2, sm: 2, xs: 2 }}
            >
              <Grid item lg={4} md={4.5} sm={4.5} xs={4.5}>
                <Stack>
                  <Typography variant="h6">Dates</Typography>
                  <Stack direction={"row"} spacing={1}>
                    <Typography variant="body1" fontWeight={500}>
                      Estimate Given:
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {getEstimateData &&
                        moment(getEstimateData?.estimateDate).format(
                          "MMM Do, YYYY"
                        )}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1}>
                    <Typography variant="body1" fontWeight={500}>
                      Estimate Expiration:
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {getEstimateData?.estimationExpiryDate
                        ? moment(getEstimateData?.estimationExpiryDate).format(
                            "MMM Do, YYYY"
                          )
                        : "-"}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1}>
                    <Typography variant="body1" fontWeight={500}>
                      Work Completion Date:
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {getEstimateData?.completionDate
                        ? moment(getEstimateData?.completionDate).format(
                            "MMM Do, YYYY"
                          )
                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item lg={4} md={3.5} sm={3.5} xs={3.5}>
                <Stack>
                  <Typography variant="h6" fontWeight={500}>
                    Customer Information
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {getEstimateData && getEstimateData?.customer?.fullName}
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {formatPhoneNumber(getEstimateData?.customer?.phoneNumber)}
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {getEstimateData && getEstimateData?.customer?.email}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="h6">Vehicle Information</Typography>
                <Typography variant="body1" fontWeight={400}>
                  {/* 2023 Audi Q5 */}
                  {getEstimateData &&
                    getEstimateData?.vehicle?.year +
                      " " +
                      getEstimateData?.vehicle?.vehicleMake?.name +
                      " " +
                      getEstimateData?.vehicle?.vehicleModel?.name +
                      " " +
                      getEstimateData?.vehicle?.vehicleModelTrim?.name}
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  <Typography variant="body1" fontWeight={500}>
                    VIN:
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {getEstimateData && getEstimateData?.vehicle?.vin
                      ? getEstimateData?.vehicle?.vin
                      : " - "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography variant="body1" fontWeight={500}>
                    Licence Plate Number:
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {getEstimateData && getEstimateData?.vehicle?.licenseNumber
                      ? getEstimateData?.vehicle?.licenseNumber
                      : " - "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography variant="body1" fontWeight={500}>
                    Odometer:
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {FormatNumberForKm(getEstimateData?.odo)} km
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  marginTop: "10px",
                }}
              >
                Total Services:{" "}
                {getEstimateData && getEstimateData?.services.length}
              </Typography>
            </Grid>
            {getEstimateData &&
              getEstimateData?.services.map((item, index) => {
                return (
                  <>
                    <Grid
                      container
                      item
                      style={{
                        backgroundColor: ESTIMATE_INVOICE_TABLE_ROW,
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography
                          sx={{
                            padding: "16px",
                            bgcolor: "secondary.main",
                            color: "white",
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "4px",
                            fontWeight: "500",
                            fontSize: "20px",
                          }}
                        >
                          {item?.serviceName}
                        </Typography>
                      </Grid>
                    </Grid>
                    {item?.pricingType == "labor_&_parts" ? (
                      <Grid container item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <TableContainer style={{ minWidth: "850px" }}>
                            <Table aria-label="caption table" padding="16px">
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: "bold",
                                }}
                              >
                                <TableRow style={{ fontWeight: "bold" }}>
                                  <TableCell width={"9.6%"}>No.</TableCell>
                                  <TableCell> Description</TableCell>
                                  <TableCell width={"9.6%"}>
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"} align="center">
                                    {" "}
                                    Amount
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {item?.labor && (
                                  <TableRow key={item.no}>
                                    <TableCell component="th" scope="row">
                                      1
                                    </TableCell>
                                    <TableCell align="left">Labor</TableCell>
                                    <TableCell align="left">
                                      {item?.labor?.qty}
                                    </TableCell>
                                    <TableCell align="left">
                                      <FormatNumber
                                        inputValue={item?.labor?.ratePerHour?.toString()}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ paddingRight: "23px" }}
                                    >
                                      <FormatTotalNumbers
                                        inputValue={item?.labor?.totalAmount?.toString()}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}

                                {item?.parts.map((row, index) => (
                                  <TableRow key={row.no}>
                                    <TableCell component="th" scope="row">
                                      {index + 2}
                                    </TableCell>
                                    <TableCell align="left">
                                      <div>{row.partName}</div>
                                      <div>
                                        Part Provider : {row.partsProvider}
                                      </div>
                                      <div>Part Number : {row.partNumber}</div>
                                      <div>{row.description}</div>
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.qty}
                                    </TableCell>
                                    <TableCell align="left">
                                      {" "}
                                      <FormatNumber
                                        inputValue={row?.cost?.toString()}
                                      />
                                    </TableCell>

                                    <TableCell
                                      align="right"
                                      style={{ paddingRight: "23px" }}
                                    >
                                      <FormatTotalNumbers
                                        inputValue={row?.totalAmount?.toString()}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid container item>
                          <Grid
                            item
                            xl={12}
                            md={12}
                            sm={12}
                            xs={12}
                            boxShadow={0}
                          >
                            <TableContainer style={{ minWidth: "850px" }}>
                              <Table aria-label="caption table" padding="16px">
                                <TableHead
                                  style={{
                                    backgroundColor: "#f4f6f8",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <TableRow style={{ fontWeight: "bold" }}>
                                    <TableCell width={"9.6%"}>No.</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell width={"9.6%"}>
                                      {" "}
                                      Quantity
                                    </TableCell>
                                    <TableCell width={"9.6%"}> Cost</TableCell>
                                    <TableCell width={"9.6%"} align="center">
                                      {" "}
                                      Amount
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  <TableRow key={item.no}>
                                    <TableCell component="th" scope="row">
                                      1
                                    </TableCell>
                                    <TableCell align="left">
                                      {item?.serviceName}
                                    </TableCell>
                                    <TableCell align="left">
                                      {item?.qty ? item?.qty : 1}
                                    </TableCell>
                                    {/* <TableCell align="left">${item?.fixedPrice}</TableCell> */}
                                    <TableCell align="left">
                                      <FormatNumber
                                        inputValue={item?.fixedPrice?.toString()}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ paddingRight: "23px" }}
                                    >
                                      {" "}
                                      <FormatTotalNumbers
                                        inputValue={item?.fixedPrice?.toString()}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                );
              })}
            <Grid
              container
              item
              justifyContent={"end"}
              padding={"80px 23px 24px 24px"}
            >
              <Grid item>
                <Stack direction="row" spacing={2}>
                  <Box textAlign={"end"}>
                    <Typography mb={1}>Sub Total</Typography>
                    {getEstimateData?.discount > 0 && (
                      <Typography mb={1}>Discount</Typography>
                    )}
                    <Typography mb={1}>Tax</Typography>
                    <Typography>Estimate Total</Typography>
                  </Box>
                  <Box textAlign={"end"}>
                    <Typography mb={1}>
                      <FormatTotalNumbers
                        inputValue={getEstimateData?.subTotal}
                      />
                    </Typography>
                    {getEstimateData?.discount > 0 && (
                      <Typography mb={1}>
                        <FormatTotalNumbers
                          inputValue={getEstimateData?.discount}
                        />
                      </Typography>
                    )}
                    <Typography mb={1}>
                      <FormatTotalNumbers inputValue={getEstimateData?.tax} />
                    </Typography>
                    <Typography>
                      <FormatTotalNumbers inputValue={getEstimateData?.Total} />
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Grid container item justifyContent={"start"} marginTop={"32px"}>
              <Grid item>
                <Stack spacing={1}>
                  <Typography fontWeight={500}>Terms and Conditions</Typography>
                  <Typography maxWidth={"500px"} color="#848484">
                    {getEstimateData &&
                      getEstimateData?.termsAndWarranty?.description}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EstimatesSend;
