import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Grid, Link, Modal, Toolbar, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pl: 4,
  pr: 0,
  pb: 3,
};

const RequestedPhotos = ({
  openCancelSubscriptionModal,
  setOpenCancelSubscriptionModal,
}) => {
  const handleCloseCancelSubscriptionModal = () => {
    setOpenCancelSubscriptionModal(false);
  };

  return (
    <Box>
      <Modal
        open={openCancelSubscriptionModal}
        onClose={handleCloseCancelSubscriptionModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "600px",
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
              borderRadius: "8px 8px 0px 0px" }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6"  width={"98%"} color={"white"}>
                Request Photos?
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    handleCloseCancelSubscriptionModal();
                  }}
                  style={{ cursor: "pointer",height:'32px' }}
                  >
                    <CloseIcon style={{ color: "#FFFFFF" ,fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              scrollbarColor: "blue",
              scrollbarWidth: "2px",

              marginTop: "60px",
              paddingRight: "14px",
            }}
          >
            <Grid
              container
              spacing={2}
              maxHeight={"85vh"}
              style={{ scrollbarColor: "blue", scrollbarWidth: "thin" }}
            >
              <Grid item lg={12}>
                <Typography variant="body1">
                  We are here to support 9:00 AM - 9:00 PM. Monday to Sunday.
                </Typography>
                <Typography variant="subtitle1" fontWeight={500}>
                  Call Us: (437)-961-8836
                </Typography>
                <Typography variant="subtitle1" fontWeight={500}>
                  Email:{" "}
                  <Link style={{ textDecoration: "none" }}>
                    support@apexmechanic.com
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>
    </Box>
  );
};

export default RequestedPhotos;
