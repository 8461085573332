import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

// Personal details fetch
export const NotificationStatus = () => async (dispatch) => {
  try {
    const response = await DataService.get(
      API.account.notificationManage.getNotificationStatus
    );
    if (!response.data.error) {
      dispatch({
        type: API.account.notificationManage.getNotificationStatus,
        getNotificationStatus: response.data.data,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateNotificationStatus = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.account.notificationManage.updateNotificationStatus,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
