import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ImagesUpload.css";

import { DeleteOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SortableList, { SortableItem } from "react-easy-sort";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { imageUrl } from "../../../config/dataService/dataService";
import {
  getShopImagesList,
  updateShopImages,
} from "../../../redux/Account/ShopDetails/action";
import { getItem, removeItem, setItem } from "../../../utility/localStorage";
import ShopImageShowModal from "../../modal/ShopImagePreviewModal";
import CustomImageUploadButton from "./CustomImageUpload";

// import galleryList from "./data.js";
const deleteStyle = {
  position: "absolute",
  top: "0%",
  left: "0%",
  width: 400,
  transform: "translate(-0%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const Card = ({
  src,
  title,
  id,
  index,

  setImageOpenModalShows,
  setImageView,
  handleDelete,
  handleEdit,

  setCurrentIndex,
}) => {
  const ref = React.useRef(null);
  const [uploadedImage, setUploadedImage] = useState();

  const currentIndex = getItem("index");
  useEffect(() => {
    if (uploadedImage) {
      if (currentIndex) {
        handleEdit(currentIndex - 1, uploadedImage);
        removeItem("index");
      }
      // else {
      //   handleAdd(index, uploadedImage);
      // }
      setUploadedImage(null);
    }
  }, [uploadedImage]);

  return (
    <div ref={ref} className="card-container">
      {index == 0 && (
        <div className="card-overlay">
          <div className="primary">Primary </div>
          <CustomImageUploadButton
            setUploadedImage={setUploadedImage}
            edit={true}
            index={index}
          />
        </div>
      )}
      {index != 0 && (
        <div
          className="card-overlay-icons"
          onClick={() => {
            setCurrentIndex(index);
          }}
        >
          {index > 3 && (
            <DeleteOutlined
              onClick={() => handleDelete(index)}
              className="icon delete"
            />
          )}
          <CustomImageUploadButton
            setUploadedImage={setUploadedImage}
            edit={true}
            index={index}
          />
        </div>
      )}

      <img
        src={src}
        alt={title}
        className="card-image"
        onClick={() => {
          setImageOpenModalShows(true);
          setImageView(src);
        }}
        draggable={false}
        loading="lazy"
      />
    </div>
  );
};
const ImageUploader = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [images, setImages] = React.useState([]);
  const [tempImages, setTempImages] = React.useState([]);

  const [imageOpenModalShows, setImageOpenModalShows] = useState(false);
  const [imageView, setImageView] = useState("");
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const localStorageImages = getItem("apex-saas_details");
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [curentIndex, setCurrentIndex] = useState("");
  useEffect(() => {
    getImages();
  }, []);
  const getImages = async () => {
    setSkeletonLoader(true);
    const resp = await dispatch(getShopImagesList());
    if (resp) {
      let temp = [];
      resp?.images?.map((img, index) => {
        temp.push({
          id: index + 1,
          title: img.type,
          img: imageUrl + img.url,
          serverImg: img.url,
        });
      });
      setImages(temp);
      setTempImages(temp);
      let allLocalData = localStorageImages;
      allLocalData.images = resp?.images;

      setItem("apex-saas_details", allLocalData);
      window.dispatchEvent(new Event("localStorageChange"));
    }
    setSkeletonLoader(false);
  };

  const handleDelete = (idx) => {
    setOpenDelete(true);
    setDeleteId(idx);
    // console.log("delete idex", idx);
    // const allImages = [...images];
    // allImages.splice(idx, 1);
    // const lastIndex = allImages[allImages.length - 1];
    // console.log("lastIndex", lastIndex);
    // // if (Object.keys(lastIndex).length != 0) {
    // //   allImages.push({});
    // // }
    // setImages(allImages);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setDeleteId(null);
  };
  const handleDeleteCofirm = () => {
    const allImages = [...images];
    allImages.splice(deleteId, 1);

    setImages(allImages);
    setOpenDelete(false);
    setDeleteId(null);
  };

  const handleEdit = (idx, file) => {
    const allImages = [...images];
    allImages[idx] = {
      id: idx + 1,
      title: "",
      img: URL.createObjectURL(file),
      file: file,
    };

    setImages(allImages);
  };
  const handleAdd = (idx, file) => {
    const allImages = [...images];
    // allImages[allImages.length - 1] = {
    //   id: idx + 1,
    //   title: "",
    //   img: URL.createObjectURL(file),
    //   file: file,
    // };

    // if (allImages.length < 10) {
    if (file) {
      allImages.push({
        id: idx + 1,
        title: "",
        img: URL.createObjectURL(file),
        file: file,
      });
      // }
      setImages(allImages);
    }
  };
  const handleSaveImages = async () => {
    let payload = {};

    // images.map((data, index) => {
    //   formData.append(`images[${index}]`, data.file);
    // });
    images.forEach((data, index) => {
      if (data.file) {
        payload[`images[${index}]`] = data.file;
      } else {
        payload[`images[${index}]`] = data.serverImg;
      }
      // paylaod(`images[${index}]`, data.file);
    });
    setShowLoader(true);
    const resp = await dispatch(updateShopImages(payload));
    if (resp) {
      toast.success("Changes saved");
      let temp = [];
      resp?.images?.map((img, index) => {
        temp.push({
          id: index + 1,
          title: img.type,
          img: imageUrl + img.url,
          serverImg: img.url,
        });
      });
      setImages(temp);
      setTempImages(temp);
      let allLocalData = localStorageImages;
      allLocalData.images = resp?.images;

      setItem("apex-saas_details", allLocalData);
      window.dispatchEvent(new Event("localStorageChange"));
    }
    setShowLoader(false);
  };
  const onSortEnd = (oldIndex, newIndex) => {
    const updatedItems = [...images];
    const [movedItem] = updatedItems.splice(oldIndex, 1);
    updatedItems.splice(newIndex, 0, movedItem);
    setImages(updatedItems);
  };
  return (
    <>
      <Grid
        container
        sx={{ boxShadow: 3, bgcolor: "white", flexDirection: "column" }}
        // justifyContent={"space-between"}
        padding={"24px"}
        borderRadius={"10px"}
        marginTop={"32px"}
      >
        <Grid
          container
          justifyContent={"space-between"}
          borderRadius={"10px"}
          mb={3}
        >
          <Grid item xs={12} sm={8} md={8} xl={10}>
            <Typography variant="h5">Shop Images</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            xl={2}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <div>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="add-image-upload-button"
                onChange={(event) => {
                  if (event.target.files[0].type.startsWith("image/")) {
                    handleAdd(images.length - 1, event.target.files[0]);
                    event.target.value = null;
                  } else {
                    toast.info("Please select only images");
                  }
                }}
                disabled={images.length == 10}
              />

              <label htmlFor="add-image-upload-button">
                <Button
                  color="secondary"
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    document.getElementById("add-image-upload-button").click();
                    removeItem("index");
                  }}
                  disabled={images.length == 10}
                >
                  ADD NEW IMAGE
                </Button>
              </label>
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          style={{
            display: "flex",
            justifyContent: "end",
            paddingRight: "10px",
          }}
        >
          <SortableList
            onSortEnd={onSortEnd}
            draggedItemClassName="dragged"
            className="sortable-list"
          >
            {images.map((image, index) => (
              <SortableItem key={index}>
                <div className="sortable-item">
                  <Card
                    src={image.img}
                    title={image.title}
                    id={image.id}
                    index={index}
                    setImageOpenModalShows={setImageOpenModalShows}
                    setImageView={setImageView}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    handleAdd={handleAdd}
                    setCurrentIndex={setCurrentIndex}
                    // canDrag={Object.keys(image).length != 0 ? true : false}
                  />
                </div>
              </SortableItem>
            ))}
          </SortableList>
          {skeletonLoader && (
            <div className="skeleton-design">
              <Skeleton variant="rectangular" width={260} height={260} />
              <Skeleton variant="rectangular" width={260} height={260} />
              <Skeleton variant="rectangular" width={260} height={260} />
              <Skeleton variant="rectangular" width={260} height={260} />
              <Skeleton variant="rectangular" width={260} height={260} />
            </div>
          )}
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          spacing={2}
          style={{ marginTop: "20px" }}
        >
          <Grid item spacing={2}>
            <Stack spacing={2} direction="row">
              <Button
                className="btn-text-size-manage"
                variant="outlined"
                color="primary"
                onClick={() => setImages([...tempImages])}
                // onClick={() => {
                //   setShopPriceCancel(true);
                //   setValue("defaultHourlyRate", defaultPriceDetails);
                // }}
              >
                Cancel Changes
              </Button>
              <Button
                className="btn-text-size-manage"
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSaveImages}
              >
                Save Changes
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <ShopImageShowModal
        imageView={imageView}
        setImageView={setImageView}
        imageOpenModalShows={imageOpenModalShows}
        setImageOpenModalShows={setImageOpenModalShows}
      />
      <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="deleteCustomer"
      >
        <Box
          sx={{
            ...deleteStyle,
            width: "100%",
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <AppBar
            color="secondary"
            variant="elevation"
            style={{
              width: "inherit",
              borderRadius: "8px 8px 0px 0px !important",
              padding: "0px",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                Delete Image
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleCloseDelete}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
        </Box>
        <Container
          style={{
            marginTop: "64px",
            padding: "0",
          }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this image?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: "8px" }}>
            <Button onClick={handleCloseDelete} variant="outlined">
              CANCEL
            </Button>
            <Button
              onClick={handleDeleteCofirm}
              autoFocus
              color="error"
              variant="contained"
            >
              DELETE IMAGE
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </>
  );
};

export default ImageUploader;
