import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

export const getServiceSubCategory =
  (limit, category, page, searchValue) => async (dispatch) => {
    try {
      const response = await DataService.get(
        `${
          API.approvals.getApprovalsListing
        }?limit=${limit}&approvalStatus=${category}&page=${page}${
          searchValue != "" && searchValue ? `&searchText=${searchValue}` : ""
        }`
      );
      if (!response.data.error) {
        dispatch({
          type: API.approvals.getApprovalsListing,
          getApprovalsListing: response.data,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const getNewApprovals = (id) => async (dispatch) => {
  try {
    const response = await DataService.get(
      `${API.approvals.getApprovalDetails}/${id}`
    );
    if (!response.data.error) {
      dispatch({
        type: API.approvals.getApprovalDetails,
        getApprovalDetails: response.data.data,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const createApproval = (payload) => {
  return async () => {
    try {
      const response = await DataService.post(
        API.approvals.createApproval,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getCurrentApprovals = (id) => async (dispatch) => {
  try {
    const response = await DataService.get(
      `${API.approvals.currentApproval}/${id}`
    );
    if (!response.data.error) {
      dispatch({
        type: API.approvals.currentApproval,
        currentApproval: response.data.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
// export const getCurrentApprovals = (payload) => {
//   return async () => {
//     try {
//       const response = await DataService.post(
//         API.approvals.createApproval,
//         payload,
//         {
//           "Content-Type": "multipart/form-data",
//         }
//       );
//       if (!response.data.error) {
//         return response.data.data;
//       } else {
//         return false;
//       }
//     } catch (error) {
//       return false;
//     }
//   };
// };
