import { API } from "../../config/api";

//ALL TYPE WISE DATA RETURN
const initState = {
  notificationPopupData: {},
};

// DEFINE AUTH REDUCER FUNCTION
const NotificationReducers = (state = initState, action) => {
  switch (action.type) {
    case API.notification.notificationList:
      if (action.forPopup == true) {
        return {
          ...state,
          notificationPopupData: action.notificationData,
        };
      } else {
        return {
          ...state,
          notificationData: action.notificationData,
        };
      }

    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default NotificationReducers;
