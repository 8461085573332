import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form"; // Step 1
import { useDispatch } from "react-redux";
import {
  fetchShopClosingTime,
  mechanicDetailsList,
  proposeDateForAppointment,
} from "../../redux/appointment/action";
import SuccessMessageModal from "../Common/SuccessMessage";
import "../Estimates/Estimates.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};
const textAreaStyle = {
  width: "100%",
  height: "100px",
  fontSize: "16px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
  fontFamily: "Roboto",
  fontWeight: 400,
};

const RequestProposeTimeModel = ({
  open,
  handleClose,
  id,
  handleClose2,
  date,
  callNotificationAPI,
  shopEndTime,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [proposeDateTime, setProposeDateTime] = useState(null);

  const [estimatePickUpDateAndTime, setEstimatePickUpDateAndTime] = useState(
    dayjs(shopEndTime)
  );
  const [showLoader, setShowLoader] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [validationDateError, setValidationDateError] = React.useState("");
  const [validationAssignError, setValidationAssignError] = React.useState("");
  const [assigneeData, setAssigneeData] = React.useState("");
  const [assigneeList, setAssigneeList] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [note, setNote] = React.useState("");

  const assigneecList = async () => {
    let data = await dispatch(mechanicDetailsList());
    setAssigneeList(data);
  };
  useEffect(() => {
    assigneecList();
    getShopClosingTime(dayjs());
    // setEstimatePickUpDateAndTime(dayjs(shopEndTime));
  }, []);
  const onSubmit = async (data) => {
    if (proposeDateTime && estimatePickUpDateAndTime) {
      // setValidationDateError("");
      const proposeNewDate = new Date(
        proposeDateTime.$y,
        proposeDateTime.$M,
        proposeDateTime.$D,
        proposeDateTime.$H,
        proposeDateTime.$m,
        proposeDateTime.$s,
        proposeDateTime.$ms
      );
      const isoProposeDate = proposeNewDate.toISOString();

      const newDate = new Date(
        estimatePickUpDateAndTime.$y,
        estimatePickUpDateAndTime.$M,
        estimatePickUpDateAndTime.$D,
        estimatePickUpDateAndTime.$H,
        estimatePickUpDateAndTime.$m,
        estimatePickUpDateAndTime.$s,
        estimatePickUpDateAndTime.$ms
      );
      const isoPickUpDate = newDate.toISOString();

      const date1String = isoProposeDate;
      const date2String = isoPickUpDate;

      const date1 = new Date(date1String);
      const date2 = new Date(date2String);

      if (date1.getTime() === date2.getTime()) {
        setValidationDateError(
          "Estimated pick up time must be after drop off."
        );
        return;
      } else if (date1 > date2) {
        setValidationDateError(
          "Estimated pick up time must be after drop off."
        );
        return;
      }
      setShowLoader(true);
      let payload = {
        proposeTime: isoProposeDate,
        pickupDateTime: isoPickUpDate,
        assignee: assigneeData,
        appointmentId: id,
      };
      // if(note!==''){
      //   payload.noteForCustomer=note
      // }
      if (assigneeData && isoProposeDate && isoPickUpDate) {
        const reschedule = await dispatch(proposeDateForAppointment(payload));
        if (reschedule) {
          setShowLoader(false);
          setOpenSuccessModal(true);
          setSuccessMessage(
            "New proposed drop off has been sent to the customer"
          );

          setTimeout(() => {
            handleClose();
            handleClose2();
          }, 1000);

          setValidationDateError("");
          handleClose();
          setValidationAssignError("");
          setAssigneeData("");
          setProposeDateTime(null);
          setEstimatePickUpDateAndTime(null);
          if (callNotificationAPI) {
            callNotificationAPI(1, false);
          }
        } else {
          setShowLoader(false);
        }
      } else {
        setShowLoader(false);
        setValidationAssignError("Assign To is required");
      }
    } else {
      setShowLoader(false);
      setValidationDateError(
        "Dropoff and Pick up Date and Time both are required"
      );
    }
  };
  const getShopClosingTime = async (time) => {
    let resp = await dispatch(fetchShopClosingTime({ dropOffDateTime: time }));

    setEstimatePickUpDateAndTime(dayjs(resp?.shopClosingTime));
  };

  const checkDateValidation = () => {
    if (proposeDateTime && estimatePickUpDateAndTime) {
      const proposeNewDate = new Date(
        proposeDateTime.$y,
        proposeDateTime.$M,
        proposeDateTime.$D,
        proposeDateTime.$H,
        proposeDateTime.$m,
        proposeDateTime.$s,
        proposeDateTime.$ms
      );
      const isoProposeDate = proposeNewDate.toISOString();

      const newDate = new Date(
        estimatePickUpDateAndTime.$y,
        estimatePickUpDateAndTime.$M,
        estimatePickUpDateAndTime.$D,
        estimatePickUpDateAndTime.$H,
        estimatePickUpDateAndTime.$m,
        estimatePickUpDateAndTime.$s,
        estimatePickUpDateAndTime.$ms
      );
      const isoPickUpDate = newDate.toISOString();

      const date1String = isoProposeDate;
      const date2String = isoPickUpDate;

      const date1 = new Date(date1String);
      const date2 = new Date(date2String);

      if (date1.getTime() === date2.getTime()) {
        setValidationDateError(
          "Estimated pick up time must be after drop off."
        );
        return;
      } else if (date1 > date2) {
        setValidationDateError(
          "Estimated pick up time must be after drop off."
        );
        return;
      } else {
        setValidationDateError("");
      }
    } else {
      setValidationDateError(
        "Propose and Pick up Date and Time both are required"
      );
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} md={9} xl={9}>
          <Modal
            open={open}
            onClose={() => {
              handleClose();
              setValidationDateError("");
            }}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {" "}
              <Box
                sx={{
                  ...style,
                  height: "fit-content",
                  width: "800px",
                  border: "none",
                }}
              >
                <AppBar
                  variant="elevation"
                  style={{
                    height: "64px",
                    boxShadow: "none",
                    backgroundColor: "#002394",
                    paddingRight: "0 !important",
                  }}
                >
                  <Toolbar variant="regular">
                    <Typography variant="h6" fontWeight={500} width={"98%"}>
                      Propose New Time
                    </Typography>
                    <Stack direction={"row"} spacing={0}>
                      <div
                        onClick={handleClose}
                        style={{ cursor: "pointer", height: "32px" }}
                      >
                        <CloseIcon sx={{ fontSize: 32 }} />
                      </div>
                    </Stack>
                  </Toolbar>
                </AppBar>
                <Container
                  disableGutters
                  style={{ marginTop: "40px", paddingTop: "24px" }}
                >
                  <Grid container>
                    <Grid container item xs={12} md={12} xl={12} spacing={3}>
                      <Grid item xs={12} md={12} xl={12}>
                        <Typography
                          variant="body1"
                          paddingBottom={"8px"}
                          fontWeight={500}
                          fontSize={16}
                        >
                          Dropoff Date and Time
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={2}>
                            <DateTimePicker
                              name="proposeDateAndTime"
                              // value={proposeTime ? dayjs(`2022-04-17T${proposeTime}`) : null}
                              onChange={(newValue) => {
                                setProposeDateTime(newValue);
                                getShopClosingTime(dayjs(newValue));

                                let value = JSON.parse(
                                  localStorage.getItem("apex-saas_details")
                                )?.shopClosingTime;
                                let time = dayjs(value).format("hh:mm A");
                                // getShopClosingTime(dayjs(newValue))
                              }}
                              timeSteps={{ minutes: 30 }}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={12} xl={12}>
                        <Typography
                          variant="body1"
                          paddingBottom={"8px"}
                          fontWeight={500}
                          fontSize={16}
                        >
                          Estimated Pick Up Date & Time
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={2}>
                            <DateTimePicker
                              name="pickUpDateAndTime"
                              value={estimatePickUpDateAndTime}
                              onChange={(newValue) => {
                                setEstimatePickUpDateAndTime(newValue);
                                // checkDateValidation()
                              }}
                              timeSteps={{ minutes: 30 }}
                            />
                          </Stack>
                        </LocalizationProvider>
                        {validationDateError && (
                          <p
                            style={{
                              color: "red",
                              margin: "0",
                              fontSize: "14px",
                              lineHeight: "24px",
                            }}
                          >
                            {validationDateError}
                          </p>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                        style={{ marginTop: "0px" }}
                      >
                        <Stack spacing={0}>
                          <Typography
                            variant="body1"
                            className="label-500"
                            fontWeight={500}
                            fontSize={16}
                          >
                            Assign To
                          </Typography>
                          <Select
                            value={assigneeData}
                            onChange={(event) => {
                              setAssigneeData(event.target.value);
                            }}
                            displayEmpty
                            // required
                          >
                            {assigneeList &&
                              assigneeList?.map((item) => {
                                return (
                                  <MenuItem value={item?._id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {validationAssignError && (
                            <p
                              style={{
                                color: "red",
                                margin: "0",
                                fontSize: "14px",
                                lineHeight: "24px",
                              }}
                            >
                              {validationAssignError}
                            </p>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={12} xl={12}>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "22px",
                          }}
                        >
                          Note for Customer
                        </Typography>
                        <textarea
                          fullWidth
                          variant="outlined"
                          style={textAreaStyle}
                          placeholder="Type Optional Message"
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item container justifyContent={"end"}>
                        <Grid item>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setValidationDateError("");
                                handleClose();
                                setValidationAssignError("");
                                setAssigneeData("");
                                setProposeDateTime(null);
                                setEstimatePickUpDateAndTime(null);
                              }}
                            >
                              CANCEL
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              SAVE
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </form>
          </Modal>
        </Grid>
      </Grid>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default RequestProposeTimeModel;
