import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import IconButton from "@material-ui/core/IconButton";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  handleForgot,
  handleResetPassword,
  handleVerifyotp,
} from "../../../redux/Auth/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const Modals = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [nextOpen, setNexOpen] = React.useState(false);
  const [nextNexOpen, setNexNexOpen] = React.useState(false);
  const [userId, setUserId] = React.useState({ id: "", otp: "" });
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConformPassword, setShowConformPassword] = React.useState(false);

  // const [showPassword, setShowPassword] = React.useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const handleOpen = () => {
    setOpen(true);
    // setNexNexOpen(true);
    // if (nextNexOpen) {
    //     setNexNexOpen(false)
    // }
    // else {
    //     setNexNexOpen(true)
    // }
  };

  const handleNextOpen = () => {
    setNexOpen(true);
    // setOpen(false);
  };

  const handleNextNextOpen = () => {
    setNexNexOpen(true);
    // setOpen(false);
    // setNexOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setNexNexOpen(false);
    setNexOpen(false);
    setValue("otp", "");
    setValue("confirmPassword", "");
    setValue("password", "");
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // handleSubmit(onSubmit)();
    }
  };
  let userIdFromLocalStorage = JSON.parse(
    localStorage.getItem("apex-saas_details")
  )?.shopOwnerId;
  const EmailVerification = async () => {
    handleNextOpen(true);

    try {
      const data = {
        // email: localStorage.getItem("apex-saas_email").toString(),
        emailOrPhoneNumber:localStorage.getItem("apex-saas_email").toString(),
        countryCode: "+1",
        role:'shop_owner'
      };
      // setLoading(true);
      let res = await dispatch(handleForgot(data));
      // setLoading(false);

      if (res?._id) {
        const newdata = { ...userId };
        newdata.id = userIdFromLocalStorage;
        newdata.otp = res.otp;

        setUserId(newdata);
        setOpen(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const RecoveryCodeSubmit = async (data) => {
    const payload = {
      userId: userIdFromLocalStorage,
      otp: data.otp,
    };
    setLoading(true);
    let res = await dispatch(handleVerifyotp(payload));
    if (res) {
      handleNextNextOpen();
      setValue("otp", "");
    }
    setLoading(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConformPassword = () =>
    setShowConformPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConformPassword = (event) => {
    event.preventDefault();
  };

  const PasswordReset = async (data) => {
    const payload = {
      userId: userId.id,
      password: data.password,
      confirmPassword: data.confirmPassword,
    };
    setLoading(true);
    let result = await dispatch(handleResetPassword(payload));
    if (result) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      handleClose();
    }
    setLoading(false);
  };

  const onInvalid = (errors) => console.error(errors);
  return (
    <div>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Typography variant="h5" sx={{ marginTop: "10px" }}>
        Change Password
      </Typography>

      <Button
        variant="text"
        color="primary"
        sx={{ padding: "11px", paddingLeft: "0px", marginTop: "8px" }}
        onClick={() => {
          EmailVerification();
        }}
        className="style-15-500-26-px"
      >
        forgot your password?
      </Button>
 
      <Modal
        open={nextOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "720px", height: "fit-content" }}>
          <AppBar variant="elevation" sx={{
                height: "64px",
                paddingRight: "0px !important",
              }}>
            <Toolbar variant="regular">
              <Typography
                variant="body1"
                gutterBottom={false}
                width={"98%"}
                color={"white"}
                className="btn-text-style"
                style={{ fontSize: "20px", fontWeight: "500" }}
              >
                Forgot Password
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div onClick={handleClose} style={{ cursor: "pointer",height:'32px' }}
                >
                  <CloseIcon style={{ color: "#0000008F",fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>

          <form onSubmit={handleSubmit(RecoveryCodeSubmit)}>
            <Container
              gap={"24px"}
              disableGutters
              style={{ height: "fit-content", marginTop: "75px" }}
            >
              <Grid container justifyContent={"start"} spacing={4}>
                <Grid item md={12} xl={12} spacing={2}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      marginBottom: "10px",
                    }}
                  >
                    Enter Recovery Code
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      marginBottom: "10px",
                    }}
                  >
                    {" "}
                    We have sent a recovery code to the email address on file.
                    Please enter the recovery code. It should arrive within 2-3
                    minutes of entering your email.
                  </Typography>
                  <Controller
                    name="otp"
                    control={control}
                    rules={{ required: "Recovery Code is required" }}
                    render={({ field }) => (
                      <TextField
                        // label="Recovery Code"
                        variant="outlined"
                        fullWidth
                        error={!!errors.otp}
                        helperText={errors.otp ? errors.otp.message : ""}
                        {...field}
                        onKeyPress={handleKeyPress}
                      />
                    )}
                  />
                </Grid>

                <Grid item container justifyContent={"end"} spacing={2}>
                  <Grid item spacing={2}>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        Cancel{" "}
                      </Button>

                      <Button
                        variant="contained"
                        color={"primary"}
                        disabled={loading}
                        // onClick={}
                        type="submit"
                      >
                        {/* {!loading ? <CircularProgress size={"small"} > "next"</CircularProgress>  : "next"} */}
                        Next
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </form>
        </Box>
      </Modal>

      <Modal
        open={nextNexOpen}
        // open={true}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "720px", height: "fit-content" }}>
          <AppBar variant="elevation" sx={{
                height: "64px",
                paddingRight: "0px !important",
              }}>
            <Toolbar variant="regular">
              <Typography
                variant="body1"
                gutterBottom={false}
                width={"98%"}
                color={"white"}
                className="btn-text-style"
                sx={{ fontSize: "20px", fontWeight: 500 }}
              >
                Forgot Password
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div onClick={handleClose}
                  style={{ cursor: "pointer",height:'32px' }}
                >
                  <CloseIcon style={{ color: "#0000008F",fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <form onSubmit={handleSubmit(PasswordReset)}>
            <Container
              gap={"24px"}
              disableGutters
              style={{ height: "fit-content", marginTop: "66px" }}
            >
              <Grid container justifyContent={"start"} spacing={4}>
                <Grid item md={12} xl={12} spacing={2}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      marginBottom: "10px",
                    }}
                  >
                    New Password
                  </Typography>

                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "New Password is required",
                      minLength: 8,
                      pattern: {
                        value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/,
                        message:
                          "Password must be at least 8 characters long, contain at least one number, one special character, and one uppercase letter.",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        // label="New Password"
                        variant="outlined"
                        fullWidth
                        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                        // onChange={someChangeHandler}

                        error={!!errors.password}
                        helperText={
                          errors.password ? errors.password.message : ""
                        }
                        {...field}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xl={12} spacing={2}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      marginBottom: "10px",
                    }}
                  >
                    Confirm Password
                  </Typography>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: "Confirm Password is required",
                      minLength: 8,
                      pattern: {
                        value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/,
                        message:
                          "Password must be at least 8 characters long, contain at least one number, one special character, and one uppercase letter.",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        // label="Confirm Password"
                        variant="outlined"
                        fullWidth
                        type={showConformPassword ? "text" : "password"} // <-- This is where the magic happens
                        // onChange={someChangeHandler}

                        error={!!errors.password}
                        helperText={
                          errors.confirmPassword
                            ? errors.confirmPassword.message
                            : ""
                        }
                        {...field}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConformPassword}
                                onMouseDown={handleMouseDownConformPassword}
                              >
                                {showConformPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item container justifyContent={"end"} spacing={2}>
                  <Grid item spacing={2}>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        Cancel{" "}
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default Modals;
