import { API } from "../../config/api/index";
import { getItem } from "../../utility/localStorage";

//ALL TYPE WISE DATA RETURN
const initState = {
  //   login: Cookies.get("logedIn") ? Cookies.get("logedIn") : false,
  login: getItem("loggedIn") ? getItem("loggedIn") : false,
  loading: false,
  userData: {},
  shopInfoData: {},
};

//DEFINE AUTH REDUCER FUNCTION
const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case API.auth.login:
      return {
        ...state,
        login: action.login,
        userData: action.userData,
        loading: action.loading,
      };
    case API.auth.register:
      return {
        ...state,
        shopInfoData: action.shopInfoData,
        loading: action.loading,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default AuthReducer;
