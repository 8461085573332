import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Link,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";

import { useTheme } from "@emotion/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { acceptDeclineAppointment } from "../../redux/Dashboard/action";
import SuccessMessageModal from "../Common/SuccessMessage";
import "../Estimates/Estimates.css";
import FormatNumber from "./../../components/FormatNumber/FormatNumber";
import AddAssigneeModal from "./AddAssigneeModal";
import EditEstimatePickUpModal from "./EditEstimatePickUpModal";
import RequestProposeTimeModel from "./RequestProposeTimeModel";
import "./modal.css";
import { getCalendarSettingAssignee } from "../../redux/Calendar/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 491,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // pt: 2,
  // px: 4,
  // pb: 3,
};

const AppointmentRequestModal = ({
  open,
  handleClose,
  appointmentData,
  id,
  handleCancelAppointment,
  apiCall,
}) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const [showModel, setShowModel] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showAssignee, setShowAssignee] = React.useState(false);
  const [showEstimatePickUp, setShowEstimatePickUp] = React.useState(false);
  const [estimatePickUpDate, setEstimatePickUpDate] = React.useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [defaultcalendarData, setDefaultCalendarData] = useState({});
  useEffect(() => {
    fetchDefaultSetting();
  }, []);

  const fetchDefaultSetting = async () => {
    const resp = await dispatch(getCalendarSettingAssignee());
    if (resp) {
      setDefaultCalendarData(resp?.data);
    }
  };

  const handleDecline = async () => {
    setShowLoader(true);
    const payload = {
      status: "decline",
    };
    const data = await dispatch(acceptDeclineAppointment(id, payload));
    if (data) {
      setOpenSuccessModal(true);
      setSuccessMessage("Appointment request was successfully declined");
      setShowLoader(false);
      apiCall();
      handleClose();
    }
  };
  const handleAccept = async () => {
    if (defaultcalendarData?.skipAssigneeSelection) {
      setShowLoader(true);
      const payload = {
        status: "accept",
        assignee: defaultcalendarData?.defaultAssigneeId,
      };
      const data = await dispatch(acceptDeclineAppointment(id, payload));
      if (data) {
        setOpenSuccessModal(true);
        setSuccessMessage("Appointment request accepted");
        setShowLoader(false);
        apiCall();
        handleClose();
      }
    } else {
      setShowAssignee(true);
    }
  };

  const handleProposeTime = () => {
    setShowModel(true);
  };
  const handleClosePropose = () => {
    setShowModel(false);
  };
  const handleCloseAssigneeModal = () => {
    setShowAssignee(false);
  };
  const handleCloseEsatimatePickUpModal = () => {
    setShowEstimatePickUp(false);
  };

  const handleCancle = () => {
    handleCancelAppointment();
    setEstimatePickUpDate(null);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} md={9} xl={9}>
          <Modal
            open={open}
            onClose={handleCancelAppointment}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                ...style,
                height: "fit-content",
                maxHeight: "90%",
                width: "900px",
                border: "none",
              }}
            >
              <AppBar
                className="modalHeader"
                variant="elevation"
                sx={{
                  height: "64px",
                  borderRadius: "8px 8px 0px 0px !important",
                  paddingRight: "0px !important",
                  boxShadow: "none",
                }}
              >
                <Toolbar variant="regular">
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    width={"98%"}
                    color={"black"}
                  >
                    Appointment Request
                  </Typography>
                  <Stack direction={"row"} spacing={0}>
                    <div
                      onClick={handleCancle}
                      className="appointmentModal"
                      style={{ cursor: "pointer", height: "32px" }}
                    >
                      <CloseIcon style={{ fontSize: 32 }} />
                    </div>
                  </Stack>
                </Toolbar>
              </AppBar>
              <Container
                gap={2}
                disableGutters
                style={{ marginTop: "45px" }}
                sx={{
                  overflowY: "auto",
                  maxHeight: "80vh",
                  scrollbarWidth: "thin",
                }}
              >
                <Grid container style={{ backgroundColor: "#F5F5F7" }}>
                  <Grid
                    container
                    item
                    xs={5.3}
                    md={5.3}
                    xl={5.3}
                    style={{
                      borderRight: "1px solid #C4C4C4",
                      marginTop: "10px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      xl={12}
                      style={{ paddingLeft: "20px", backgroundColor: "white" }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                        marginTop={"20px"}
                      >
                        Appointment Information
                      </Typography>
                      <Grid
                        item
                        md={12}
                        lg={12}
                        xl={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        fontWeight={400}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"358px"}
                      >
                        <Grid container item>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Customer
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(
                                    `/customers/${appointmentData?.customer?._id}/${appointmentData?.customer?.createdFrom}`
                                  )
                                }
                              >
                                {appointmentData?.customer?.firstName
                                  ? appointmentData?.customer?.firstName
                                  : " "}{" "}
                                {appointmentData?.customer?.lastName
                                  ? appointmentData?.customer?.lastName
                                  : ""}
                              </Link>
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Drop Off
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {appointmentData &&
                                appointmentData?.dropOffDateTime &&
                                moment(appointmentData?.dropOffDateTime).format(
                                  "MMM D, YYYY hh:mm A"
                                )}
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Estimated Pick Up
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowEstimatePickUp(true);
                                }}
                              >
                                {estimatePickUpDate != null
                                  ? moment(new Date(estimatePickUpDate)).format(
                                      "MMM D, YYYY h:mm A"
                                    )
                                  : appointmentData?.pickupDateTime
                                  ? moment(
                                      appointmentData?.pickupDateTime
                                    ).format("MMM D, YYYY hh:mm A")
                                  : moment(
                                      appointmentData?.shopClosingTime
                                    ).format("MMM D, YYYY hh:mm A")}
                              </Link>
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Customer Wants to
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {appointmentData?.waitingOnSite &&
                              appointmentData?.waitingOnSite ==
                                "dropping_off_car"
                                ? "Dropping Off Car"
                                : " Wait on Site "}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                        marginTop={"20px"}
                      >
                        Contact Preferences
                      </Typography>

                      <Typography
                        item
                        md={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        fontWeight={400}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"358px"}
                      >
                        {appointmentData?.contactPreferences &&
                        appointmentData?.contactPreferences == "phone"
                          ? "Phone Call"
                          : appointmentData?.contactPreferences == "email"
                          ? "Email"
                          : "In-App Message"}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                        marginTop={"20px"}
                      >
                        Note from {appointmentData?.customer?.firstName}
                      </Typography>
                      <Typography
                        item
                        md={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        fontWeight={400}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"358px"}
                        // height={"100%"}
                        maxHeight={"60px"}
                        sx={{
                          overflowY: "scroll",
                          scrollbarWidth: "thin",
                          whiteSpace: "pre-line",
                        }}
                        marginBottom={"20px"}
                      >
                        {appointmentData?.noteForMechanic
                          ? appointmentData?.noteForMechanic
                          : " "}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* <Divider orientation="vertical" flexItem  /> */}

                  <Grid container item xs={6} md={6} xl={6}>
                    <div>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                        style={{
                          paddingLeft: "20px",
                          paddingTop: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          fontSize={"16px"}
                        >
                          Vehicle Information
                        </Typography>
                        <Grid
                          item
                          md={12}
                          lg={12}
                          xl={12}
                          border={"1px solid #C4C4C4"}
                          padding={"16px"}
                          fontWeight={400}
                          borderRadius={"4px"}
                          fontSize={"16px"}
                          marginTop={"7px"}
                          width={"450px"}
                          bgcolor={"white"}
                        >
                          <Grid container item>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                Vehicle
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                {appointmentData?.vehicle?.year
                                  ? appointmentData?.vehicle?.year + " "
                                  : " "}
                                {appointmentData?.vehicle?.vehicleMake?.name
                                  ? appointmentData?.vehicle?.vehicleMake
                                      ?.name + " "
                                  : ""}
                                {appointmentData?.vehicle?.vehicleModel?.name
                                  ? appointmentData?.vehicle?.vehicleModel?.name
                                  : ""}
                              </Typography>
                            </Grid>
                            <Divider
                              style={{
                                padding: "5px ",
                                width: "100%",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2px",
                                marginBottom: "12px",
                              }}
                            />
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                Trim
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                {appointmentData?.vehicle?.vehicleModelTrim
                                  ?.name
                                  ? appointmentData?.vehicle?.vehicleModelTrim
                                      ?.name
                                  : " - "}
                              </Typography>
                            </Grid>

                            <Divider
                              style={{
                                padding: "5px ",
                                width: "100%",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2px",
                                marginBottom: "12px",
                              }}
                            />
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                VIN
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                {appointmentData?.vehicle?.vin
                                  ? appointmentData?.vehicle?.vin
                                  : " - "}
                              </Typography>
                            </Grid>
                            <Divider
                              style={{
                                padding: "5px ",
                                width: "100%",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2px",
                                marginBottom: "12px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Licence Plate
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {appointmentData?.vehicle?.licenseNumber
                                ? appointmentData?.vehicle?.licenseNumber
                                : " - "}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                        style={{ paddingLeft: "20px", paddingTop: "15px" }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          fontSize={"16px"}
                        >
                          Services
                        </Typography>
                        <Grid
                          item
                          md={12}
                          lg={12}
                          xl={12}
                          border={"1px solid #C4C4C4"}
                          padding={"16px"}
                          fontWeight={400}
                          borderRadius={"4px"}
                          fontSize={"16px"}
                          marginTop={"7px"}
                          width={"450px"}
                          bgcolor={"white"}
                          maxHeight={"40vh"}
                          overflow={"auto"}
                          sx={{ scrollbarWidth: "thin" }}
                        >
                          <Grid container item>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                Total Services
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                {appointmentData?.services?.length}
                              </Typography>
                            </Grid>

                            {appointmentData &&
                              appointmentData?.services?.map((item) => {
                                return (
                                  <>
                                    <Divider
                                      style={{
                                        padding: "5px ",
                                        width: "100%",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "2px",
                                        marginBottom: "12px",
                                      }}
                                    />
                                    <Grid
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        fontWeight={400}
                                        fontSize={"16px"}
                                      >
                                        {item?.name || item?.serviceName}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "16px",
                                        }}
                                      >
                                        {item?.pricingType === "no_price" ? (
                                          "No Price"
                                        ) : item?.pricingType === "fixed" ? (
                                          item?.fixedPrice && (
                                            <FormatNumber
                                              inputValue={item.fixedPrice}
                                            />
                                          )
                                        ) : item?.pricingType === "range" ? (
                                          <>
                                            <FormatNumber
                                              inputValue={item?.priceMin}
                                            />{" "}
                                            -{" "}
                                            <FormatNumber
                                              inputValue={item?.priceMax}
                                            />
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Typography>
                                    </Grid>
                                    {item?.questionnaire?.map((row) => {
                                      return (
                                        <>
                                          <Grid item md={12}>
                                            <Typography
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                marginTop: "5px",
                                              }}
                                              variant="body1"
                                            >
                                              {" "}
                                              {row?.question}
                                            </Typography>
                                          </Grid>
                                          <Grid item md={12}>
                                            <Typography
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                marginTop: "5px",
                                                marginBottom: "5px",
                                              }}
                                              variant="body1"
                                            >
                                              {" "}
                                              {row?.answer}
                                            </Typography>
                                          </Grid>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              })}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        justifyContent={"end"}
                        spacing={2}
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        <Grid item>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={handleDecline}
                            >
                              DECLINE
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                handleProposeTime();
                              }}
                            >
                              PROPOSE NEW TIME
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleAccept}
                            >
                              ACCEPT
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </div>{" "}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Modal>
        </Grid>
      </Grid>

      <RequestProposeTimeModel
        shopEndTime={appointmentData?.shopClosingTime}
        open={showModel}
        handleClose={handleClosePropose}
        id={appointmentData?._id}
        handleClose2={handleClose}
        apiCall={apiCall}
      />
      <AddAssigneeModal
        open={showAssignee}
        handleClose={handleCloseAssigneeModal}
        id={appointmentData?._id}
        handleCloseRequestModal={handleClose}
        apiCall={apiCall}
      />
      <EditEstimatePickUpModal
        open={showEstimatePickUp}
        handleClose={handleCloseEsatimatePickUpModal}
        setEstimatePickUpDate={setEstimatePickUpDate}
        id={appointmentData?._id}
        dropOffDate={appointmentData?.dropOffDateTime}
      />
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AppointmentRequestModal;
