import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNotification } from "../../redux/Notification/action";
import "./Sitemaintenance.css";
const SiteMaintennance = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = window.history.length;
  const getNotificationPopup = async () => {
    await dispatch(getNotification(3, 1, true));
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("maintenance") == "false" ||
      !sessionStorage.getItem("maintenance")
    ) {
      navigate(history > 2 ? -1 : "/");
    }

    const interval = setInterval(() => {
      getNotificationPopup();
      // window.location.reload();
      if (
        sessionStorage.getItem("maintenance") == "false" ||
        !sessionStorage.getItem("maintenance")
      ) {
        navigate(history > 2 ? -1 : "/");
      }
    }, [3000]);
    return () => clearInterval(interval);
  }, [navigate]);
  return (
    <div className="siteMaintenece">
      <img src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/0292ed60691497.5a56156fd6284.jpg" />
      {/* <h1>Sorry! We are under maintenance currently!!</h1> */}
    </div>
  );
};

export default SiteMaintennance;
