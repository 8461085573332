import { Add } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import FormatNumber, {
  FormatNumberForKm,
  FormatNumberForKmFloat,
} from "../../components/FormatNumber/FormatNumber";
import { getShopPriceDetails } from "../../redux/Account/ShopDetails/action";
import {
  serviceCategoryData,
  serviceSubcategoryData,
} from "../../redux/Estimate/action";
import { CustomPaper } from "../../utility/SelectDropdown";
import { validateNumberPositive } from "../Common/validateNumberPositive";
import ServiceAddPartModal from "./ServiceAddPartModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 3,
};

const ServiceModal = ({
  open,
  setAllServices,
  allServices,
  setEditServiceData,
  // handleClose,
  // id,
  // serviceModalDataProps,
  // servicePartsModalDataProps,
  // getData,
  editServiceData,
  setOpenServiceModal,
  addedServices,
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      pricingType: "fixed",
    },
  });
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const selectRef = useRef(null);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [serviceData, setServiceData] = React.useState("");
  const [serviceNameData, setServiceNameData] = React.useState(null);
  const [subCategoryData, setSubCategoryData] = React.useState([]);

  const [cancelClicked, setCancelClicked] = React.useState(false);

  const [data, setData] = React.useState("");
  const [partsData, setPartsData] = React.useState([]);
  const [partsOpen, setPartsOpen] = React.useState(false);
  const [editPartData, setEditPartData] = useState({ edit: false, index: "" });
  const [partsId, setPartsId] = React.useState("");
  const [pricingType, setPricingType] = React.useState("fixed");
  const [serviceModalData, setServiceModalData] = useState([]);
  const [partServiceModalData, setPartServiceModalData] = useState([]);
  const [page, setPage] = useState(1);
  const [servicePageData, setServicePageData] = useState({
    totalpages: "",
    currentPage: "",
  });
  const [errValidation, setErrValidation] = useState(null);

  const [categoryData, setCategoryData] = useState([{}]);
  const [serviceCategory, setServiceCategory] = React.useState("");
  const [showServiceLoader, setShowServiceLoader] = useState(false);
  const [addPartError, setAddPartError] = useState(false);
  const [showServiceCategoryLoader, setShowServiceCategoryLoader] =
    React.useState(false);
  const limit = 1000;
  const [showEditSkeleton, setShowEditSkeleton] = useState(false);
  const cancelTokenSourceRef = useRef(null);
  const modelRef = useRef();
  const [againScroll, setAgainScroll] = useState(false);
  const handleCloseServiceModal = async () => {
    reset();
    setServiceNameData(null);
    setServiceCategory("");
    setSubCategoryData([]);
    setPricingType("fixed");
    setPartsData([]);
    setEditServiceData({ edit: false, index: "" });
    setErrValidation("");
    setAddPartError(false);
    setOpenServiceModal(false);

    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to modal close."
      );
    }
  };
  const [defaultPriceData, setDefaultPriceData] = useState("");

  // useEffect(() => {
  //   // services();
  //   servicesName(page, limit);
  // }, []);

  const fetchCategory = async () => {
    setShowServiceCategoryLoader(true);
    const resp = await dispatch(serviceCategoryData(5000));

    setCategoryData(resp);
    setShowServiceCategoryLoader(false);
  };

  useEffect(() => {
    fetchCategory();
    setSubCategoryData([]);
    getShopPrice();
  }, []);

  const getShopPrice = async () => {
    const data = await dispatch(getShopPriceDetails());
    if (data) {
      setDefaultPriceData(data?.defaultHourlyRate);
      if (!editServiceData?.edit) {
        setValue("laborRate", data?.defaultHourlyRate);
      }
    }
  };

  const onSubmit = (values) => {
    toast.dismiss();
    let value = { ...values };
    clearErrors();

    if (values?.pricingType == "fixed" && values?.fixedPrice) {
      const valueFixedprice = values?.fixedPrice?.toString()?.replace(/,/g, "");
      if (valueFixedprice == 0) {
        toast.error("Fixed price should be greater than 0.");
        return setError("fixedPrice", {
          message: "Fixed price should be greater than 0.",
        });
      }
      value.fixedPrice = valueFixedprice;
    }

    if (values?.pricingType == "labor_&_parts") {
      value.laborQuantity = value.laborQuantity?.toString()?.replace(/,/g, "");
      value.laborRate = value.laborRate?.toString()?.replace(/,/g, "");
      // value.laborQuantity=value.laborQuantity?.toString()?.replace(/,/g, "");
    }

    value.service = serviceNameData?.serviceSubCategory;

    value.category = serviceCategory;
    let found;
    if (editServiceData.edit == true) {
      // if (
      //   serviceNameData == undefined
      // ) {
      //   setErrValidation("");
      // } else {
      //   found = allServices?.find(
      //     (element, index) =>
      //       element?.service?._id == serviceNameData?.serviceSubCategory?._id
      //   );
      //   if (found) {
      //     setErrValidation("This service already exist in records.");
      //     return;
      //   }
      // }
    } else {
      // found = allServices?.find(
      //   (element, index) =>
      //     element?.service?._id == serviceNameData?.serviceSubCategory?._id
      // );
      // if (found) {
      //   setErrValidation("This service already exist in records.");
      //   return;
      // }
    }

    // "Please add a part for this service."
    setAgainScroll(false);

    if (value?.pricingType == "labor_&_parts") {
      if (partsData?.length == 0) {
        setAgainScroll(true);
        if (modelRef.current) {
          modelRef.current.scrollTo({
            top: modelRef.current.scrollHeight,
            behavior: "smooth",
          });
        }

        return setAddPartError(true);
      } else if (partsData?.length > 0) {
        value["parts"] = [...partsData];
        setAddPartError(false);
      } else {
        value["parts"] = [...partsData];
        setAddPartError(false);
      }
    }

    if (editServiceData?.edit) {
      let all = [...allServices, ...(addedServices ?? [])];
      let OnlyScheduledServices = [...allServices];
      let newArray = [
        ...all.slice(0, editServiceData?.index),
        ...all.slice(editServiceData?.index + 1),
      ];

      let alreadyAdded = newArray.filter(
        (d) => JSON.stringify(d.service) == JSON.stringify(value.service)
      );

      if (alreadyAdded.length > 0) {
        return toast.error("This service already added");
      }
      OnlyScheduledServices[editServiceData?.index] = value;
      setAllServices(OnlyScheduledServices);
      setEditServiceData({
        edit: false,
        index: "",
      });
    } else {
      let all = [...allServices, ...(addedServices ?? [])];

      let alreadyAdded = all.filter(
        (d) => JSON.stringify(d.service) == JSON.stringify(value.service)
      );
      if (alreadyAdded.length > 0) {
        return toast.error("This service already added");
      }

      setAllServices([...allServices, value]);
    }

    handleCloseServiceModal();
    setPartsData([]);
    setPricingType("fixed");
    setAddPartError(false);
    reset();
    setErrValidation("");
    setServiceCategory("");
  };

  useEffect(() => {
    if (againScroll) {
      modelRef.current.scrollTo({
        top: modelRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [againScroll]);
  const handlePartsOpen = () => {
    setPartsOpen(true);
    setErrValidation("");
  };

  const handleCancel = () => {
    setPricingType("Fixed_Price");
    // removeItem("service_parts");
    setCancelClicked(false);
    setPartsData("");
    setPartServiceModalData([]);
    // handleClose();
    setValue("serviceName", "");
    setValue("fixedPrice", "");
    setValue("laborRate", "");
    setValue("laborQuantity", "");
    setServiceData("");
    setServiceNameData("");
    setPartsId("");
    setErrValidation("");
  };

  const handleRemovePart = (index) => {
    const newData = partsData?.filter((item, idx) => idx !== index);

    setPartServiceModalData(newData);
    setPartsData(newData);
  };

  const services = async () => {
    let serviceDetailsData = await dispatch(serviceCategoryData());
    setData(serviceDetailsData);
  };
  const servicesName = async (page, limit, catogryId) => {
    let id;
    if (catogryId) {
      id = catogryId;
    } else {
      id = undefined;
    }

    // Cancel API call on recall
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }

    cancelTokenSourceRef.current = axios.CancelToken.source();

    setShowServiceLoader(true);

    let serviceSubCategoryData = await dispatch(
      serviceSubcategoryData(
        page,
        limit,
        catogryId,
        cancelTokenSourceRef.current.token
      )
    );

    if (serviceSubCategoryData) {
      if (page > 1) {
        setSubCategoryData([
          ...subCategoryData,
          ...serviceSubCategoryData.data,
        ]);
      } else {
        setSubCategoryData([...serviceSubCategoryData.data]);
      }
      setServicePageData({
        totalpages: serviceSubCategoryData?.totalPages,
        currentPage: serviceSubCategoryData?.currentPage,
      });
      setShowServiceLoader(false);
    }
    if (serviceSubCategoryData && editServiceData.edit == true) {
      setShowEditSkeleton(false);

      let data = allServices[editServiceData.index];
      let found = serviceSubCategoryData?.data.filter(
        (d) => d.serviceSubCategory._id == data?.service?._id
      )[0];
      setServiceNameData(found);
      setValue("service", found);
    }
    if (!serviceSubCategoryData) {
      // setShowServiceLoader(false);
      setShowEditSkeleton(false);
      // setSubCategoryData([]);
      // setServiceCategory("");
    }
  };
  useEffect(() => {
    reset();
    if (editServiceData.edit == true) {
      setShowEditSkeleton(true);
      editService(editServiceData.index);
    } else {
      setSubCategoryData([]);
      setShowServiceLoader(false);
    }
  }, [editServiceData]);

  useEffect(() => {
    if (open && !editServiceData?.edit) {
      setValue("laborRate", defaultPriceData);
    }
  }, [open]);
  const editService = async (idx) => {
    let data = allServices[idx];
    // console.log("data", data);
    setValue("fixedPrice", FormatNumberForKm(data?.fixedPrice) ?? 0);

    setValue(
      "pricingType",
      data?.pricingType == "range" ? "fixed" : data?.pricingType
    );
    setValue("laborRate", FormatNumberForKm(data?.laborRate));
    setValue("laborQuantity", FormatNumberForKm(data?.laborQuantity));

    setPricingType(data?.pricingType == "range" ? "fixed" : data?.pricingType);
    if (data?.pricingType == "labor_&_parts") {
      setPartsData(data?.parts);
    }
    if (data?.category) {
      setValue(
        "serviceCategory",
        data && categoryData.filter((d) => d._id == data?.category)?.[0]
      );
      setServiceNameData({
        serviceSubCategory: {
          _id: data?.service?._id,
          name: data?.service?.name,
        },
      });
      await servicesName(1, 5000, data?.category);
      setServiceCategory(data.category);
    } else {
      if (typeof data?.serviceCategory == "string") {
        setServiceNameData({
          serviceSubCategory: {
            _id: data?.serviceId,
            name: data?.serviceName,
          },
        });
        setValue(
          "serviceCategory",
          data &&
            categoryData.filter((d) => d._id == data?.serviceCategory)?.[0]
        );
        await servicesName(1, 5000, data?.serviceCategory);
        setServiceCategory(data.serviceCategory);
      } else {
        setServiceNameData({
          serviceSubCategory: {
            _id: data?.service?._id,
            name: data?.service?.name,
          },
        });
        setValue(
          "serviceCategory",
          data &&
            categoryData.filter((d) => d._id == data?.serviceCategory?._id)?.[0]
        );
        await servicesName(1, 5000, data?.serviceCategory?._id);
        setServiceCategory(data.serviceCategory?._id);
      }
    }
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      parseInt(target.scrollHeight - target.scrollTop) ===
        parseInt(target.clientHeight) &&
      servicePageData.totalpages > page
    ) {
      setPage(page + 1);
      servicesName(page + 1, limit);
    }
  };
  function validate(s) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  }

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleCloseServiceModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              ...style,
              border: "none",
              borderRadius: "8px 8px 0px 0px",
            }}
            className="modalBodyBoxContainer"
          >
            <AppBar
              color="secondary"
              variant="elevation"
              sx={{
                height: "64px",
                borderRadius: "8px 8px 0px 0px !important",
                paddingRight: "0px !important",
              }}
            >
              <Toolbar variant="regular">
                <Typography variant="h6" width={"98%"} color={"white"}>
                  {editServiceData.edit == true
                    ? "Edit Service"
                    : "Add Service"}
                </Typography>
                <Stack direction={"row"}>
                  <div
                    onClick={() => {
                      handleCloseServiceModal();
                    }}
                    style={{ cursor: "pointer", height: "32px" }}
                  >
                    <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
                  </div>
                </Stack>
              </Toolbar>
            </AppBar>
            <Container
              // gap={2}
              disableGutters
              style={{
                marginTop: "64px",
              }}
            >
              <Grid
                container
                // spacing={3}
                style={{
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                  overflowY: "auto",
                  maxHeight: "78vh",
                }}
                padding={"24px 24px 0 24px"}
                ref={modelRef}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  marginBottom={"24px"}
                  paddingTop={"0"}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Service Category
                    </Typography>

                    {/* <Select
                      name="serviceCategory"
                      value={serviceCategory}
                      onChange={(event) => {
                        setServiceCategory(event.target.value);
                        servicesName(1, 5000, event.target.value);
                        setSubCategoryData([]);
                      }}
                      displayEmpty
                    >
                      {Array.isArray(categoryData) &&
                        categoryData?.map((item) => {
                          return (
                            <MenuItem value={item?._id}>{item?.name}</MenuItem>
                          );
                        })}
                      {showServiceCategoryLoader && (
                        <MenuItem disabled>
                          <CircularProgress />{" "}
                        </MenuItem>
                      )}
                    </Select> */}
                    <Controller
                      name="serviceCategory"
                      control={control}
                      rules={{
                        required: "Service Category is required",
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          PaperComponent={CustomPaper}
                          {...field}
                          disablePortal
                          id="combo-box-demo"
                          options={
                            categoryData
                              ? categoryData.sort((a, b) =>
                                  a.name.localeCompare(b.name)
                                )
                              : []
                          }
                          getOptionLabel={(option) => option?.name}
                          onChange={(event, newValue) => {
                            setValue("serviceCategory", newValue);
                            setSubCategoryData([]);
                            setPage(1);

                            setServiceCategory(newValue?._id);
                            servicesName(1, 1000, newValue?._id);

                            setServiceNameData(null);
                            // setServiceNameData(null);
                          }}
                          ListboxProps={{
                            style: { maxHeight: "300px" },
                            onScroll: (event) => {
                              handleScroll(event);
                            },
                          }}
                          loading={showServiceCategoryLoader}
                          loadingText={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <CircularProgress />
                            </div>
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              error={!!errors?.serviceCategory}
                              helperText={
                                errors?.serviceCategory
                                  ? errors?.serviceCategory?.message
                                  : ""
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  marginBottom={"24px"}
                  paddingTop={"0"}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Service Name
                    </Typography>{" "}
                  </Stack>

                  {showEditSkeleton ? (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      sx={{ fontSize: "3.5rem" }}
                    />
                  ) : (
                    <Controller
                      name="service"
                      control={control}
                      rules={{
                        required: "Service Name is required",
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          PaperComponent={CustomPaper}
                          disablePortal
                          id="combo-box-demo"
                          options={subCategoryData ?? []}
                          getOptionLabel={(option) =>
                            option?.serviceSubCategory?.name
                          }
                          value={serviceNameData ?? null}
                          disabled={serviceCategory ? false : true}
                          onChange={(event, newValue) => {
                            // field.onChange(
                            //   JSON.stringify(newValue?.serviceSubCategory)
                            // );
                            setServiceNameData(newValue);
                            setValue("service", newValue);
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "300px",
                            },
                            onScroll: (event) => {
                              handleScroll(event);
                            },
                          }}
                          loading={showServiceLoader}
                          loadingText={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <CircularProgress />
                            </div>
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              error={!!errors?.service}
                              helperText={
                                errors?.service ? errors?.service?.message : ""
                              }
                            />
                          )}
                          renderOption={(props, option) => (
                            <>
                              <Box component="li" {...props}>
                                <li>{option?.serviceSubCategory?.name}</li>
                              </Box>
                              {showServiceLoader &&
                                props["subCategoryData-option-index"] + 1 ==
                                  subCategoryData?.length && (
                                  <Box component="li" {...props}>
                                    <li {...props}>
                                      <CircularProgress />
                                    </li>
                                  </Box>
                                )}
                            </>
                          )}
                        />
                      )}
                    />
                  )}
                </Grid>
                {/* {errValidation && (
                  <Typography className="err-validation">
                    {errValidation}
                  </Typography>
                )} */}
                <Grid
                  item
                  // xs={12}
                  // md={12}
                  // xl={12}
                  marginBottom={"24px"}
                  paddingTop={"0"}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Pricing
                    </Typography>
                    {/* <FormControl> */}
                    <Controller
                      name="pricingType"
                      control={control}
                      rules={{
                        required: "Pricing is required",
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          // aria-labelledby="demo-radio-buttons-group-label"
                          // defaultValue="fixed"

                          // value={pricingType}
                          onChange={(e) => {
                            setPricingType(e?.target?.value);
                            setValue("pricingType", e.target.value);
                            if (e.target.value == "labor_&_parts")
                              setValue("laborRate", defaultPriceData);
                          }}
                          error={!!errors.pricingType}
                          helperText={
                            errors.pricingType ? errors.pricingType.message : ""
                          }
                        >
                          <FormControlLabel
                            value="fixed"
                            control={<Radio />}
                            label="Fixed Price"
                          />
                          <FormControlLabel
                            value="labor_&_parts"
                            control={<Radio />}
                            label="Labor and Parts"
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors.pricing && (
                      <FormHelperText error={!!errors.pricing}>
                        {errors.pricing && errors.pricing.message}
                      </FormHelperText>
                    )}
                    {/* </FormControl> */}
                  </Stack>
                </Grid>
                {pricingType == "labor_&_parts" ? (
                  <>
                    <Grid item xs={12} md={12} xl={12}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "20px",
                          lineHeight: "32px",
                          letterSpacing: "0.15px",
                        }}
                        marginBottom={"24px"}
                      >
                        Labor
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={12} xl={12} marginBottom={"24px"}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Quantity (Number of Hours)
                        </Typography>

                        <Controller
                          name="laborQuantity"
                          control={control}
                          rules={{
                            required: "Quantity is required",
                            validate: (value) =>
                              validateNumberPositive(
                                value,
                                "Quantity should be greater than 0."
                              ),
                          }}
                          render={({ field }) => (
                            <>
                              <TextField
                                fullWidth
                                variant="outlined"
                                error={!!errors.laborQuantity}
                                helperText={
                                  errors.laborQuantity
                                    ? errors.laborQuantity.message
                                    : ""
                                }
                                value={field.value}
                                onChange={(event) => {
                                  const inputValue = event.target.value;
                                  const numericValue = inputValue.replace(
                                    /[^\d.]/g,
                                    ""
                                  );
                                  setValue(
                                    "laborQuantity",
                                    FormatNumberForKmFloat(numericValue)
                                  );
                                }}
                                // {...field}
                              />
                            </>
                          )}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={12} xl={12} marginBottom={"24px"}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Rate (Per Hour)
                        </Typography>

                        <Controller
                          name="laborRate"
                          control={control}
                          rules={{
                            required: "Rate is required",
                            validate: (value) =>
                              validateNumberPositive(
                                value,
                                "Rate should be greater than 0."
                              ),
                          }}
                          render={({ field }) => (
                            <>
                              <TextField
                                fullWidth
                                variant="outlined"
                                error={!!errors.laborRate}
                                helperText={
                                  errors.laborRate
                                    ? errors.laborRate.message
                                    : ""
                                }
                                value={field.value} // Ensure the TextField value is controlled by the field value
                                onChange={(event) => {
                                  const inputValue = event.target.value;
                                  const numericValue = inputValue.replace(
                                    /[^\d.]/g,
                                    ""
                                  );
                                  setValue(
                                    "laborRate",
                                    FormatNumberForKmFloat(numericValue)
                                  );
                                }}
                              />
                            </>
                          )}
                        />
                      </Stack>
                    </Grid>

                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "20px",
                          lineHeight: "32px",
                          letterSpacing: "0.15px",
                        }}
                        marginBottom={"24px"}
                      >
                        Parts
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent={"space-between"}
                      spacing={2}
                      // marginBottom={"24px"}
                    >
                      <Grid item>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={<Add />}
                          onClick={() => {
                            handlePartsOpen();
                          }}
                        >
                          Add Part
                        </Button>
                      </Grid>
                    </Grid>
                    {addPartError && partsData?.length <= 0 ? (
                      <Typography
                        variant="body1"
                        sx={{ marginTop: "16px", marginLeft: "0px" }}
                        color={"error"}
                      >
                        Please add a part for this service.
                      </Typography>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <Grid item xs={12} md={12} xl={12}>
                      <Grid item marginBottom={"24px"}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Fixed Price
                          </Typography>

                          <Controller
                            name="fixedPrice"
                            control={control}
                            rules={{
                              required: "Fixed Price is required",
                              // validate: validateNumberPositive,
                            }}
                            render={({ field }) => (
                              <>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  error={!!errors.fixedPrice}
                                  helperText={
                                    errors.fixedPrice
                                      ? errors.fixedPrice.message
                                      : ""
                                  }
                                  value={field.value} // Ensure the TextField value is controlled by the field value
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const numericValue = inputValue.replace(
                                      /[^\d.]/g,
                                      ""
                                    );
                                    setValue(
                                      "fixedPrice",
                                      FormatNumberForKmFloat(numericValue)
                                    );
                                  }}
                                />
                              </>
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Stack spacing={2} direction="column">
                  {pricingType == "labor_&_parts" &&
                    partsData &&
                    partsData?.map((item, index) => {
                      return (
                        <Accordion
                          style={{
                            width: "99%",
                            padding: "24px",
                            margin: "24px auto 0px auto",
                            borderRadius: "8px",
                            border: "2px solid #0000001F",
                          }}
                          expanded={expanded == item?.partsId}
                          onChange={handleChange(item?.partsId)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ backgroundColor: "#ecdeff" }}
                          >
                            <Typography>{item?.partName}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              backgroundColor: "#fff",
                              padding: "24px 0 0px",
                            }}
                          >
                            <Grid container rowGap={2}>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                xl={12}
                                key={item.id}
                                rowGap={2}
                              >
                                <Stack rowGap={2}>
                                  <Stack direction={"row"}>
                                    <Typography
                                      variant="title"
                                      fontWeight={"bold"}
                                      paddingRight={"10px"}
                                    >
                                      Part Number:
                                    </Typography>
                                    <Typography variant="body1">
                                      {item?.partNumber}
                                    </Typography>
                                  </Stack>
                                  <Stack direction={"row"}>
                                    <Typography
                                      variant="title"
                                      fontWeight={"bold"}
                                      paddingRight={"10px"}
                                    >
                                      Part Provider:
                                    </Typography>
                                    <Typography variant="body1">
                                      {item.partsProvider == "used"
                                        ? "Used"
                                        : item.partsProvider == "oem"
                                        ? "OEM"
                                        : "After Market"}
                                    </Typography>
                                  </Stack>
                                  <Stack direction={"row"}>
                                    <Typography
                                      variant="title"
                                      fontWeight={"bold"}
                                      paddingRight={"10px"}
                                    >
                                      Description:
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      fontWeight={400}
                                    >
                                      {item.description}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Grid>
                              <Divider style={{ width: "100%" }} />
                              <Grid item>
                                <Stack rowGap={2}>
                                  <Stack direction={"row"}>
                                    <Typography
                                      variant="title"
                                      paddingRight={"10px"}
                                      fontWeight={"bold"}
                                    >
                                      Quantity:
                                    </Typography>
                                    <Typography variant="body1">
                                      {item.qty}
                                    </Typography>
                                  </Stack>
                                  <Stack direction={"row"}>
                                    <Typography
                                      variant="title"
                                      paddingRight={"10px"}
                                      fontWeight={"bold"}
                                    >
                                      Cost:
                                    </Typography>
                                    <Typography variant="body1">
                                      <FormatNumber inputValue={item?.cost} />
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Grid>
                              <Divider style={{ width: "100%" }} />
                              <Grid item>
                                <Stack>
                                  <Stack direction={"row"}>
                                    <Typography
                                      variant="title"
                                      paddingRight={"10px"}
                                      fontWeight={"bold"}
                                    >
                                      Total Amount:
                                    </Typography>
                                    <Typography variant="body1">
                                      <FormatNumber
                                        inputValue={item?.totalAmount}
                                      />
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Grid>
                              <Grid item container justifyContent={"end"}>
                                <Stack spacing={2} direction="row">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // setPartsId(item?.partsId);
                                      handlePartsOpen();
                                      setEditPartData({
                                        edit: true,
                                        index: index,
                                      });
                                    }}
                                  >
                                    EDIT PART
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                      handleRemovePart(index);
                                    }}
                                  >
                                    REMOVE PART
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </Stack>
              </Grid>

              <Grid item container justifyContent={"space-between"} p={3}>
                <div>
                  <Stack spacing={2} direction="row">
                    {editServiceData?.edit && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          const newdata = [...allServices];
                          let newArray = [
                            ...newdata.slice(0, editServiceData?.index),
                            ...newdata.slice(editServiceData?.index + 1),
                          ];
                          setAllServices(newArray);
                          handleCloseServiceModal();
                        }}
                      >
                        REMOVE SERVICE
                      </Button>
                    )}
                  </Stack>
                </div>

                <div>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleCloseServiceModal();
                        setServiceCategory("");
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      SAVE
                    </Button>
                  </Stack>
                </div>
              </Grid>
            </Container>
          </Box>
        </form>
      </Modal>
      <ServiceAddPartModal
        open={partsOpen}
        setPartsOpen={setPartsOpen}
        setPartsData={setPartsData}
        partsData={partsData}
        setEditPartData={setEditPartData}
        editPartData={editPartData}
      />
      {/* <Loader showLoader={loading} /> */}
    </Box>
  );
};

export default ServiceModal;
