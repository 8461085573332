import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
    loading: false,
    appointmentData: {},
    appointmentListData: {},
    appointmentDetailsData: {},
    mechanicDetailsData:{}
};

//DEFINE ACCOUNT REDUCER FUNCTION
const AppointmentReducer = (state = initState, action) => {
    switch (action.type) {
        case API.appointment.kanbanBoardList:
            return {
                ...state,
                appointmentData: action.appointmentData,
                loading: action.loading,
            };
        case API.appointment.appointmentList:
            return {
                ...state,
                appointmentListData: action.appointmentListData,
                loading: action.loading,
            };
        case API.appointment.appointmentDetails:
            return {
                ...state,
                appointmentDetailsData: action.appointmentDetailsData,
                loading: action.loading,
            };
            case API.appointment.mechanicDetails:
                return {
                    ...state,
                    mechanicDetailsData: action.mechanicDetailsData,
                    loading: action.loading,
                };
        default:
            return state;
    }
};

//EXPORT ALL FUNCTION
export default AppointmentReducer;
