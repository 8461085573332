export const PHONE_TYPES = [
  {
    key: "mobile",
    value: "Mobile",
  },
  {
    key: "work",
    value: "Work",
  },
  {
    key: "home",
    value: "Home",
  },
  {
    key: "landline",
    value: "Landline",
  },
];

export const CONTACT_PREFRENCES = [
  {
    key: "callOnPhone",
    value: "Phone Call",
  },
  {
    key: "email",
    value: "Email",
  },
  {
    key: "inAppMessages",
    value: "In-App Message",
  },
];
