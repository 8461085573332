import React, { useState } from "react";
// import { Button, IconButton } from "@material-ui/core";
// import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Button, IconButton } from "@mui/material";
import { EditOutlined, PhotoCamera } from "@mui/icons-material";
import { removeItem, setItem } from "../../../utility/localStorage";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import { toast } from "sonner";
const CustomImageUploadButton = ({ setUploadedImage, edit, index }) => {
  const handleFileChange = (event) => {
    if (event.target.files[0].type.startsWith("image/")) {
      setUploadedImage(event.target.files[0]);
      event.target.value = null;
    } else toast.info("Please select only images");
  };
  return (
    <div className="card-image uploadImageBtn ">
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        id="image-upload-button"
        onChange={handleFileChange}
      />
      {!edit && (
        <label
          htmlFor="image-upload-button"
          onClick={() => removeItem("index", index)}
        >
          <Button variant="contained" color="secondary" component="span">
            Choose Image
          </Button>
        </label>
      )}
      {edit && (
        <label
          htmlFor="image-upload-button"
          onClick={() => setItem("index", index + 1)}
        >
          <EditOutlined
            // style={{ color: "white", cursor: "pointer" }}
            className="icon edit"

            // onClick={() => handleEdit(index)}
          />
        </label>
      )}
      {/* <IconButton
        color="secondary"
        aria-label="upload picture"
        component="span"
      >
        <PhotoCamera />
      </IconButton> */}
    </div>
  );
};

export default CustomImageUploadButton;
