import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { currentPlan, plansList } from "../../../redux/Account/Billing/action";
import ChangeSubscriptionModal from "./ChangePlan";
import "./Subscription.css";
import SubscriptionToggle from "./ToggleButton";
import AddpaymentModel from "./AddpaymentModel";
const SubscriptionTiers = () => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  const theme = useTheme();
  const dispatch = useDispatch();
  const [alignment, setAlignment] = React.useState("web");
  const [activeButton, setActiveButton] = useState("month");
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [addCardModal, setAddCardModal] = useState(false);

  const [newPlanDetail, setNewPlanDetail] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleChangeButton = (type) => {
    setActiveButton(type);
  };

  useEffect(() => {
    fetchPlans();
    fetchCurrentPlan();
  }, [activeButton]);
  const fetchPlans = async () => {
    setShowLoader(true);
    await dispatch(plansList({ interval: activeButton }));
    setShowLoader(false);
  };
  const plans = useSelector((state) => state.account.plansList);
  console.log("🚀 ~ SubscriptionTiers ~ plans:", plans);

  const fetchCurrentPlan = async () => {
    setShowLoader(true);

    await dispatch(currentPlan());
    setShowLoader(false);
  };
  const currentPlanDetails = useSelector((state) => state.account.currentPlan);
  console.log("currentPlanDetails", currentPlanDetails);

  useEffect(() => {
    const sections = document.querySelectorAll(".summary-section");
    let maxHeight = 0;

    sections.forEach((section) => {
      const sectionHeight = section.offsetHeight;
      if (sectionHeight > maxHeight) {
        maxHeight = sectionHeight;
      }
    });

    sections.forEach((section) => {
      section.style.height = `${maxHeight}px`;
    });
  }, [plans]);
  return (
    <Box padding={"32px 24px"} sx={{ bgcolor: "background.main" }}>
      <Grid container spacing={0} sx={{ padding: "16px 0px" }} gap={"8px"}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/account-settings/billing"
                style={{
                  color: "black",
                  opacity: "0.6",
                  textDecoration: "none",
                }}
              >
                Account
              </Link>
              <Link
                // to={`/customers/${id}`}
                style={{
                  color: "black",
                  opacity: "0.6",

                  textDecoration: "none",
                }}
              >
                <Typography color="black">Billing</Typography>
              </Link>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent={"space-between"}
          spacing={2}
          rowGap={2}
        >
          <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
            <Typography variant="h4">
              Apex Mechanic Subscription Tiers
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            xl={12}
            mb={3}
            mt={1}
            display={"flex"}
            justifyContent={"center"}
            borderBottom={"1px solid  rgba(0, 0, 0, 0.12)"}
            paddingBottom={1}
            marginLeft={2}
          >
            <SubscriptionToggle
              activeButton={activeButton}
              handleChangeButton={handleChangeButton}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          spacing={3}
          rowGap={2}
        >
          {plans?.map((plan, index) => (
            <Grid
              item
              lg={plans?.length > 3 ? 3 : 4}
              md={plans?.length > 3 ? 6 : 4}
              sm={plans?.length > 3 ? 6 : 4}
              xs={12}
              xl={plans?.length > 3 ? 3 : 4}
            >
              <Card
                className={`
               
                ${
                  plan?.ifFreeTrial
                    ? "planCard"
                    : plan?.nameOfProduct == "premium"
                    ? "planPremiumCard"
                    : "planCard"
                }
                 ${plan?.isCurrentSubscription && "activeCard"} `}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  xl={12}
                  mb={"24px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="h5">
                    {}
                    {plan?.ifFreeTrial
                      ? "Free Trial"
                      : plan?.nameOfProduct == "mobile"
                      ? "Mobile Essentials"
                      : plan?.nameOfProduct == "web"
                      ? "Web Access"
                      : "Premium"}
                  </Typography>
                  {plan?.isCurrentSubscription && (
                    <Chip
                      label="Current Plan"
                      sx={{
                        background: "#75DBFF",
                        color: "#002394",
                      }}
                    ></Chip>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} xl={12} mb={"24px"}>
                  <Stack direction={"row"}>
                    <Typography variant="h3">
                      {plan?.ifFreeTrial ? (
                        `${plan?.totalDays} Days`
                      ) : (
                        <>
                          $
                          {plan?.priceWithoutDiscountInDollar?.toLocaleString()}
                          <sub style={{ fontSize: "16px" }}>/mo</sub>
                        </>
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                {plan?.ifFreeTrial ? (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    xl={12}
                    mb={"24px"}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      {`Free trial ends ${plan?.endDate}. `}
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      xl={12}
                      mb={"24px"}
                    >
                      <Typography variant="body1">
                        {plan?.interval == "month" && "Billed monthly"}
                        {plan?.interval == "year" &&
                          `Billed at $${plan?.unitAmountInDollars.toLocaleString()}/yr (${
                            plan?.percentageDiscount
                          }%  discount from monthly)`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      xl={12}
                      mb={"24px"}
                    >
                      <span className="summary-section" ref={sectionRef}>
                        <Typography
                          variant="body1"
                          color={
                            plan?.nameOfProduct == "premium"
                              ? "white"
                              : "rgba(0, 0, 0, 0.6)"
                          }
                        >
                          {plan?.subscriptionProduct?.summary}
                        </Typography>
                      </span>
                    </Grid>
                  </>
                )}
                {!plan?.ifFreeTrial && plan?.buttonLabel != "" && (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    xl={12}
                    mb={"24px"}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      sx={{
                        background:
                          plan?.nameOfProduct == "premium"
                            ? "rgba(117, 219, 255, 1)"
                            : "primary",
                      }}
                      fullWidth
                      onClick={() => {
                        if (plan?.switchToYear == true) {
                          setActiveButton("year");
                        } else if (plan?.buttonLabel == "SWITCH TO MONTHLY") {
                          setActiveButton("month");
                        } else {
                          setNewPlanDetail(plan);
                          // setAddCardModal(true);
                          {
                            currentPlanDetails?.isManualSubscription
                              ? setAddCardModal(true)
                              : setOpenSubscriptionModal(true);
                          }
                        }
                      }}
                    >
                      {plan?.buttonLabel}
                    </Button>
                  </Grid>
                )}

                <Grid item lg={12} md={12} sm={12} xs={12} xl={12} mb={"24px"}>
                  <List>
                    {plan?.subscriptionProduct?.features?.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <CheckIcon style={{ marginRight: "24px" }} />{" "}
                          <Typography variant="body1"> {item}</Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <AddpaymentModel
        addCardModal={addCardModal}
        setAddCardModal={setAddCardModal}
        setModalLoader={setShowLoader}
        fetchCurrentPlan={fetchCurrentPlan}
        setSelectedCard={setSelectedCard}
        setOpenSubscriptionModal={setOpenSubscriptionModal}
      />
      <ChangeSubscriptionModal
        openSubscriptionModal={openSubscriptionModal}
        setOpenSubscriptionModal={setOpenSubscriptionModal}
        currentPlan={currentPlanDetails}
        newPlan={newPlanDetail}
        fetchCurrentPlan={fetchCurrentPlan}
        setAddCardModal={setAddCardModal}
        selectedCard={selectedCard}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SubscriptionTiers;
