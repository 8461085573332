import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Modal,
  Select,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form"; // Step 1
import { useDispatch } from "react-redux";
import { changeAssignee } from "../../../redux/Dashboard/action";
import { mechanicDetailsList } from "../../../redux/appointment/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import "../../Estimates/Estimates.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AddAssigneeModal = ({
  open,
  handleClose,
  data,
  setIsAssigneeChanged,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [assigneeData, setAssigneeData] = React.useState("");
  const [assigneeList, setAssigneeList] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const assigneecList = async () => {
    let data = await dispatch(mechanicDetailsList());
    setAssigneeList(data);
  };

  useEffect(() => {
    assigneecList();

    if (data) {
      setAssigneeData(data?.assignee?.[0]?._id);
    }
  }, [data, open]);

  const onSubmit = async () => {
    setShowLoader(true);
    const payload = {
      id: data?._id,
      assigneeIds: [assigneeData],
    };
    const resp = await dispatch(changeAssignee(payload));
    if (resp) {
      setIsAssigneeChanged(true);
      setOpenSuccessModal(true);
      setSuccessMessage("Appointment assignee changed");
      handleClose();
    }
    setShowLoader(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} md={9} xl={9}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {" "}
              <Box
                sx={{
                  ...style,
                  height: "fit-content",
                  width: "848px",
                  border: "none",
                }}
              >
                <AppBar
                  variant="elevation"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#002394",
                    height: "64px",
                    paddingRight: "0px !important",
                  }}
                >
                  <Toolbar variant="regular">
                    <Typography variant="h6" fontWeight={500} width={"98%"}>
                      Add Assignee
                    </Typography>
                    <Stack direction={"row"} spacing={0}>
                      <div
                        onClick={handleClose}
                        style={{ cursor: "pointer", height: "32px" }}
                      >
                        <CloseIcon style={{ fontSize: 32 }} />
                      </div>
                    </Stack>
                  </Toolbar>
                </AppBar>

                <Container gap={4} disableGutters style={{ marginTop: "66px" }}>
                  <Grid container padding={"10px 0px"}>
                    <Grid container item xs={12} md={12} xl={12} spacing={3}>
                      <Grid item xs={12} md={12} xl={12}>
                        <Stack spacing={0}>
                          <Typography
                            variant="body1"
                            className="label-500"
                            fontWeight={500}
                            fontSize={16}
                          >
                            Assign To
                          </Typography>
                          <Select
                            value={assigneeData}
                            onChange={(event) => {
                              setAssigneeData(event.target.value);
                            }}
                            displayEmpty
                            required
                          >
                            {assigneeList &&
                              assigneeList?.map((item, index) => {
                                return (
                                  <MenuItem value={item?._id} key={index}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent={"end"}
                        spacing={2}
                        style={{ marginTop: "0px" }}
                      >
                        <Grid item>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleClose}
                            >
                              CANCEL
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              SAVE
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </form>
          </Modal>
        </Grid>
      </Grid>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddAssigneeModal;
