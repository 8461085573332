import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getWorkOrderAuthorizationHistory } from "../../redux/WorkOrderAuthorization/action";
import "./Historymodel.css";
import { Scrollbar } from "react-scrollbars-custom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 624,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  //   p: 3,
  //   padding
};
function HistoryModel({ id, open, setOpen, data }) {
  const dispatch = useDispatch();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open) fetchHistory();
  }, [open]);

  const fetchHistory = async () => {
    setLoading(true);
    const resp = await dispatch(getWorkOrderAuthorizationHistory(id));
    console.log("🚀 ~ fetchHistory ~ resp:", resp);
    if (resp) {
      setHistory(resp?.data);
    }
    setLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style} className="modalBodyBoxContainer">
        <AppBar
          color="secondary"
          variant="elevation"
          sx={{
            height: "64px",
            borderRadius: "8px 8px 0px 0px !important",
            paddingRight: "0px !important",
            justifyContent: "center",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            display={"flex"}
            // paddingTop={1.9}
            paddingInline={2}
          >
            <Typography variant="h6" color={"white"}>
              History
            </Typography>
            <div
              onClick={handleClose}
              style={{ cursor: "pointer", height: "32px" }}
            >
              <CloseIcon sx={{ fill: "white", fontSize: 35 }} />
            </div>
          </Stack>
        </AppBar>

        <Box
          mt={8}
          ml={0}
          // minHeight={loading ? "20vh" : ""}
          // maxHeight={"40vh"}
          // overflow={loading ? "" : "auto"}
          display={loading ? "flex" : ""}
          alignItems={"center"}
          justifyContent={"center"}
          className="boxStyle"
        >
          {!loading && (
            <Scrollbar
              trackXVisible={true}
              trackYVisible={true}
              style={{
                width: "100%",

                height: "30vh",
                // minHeight: loading ? "20vh" : "100%",
              }}
              className="boxStyle"
            >
              {history?.map(
                (hist) =>
                  hist?.message && (
                    <Grid container mb={3}>
                      <Grid item xs={12}>
                        <Typography fontWeight={500} fontSize={16}>
                          {hist?.message}
                        </Typography>{" "}
                      </Grid>
                      <Grid item xs={12}>
                        {dayjs(hist?.date).format("DD-MMM-YYYY hh:mma")}
                      </Grid>
                    </Grid>
                  )
              )}
            </Scrollbar>
          )}

          {loading && <CircularProgress />}
          {/* <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography fontWeight={500} fontSize={16}>
                Edited service and sent re-authorization to customer
              </Typography>{" "}
            </Grid>
            <Grid item>
              <Typography>
                {" "}
                {dayjs("2024-08-13T09:18:58.695Z").format("DD-MMM-YYYY hh:mma")}
              </Typography>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </Modal>
  );
}

export default HistoryModel;
