import { FormControl } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import SuccessMessageModal from "../../container/Common/SuccessMessage";
import { configDetails } from "../../redux/Account/action";
import {
  addVehicle,
  decodeLicence,
  decodeVIN,
  editCustomerVehicle,
  editImportedVehicle,
  vehicleMakeMaster,
  vehicleModelMaster,
  vehicleTrimMaster,
  vehicleYearMaster,
} from "../../redux/Customer/action";
import { fetchProvinces } from "../../redux/Vehicles/action";
import useDebounce from "../../utility/useDebounce";
import { checkValidVIN } from "../../utility/utilityFunctions";
import "./VehicleModel.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
  outline: "none",
};
function VehicleModel({
  open,
  handleClose,
  importedVehicleId,
  editVehicle,
  setShowLoader,
  setEditVehicle,
  setImportedVehicleId = () => {},
  fetchCustomerVehicle,

  setDeleteVehicleId,
  setOpenVehicleDelete,
  id = null,
}) {
  console.log("🚀 ~ editVehicle:", editVehicle);
  const dispatch = useDispatch();
  const [step, setStep] = useState(editVehicle?.edit == true ? 2 : 1);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [vinLoading, setVINLoading] = useState(false);
  const [yearValue, setYearValue] = useState("");
  const [provinceValue, setProvinceValue] = useState("");
  const [loader, setLoader] = useState(false);
  console.log("🚀 ~ loader:", loader);
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [trimValue, setTrimValue] = useState("");
  const [trimEngineValue, setTrimEngineValue] = useState("");

  const [makeValuePage, setMakeValuePage] = useState(1);
  const [modelValuePage, setModelValuePage] = useState(1);
  const [trimValuePage, setTrimValuePage] = useState(1);
  // const [year, setYear] = useState([]);
  const [provinces, setProvince] = useState([]);

  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [trim, setTrim] = useState([]);
  const [makeLoader, setMakeLoader] = useState(false);
  const [provinceLoader, setProvinceLoader] = useState(false);

  const [modelLoader, setModelLoader] = useState(false);
  const [trimLoader, setTrimLoader] = useState(false);
  const [vehicles, setvehicles] = useState([]);
  const [yearPageLimit, setYearPageLimit] = useState({ page: 1, limit: 1000 });
  const [decode, setDecode] = useState({
    vin: false,
    licence: false,
  });
  // Watch the field value
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue: setValueinForm,
    getValues,
    setError,
    clearErrors,
    setFocus,
    watch,
  } = useForm();

  useEffect(() => {
    if (editVehicle?.edit == true) {
      console.log("🚀 ~ useEffect ~ editVehicle:", editVehicle);

      fetchVehicleMake({
        page: makeValuePage,
        limit: 1000,

        year: editVehicle?.data?.year ?? editVehicle?.edit?.data?.vehicleYear,
      });
      fetchVehicleModel({
        page: modelValuePage,
        limit: 1000,
        vehicleMakeId: editVehicle?.data?.vehicleMake?._id,
        year: editVehicle?.data?.year ?? editVehicle?.edit?.data?.vehicleYear,
      });
      fetchVehicleTrim({
        page: trimValuePage,
        limit: 1000,
        vehicleMakeId: editVehicle?.data?.vehicleMake._id,
        vehicleModelId: editVehicle?.data?.vehicleModel?._id,
        year: editVehicle?.data?.year ?? editVehicle?.edit?.data?.vehicleYear,
      });
      setValueinForm("vin", editVehicle?.data?.vin);
      setValueinForm(
        "year",
        (editVehicle?.data?.year ?? editVehicle?.data?.vehicleYear) + ""
      );

      setValueinForm("vehicleMakeId", editVehicle?.data?.vehicleMake?._id);
      setValueinForm("vehicleModelId", editVehicle?.data?.vehicleModel?._id);
      setValueinForm(
        "vehicleModelTrimId",
        editVehicle?.data?.vehicleModelTrim?._id
      );
      // if (type == SHOP_IMPORTED) {
      //   setYearValue(editVehicle?.data?.vehicleYear + "");
      // } else {
      setYearValue(
        (editVehicle?.data?.year ?? editVehicle?.data?.vehicleYear) + ""
      );
      // }
      setMakeValue(editVehicle?.data?.vehicleMake?._id);
      setModelValue(editVehicle?.data?.vehicleModel?._id);
      setTrimValue(editVehicle?.data?.vehicleModelTrim?._id);

      if (
        editVehicle?.data?.licenseNumber !== undefined &&
        editVehicle?.data?.licenseNumber !== null
      ) {
        setValueinForm("licenseNumber", editVehicle?.data?.licenseNumber);
      } else if (
        editVehicle?.data?.license !== undefined &&
        editVehicle?.data?.license !== null
      ) {
        setValueinForm("licenseNumber", editVehicle?.data?.license);
      }

      setValueinForm("province", editVehicle?.data?.province);
      setProvinceValue(editVehicle?.data?.province);
      setStep(2);
    } else {
      setStep(1);
    }
  }, [editVehicle]);

  useEffect(() => {
    if (open) {
      fetchConfig();
    }
  }, [open]);
  useEffect(() => {
    fetchVehicleYear();
    fetchProvincesList();
  }, []);

  const watchedLicenceValue = watch("licenseNumber", "");

  // Use debounce for the watched value
  const debouncedLicenceValue = useDebounce(watchedLicenceValue, 1000);

  const watchedVINValue = watch("vin", "");

  // Use debounce for the watched value
  const debouncedVINValue = useDebounce(watchedVINValue, 1000);

  const fetchConfig = async () => {
    await dispatch(configDetails());
  };
  const config = useSelector((state) => state.account.configDetailData);
  console.log("🚀 ~ config:", config);

  const fetchVehicleYear = async () => {
    await dispatch(
      vehicleYearMaster({
        page: yearPageLimit.page,
        limit: yearPageLimit.limit,
      })
    );
  };
  const year = useSelector((state) => state.customer?.vehicleYear);

  const fetchProvincesList = async () => {
    await dispatch(fetchProvinces());
  };
  const provincesList = useSelector((state) => state.vehicle.provinces)?.sort(
    (a, b) => a.province.localeCompare(b.province)
  );

  useEffect(() => {
    setProvince(provincesList);
  }, [provincesList]);

  useEffect(() => {
    if (!editVehicle?.edit) {
      const defaultProvince = provinces?.find((d) => d.isDefault === true)?._id;
      setValueinForm("province", defaultProvince);
      setProvinceValue(defaultProvince);
    }
  }, [provinces, open, provinceValue]);
  const fetchVehicleMake = async (vehicleMakeParams) => {
    setMakeLoader(true);
    await dispatch(vehicleMakeMaster(vehicleMakeParams));
    setMakeLoader(false);
  };

  const makes = useSelector((state) => state.customer?.vehicleMake);
  useEffect(() => {
    setMake(makes);
  }, [makes]);
  const fetchVehicleModel = async (vehicleModelParams) => {
    setModelLoader(true);
    await dispatch(vehicleModelMaster(vehicleModelParams));
    setModelLoader(false);
  };
  const models = useSelector((state) => state.customer?.vehicleModel);
  useEffect(() => {
    setModel(models);
  }, [models]);
  const fetchVehicleTrim = async (vehicleTrimParams) => {
    setTrimLoader(true);
    await dispatch(vehicleTrimMaster(vehicleTrimParams));
    setTrimLoader(false);
  };
  const trims = useSelector((state) => state.customer?.vehicleTrim);
  useEffect(() => {
    setTrim(trims);
  }, [trims]);
  const handleDecodeVIN = async () => {
    if (getValues()?.vin && getValues()?.vin.length == 17) {
      // setVINError("");
      clearErrors("vin");
      setLoader(true);
      setVINLoading(true);

      const resp = await dispatch(decodeVIN(getValues()?.vin));
      console.log("🚀 ~ handleDecodeVIN ~ resp:", resp);

      if (resp?.status) {
        // setModelSelectLoader(true);
        setMakeValue(resp?.data?.make?._id);
        setModelValue("");
        setTrimValue("");
        await dispatch(
          vehicleMakeMaster({
            page: 1,
            limit: 1000,
            year: resp?.data?.year,
          })
        );
        let para = {
          page: 1,
          limit: 1000,
          vehicleMakeId: resp?.data?.make?._id,
          year: resp?.data?.year,
        };
        let maekResult = await dispatch(vehicleModelMaster(para));
        if (maekResult) {
          let para = {
            page: 1,
            limit: 1000,
            vehicleMakeId: resp?.data?.make?._id,
            vehicleModelId: resp?.data?.model?._id,
            year: resp?.data?.year,
          };
          await dispatch(vehicleTrimMaster(para));
        }

        setValueinForm("year", resp?.data?.year + "");
        setValueinForm("vehicleMakeId", resp?.data?.make?._id);
        setValueinForm("vehicleModelId", resp?.data?.model?._id);
        setValueinForm("vehicleModelTrimId", resp?.data?.trim?._id);
        setYearValue(resp?.data?.year);
        setMakeValue(resp?.data?.make?._id);
        setModelValue(resp?.data?.model?._id);
        setTrimValue(resp?.data?.trim?._id);

        setVINLoading(false);
        if (step == 1) setStep(2);
      }
      setLoader(false);
    } else if (getValues()?.vin && getValues()?.vin.length == 0) {
      setVINLoading(false);
      setLoader(false);
      clearErrors("vin");
      // setVINError("Please Enter Valid VIN");
    } else {
      setVINLoading(false);
      setLoader(false);

      setError("vin", {
        message: "Please enter valid VIN",
      });
      // setVINError("Please Enter Valid VIN");

      // message.error("Please enter VIN number");
    }
  };
  const handleDecodeLicence = async (value) => {
    if (!provinceValue) {
      setError("province", {
        message: "Please select province",
      });
      return toast.info("Please select province");
    }
    if (provinceValue && debouncedLicenceValue) {
      setLoader(true);

      const resp = await dispatch(
        decodeLicence({
          licenceNumber: debouncedLicenceValue,
          provinceCode: provinces?.find((d) => d._id == provinceValue)
            ?.provinceCode,
        })
      );
      // setModelSelectLoader(true);
      console.log("resprespresp", resp);
      if (resp?.status) {
        setValueinForm("vin", resp?.data?.vin);
        handleDecodeVIN();
      } else {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    if (config?.isLicensePlateDecodeEnabled && debouncedLicenceValue) {
      // Replace with your fetch function
      if (decode?.licence) handleDecodeLicence(debouncedLicenceValue);
    }
  }, [debouncedLicenceValue]);
  useEffect(() => {
    if (debouncedVINValue) {
      // Replace with your fetch function
      if (decode?.vin) handleDecodeVIN();
    }
  }, [debouncedVINValue]);
  const onSubmit = async (values) => {
    if (values?.vin && values?.vin !== "") {
      if (checkValidVIN(values?.vin)) {
        clearErrors("vin");
      } else {
        return setError("vin", { message: "Please enter valid VIN" });
      }
    }
    let payload = {
      // ...values,
      year: values?.year,
      vehicleMakeId: values?.vehicleMakeId,
      vehicleModelId: values?.vehicleModelId,
      // vehicleModelTrimId: values?.vehicleModelTrimId,
      vin: values?.vin,
      licenseNumber: values.licenseNumber,
      provinceId: values?.province,
      customerId: id,
    };

    if (!values?.vin) {
      delete payload["vin"];
    }

    if (values.vehicleModelTrimId) {
      payload.vehicleModelTrimId = values?.vehicleModelTrimId;
    }
    setLoader(true);
    if (editVehicle?.edit) {
      let resp = "";
      if (importedVehicleId) {
        resp = await dispatch(editImportedVehicle(importedVehicleId, payload));
      } else {
        resp = await dispatch(editCustomerVehicle(editVehicle.id, payload));
      }
      if (resp) {
        setEditVehicle({ edit: false, index: "", id: "" });
        handelCloseModel();
        reset();
        setMakeValue("");
        setYearValue("");
        setModelValue("");
        setTrimValue("");
        setImportedVehicleId(null);
        fetchCustomerVehicle();
        setOpenSuccessModal(true);
        setSuccessMessage("Vehicle Updated");
      }
    } else {
      const resp = await dispatch(addVehicle(payload));
      if (resp?.status) {
        handelCloseModel();
        reset();
        setMakeValue("");
        setYearValue("");
        setModelValue("");
        setTrimValue("");
        fetchCustomerVehicle();
        setOpenSuccessModal(true);
        setSuccessMessage("New Vehicle Added");
      }
    }
    setLoader(false);
    // if (resp) {

    // }
  };
  const handelCloseModel = () => {
    handleClose();
    reset();
    setTrimEngineValue(null);
    setYearValue(null);
    setMakeValue(null);
    setModelValue(null);
    setTrimValue(null);
    setDecode({ vin: false, licence: false });
    setProvinceValue("");
  };
  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];

    if (firstErrorField) {
      // Select the error element using the name attribute
      const errorElement = document.querySelector(
        `[name="${firstErrorField}"]`
      );
      if (errorElement) {
        // Scroll to the error element smoothly
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        setTimeout(() => {
          const offset = 140; // Adjust this value based on your modal's header/footer
          const elementPosition = errorElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.scrollY - offset; // Absolute position adjustment

          // Ensure scrolling within the modal
          const modalContainer = document.querySelector(".MuiDialog-container"); // Adjust selector based on your modal structure
          if (modalContainer) {
            modalContainer.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }
          // Set focus to the error field
          setFocus(firstErrorField);
        }, 100); // Delay to ensure scroll completes
      }
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handelCloseModel}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            // width: "41vw",
            // width: "100%",
            // maxWidth: "50vw",
            // maxHeight: "90vh",
            // overflow: "hidden",
            // overflowY: "scroll",
          }}
          className="modelSize"
        >
          <AppBar color="secondary" variant="elevation" handelCloseModel>
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                {importedVehicleId
                  ? "Confirm Vehicle Information"
                  : editVehicle?.edit
                  ? "Edit Vehicle "
                  : "Add Vehicle"}
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handelCloseModel}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            // className="viewVehicleModal"
            style={{
              marginTop: "64px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit, onInvalid)} noValidate>
              <Grid
                container
                spacing={3}
                style={{
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                  overflow: "auto",
                  marginBottom: "24px",
                  maxHeight: "60vh",
                  padding: "24px",
                  paddingBottom: 0,
                }}
              >
                {" "}
                <Grid item xs={12} md={6} xl={6}>
                  <FormControl>
                    <Typography
                      variant="body1"
                      className="label-500"
                      style={{ fontWeight: 400 }}
                    >
                      Province
                      {config?.isLicensePlateRequired && (
                        <span style={{ color: "#D32F2F" }}> *</span>
                      )}
                    </Typography>
                    {console.log("errorserrorserrors", errors.province)}
                    {editVehicle?.edit &&
                    provinceLoader &&
                    provinceValue != null ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        sx={{ fontSize: "3.5rem" }}
                        className="skelton"
                      ></Skeleton>
                    ) : (
                      <Controller
                        name="province"
                        control={control}
                        rules={{
                          required: config?.isLicensePlateRequired
                            ? "Province is required"
                            : "",
                        }}
                        render={({ field }) => (
                          <Select
                            fullWidth
                            value={provinceValue || ""}
                            // disabled={yearValue !== "" ? false : true}

                            error={!!errors.province}
                            helperText={
                              errors.province ? errors.province.message : ""
                            }
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                            {...field}
                            onChange={(event) => {
                              console.log("🚀 ~ event:", event);
                              setProvinceValue(event.target.value);
                              field.onChange(event);
                              clearErrors("province");
                              // let licenseNumber = getValues("licenseNumber");
                              // licenseNumber = licenseNumber?.replace(/\s/g, "");

                              // setValueinForm(
                              //   "licenseNumber",

                              //   formatLicenceNumber(
                              //     licenseNumber,
                              //     event.target.value
                              //   )
                              // );
                            }}
                          >
                            {Array.isArray(provinces) &&
                              provinces?.map((m) => {
                                return (
                                  <MenuItem value={m?._id} key={m?._id}>
                                    {m?.province}
                                  </MenuItem>
                                );
                              })}
                            {provinceLoader && (
                              <MenuItem disabled>
                                <CircularProgress />
                              </MenuItem>
                            )}
                          </Select>
                        )}
                      />
                    )}
                    {errors.province && (
                      <FormHelperText error>
                        {errors.province.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <Typography
                    variant="body1"
                    className="label-500"
                    style={{ fontWeight: 400 }}
                  >
                    Licence Plate Number{" "}
                    {config?.isLicensePlateRequired && (
                      <span style={{ color: "#D32F2F" }}> *</span>
                    )}
                  </Typography>

                  <Controller
                    name="licenseNumber"
                    control={control}
                    rules={{
                      required: config?.isLicensePlateRequired
                        ? "Licence Plate Number is required"
                        : "",

                      // pattern: {
                      //   value: licensePlateRegex[provinceValue],
                      //   message: `Invalid Licence Number.Format:${provinceLicensePlatesExample[provinceValue]}`,
                      // },
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        // required
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.licenseNumber}
                        helperText={
                          errors.licenseNumber
                            ? errors.licenseNumber.message
                            : ""
                        }
                        // onInvalid={handleInvalid}
                        // inputProps={{
                        //   maxLength: getLicenceLength(provinceValue), // Limits input length to 17 characters
                        // }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setValueinForm(
                            "licenseNumber",
                            e.target.value?.toUpperCase()
                          );
                          setDecode({ ...decode, licence: true });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {" "}
                              {/* {licenseLoading ? (
                                <CircularProgress />
                              ) : ( */}
                              {config?.isLicensePlateDecodeEnabled &&
                                getValues("licenseNumber") &&
                                getValues("licenseNumber") !== "" && (
                                  <Button onClick={handleDecodeLicence}>
                                    Decode
                                  </Button>
                                )}
                              {/* // )} */}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {/* <TextField
                        id="outlined-basic"
                        variant="outlined"
                        fullWidth
                      /> */}
                </Grid>
                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    paddingLeft: "24px",
                    marginTop: "24px",
                  }}
                >
                  {step == 1 ? "or" : "and"}
                </Divider>
                <Grid item xs={12} md={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    VIN{" "}
                    {step == 1 && <span style={{ color: "#D32F2F" }}>*</span>}
                  </Typography>
                  <Controller
                    name="vin"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        // required
                        inputProps={{
                          maxLength: 17, // Limits input length to 17 characters
                        }}
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.vin}
                        helperText={errors.vin ? errors.vin.message : ""}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setDecode({ ...decode, vin: true });

                          setValueinForm("vin", e.target.value?.toUpperCase());
                          if (e.target.value !== "") {
                            const regex = checkValidVIN(e.target.value);
                            console.log("🚀 ~ regex:", regex);

                            if (regex) {
                              clearErrors("vin");
                              handleDecodeVIN();
                            } else {
                              setError("vin", {
                                message: "Please enter valid VIN",
                              });
                            }
                          } else {
                            clearErrors("vin");
                          }
                        }}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end">
                        //       {" "}
                        //       {vinLoading ? (
                        //         <CircularProgress />
                        //       ) : (
                        //         <></>
                        //         // <Button onClick={handleDecodeVIN}>
                        //         //   Decode
                        //         // </Button>
                        //       )}
                        //     </InputAdornment>
                        //   ),
                        // }}
                      />
                    )}
                  />
                  {/* <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                          /> */}
                  {/* <Typography style={{ color: "red" }}>
                            {vinError}
                          </Typography> */}
                </Grid>
                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    paddingLeft: "24px",
                    marginTop: "24px",
                  }}
                >
                  or
                </Divider>
                {step == 1 && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    <Button variant="outlined" onClick={() => setStep(2)}>
                      Enter details manually
                    </Button>
                  </Grid>
                )}
                {step == 2 && (
                  <>
                    <Grid item xs={12} md={12} xl={12}>
                      <FormControl>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Year <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          defaultValue=""
                          name="year"
                          control={control}
                          rules={{
                            required: "Year is required",
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              fullWidth
                              value={yearValue}
                              onChange={(event) => {
                                field.onChange(event);

                                setYearValue(event.target.value);
                                setMakeValue(null);
                                setModelValue(null);
                                setTrimValue(null);
                                setMake([]);
                                setModel([]);
                                setTrim([]);
                                fetchVehicleMake({
                                  page: makeValuePage,
                                  limit: 500,
                                  year: event.target.value,
                                });
                                setValueinForm("vehicleMakeId", "");
                                setValueinForm("vehicleModelId", "");
                                setValueinForm("vehicleModelTrimId", "");
                              }}
                              error={!!errors.year}
                              helperText={
                                errors.year ? errors.year.message : ""
                              }
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                            >
                              {Array.isArray(year) &&
                                year?.map((y) => {
                                  return (
                                    <MenuItem value={y} key={y}>
                                      {y}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          )}
                        />
                        {errors.year && (
                          <FormHelperText error>
                            {errors.year.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <FormControl>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Make <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        {editVehicle?.edit &&
                        makeLoader &&
                        makeValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          ></Skeleton>
                        ) : (
                          <Controller
                            name="vehicleMakeId"
                            defaultValue={""}
                            control={control}
                            rules={{
                              required: "Make is required",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                fullWidth
                                value={makeValue ? makeValue : ""}
                                disabled={yearValue !== "" ? false : true}
                                onChange={(event) => {
                                  field.onChange(event);

                                  setMakeValue(event.target.value);
                                  setModelValue(null);
                                  setModel([]);
                                  setTrim([]);
                                  setTrimValue(null);
                                  setValueinForm("vehicleModelId", "");
                                  setValueinForm("vehicleModelTrimId", "");
                                  fetchVehicleModel({
                                    page: modelValuePage,
                                    limit: 1000,
                                    vehicleMakeId: event.target.value,
                                    year: yearValue,
                                  });
                                }}
                                error={!!errors.vehicleMakeId}
                                helperText={
                                  errors.vehicleMakeId
                                    ? errors.vehicleMakeId.message
                                    : ""
                                }
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                              >
                                {Array.isArray(make) &&
                                  make?.map((m) => {
                                    return (
                                      <MenuItem value={m?._id} key={m?._id}>
                                        {m?.name}
                                      </MenuItem>
                                    );
                                  })}
                                {makeLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        )}
                        {errors.vehicleMakeId && (
                          <FormHelperText error>
                            {errors.vehicleMakeId.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <FormControl>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Model <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        {editVehicle?.edit &&
                        modelLoader &&
                        modelValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          ></Skeleton>
                        ) : (
                          <Controller
                            name="vehicleModelId"
                            control={control}
                            rules={{
                              required: "Model is required",
                            }}
                            render={({ field, fieldState }) => (
                              <Select
                                {...field}
                                fullWidth
                                value={modelValue}
                                disabled={
                                  yearValue !== "" && makeValue !== ""
                                    ? false
                                    : true
                                }
                                onChange={(event) => {
                                  field.onChange(event);
                                  setModelValue(event.target.value);
                                  setTrimValue(null);
                                  setValueinForm("vehicleModelTrimId", null);
                                  setTrim([]);

                                  fetchVehicleTrim({
                                    page: trimValuePage,
                                    limit: 1000,
                                    vehicleMakeId: makeValue,
                                    vehicleModelId: event.target.value,
                                    year: yearValue,
                                  });
                                }}
                                error={!!errors.vehicleModelId}
                                helperText={
                                  errors.vehicleModelId
                                    ? errors.vehicleModelId.message
                                    : ""
                                }
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                              >
                                {Array.isArray(model) &&
                                  model?.map((m) => {
                                    return (
                                      <MenuItem value={m?._id} key={m?._id}>
                                        {m?.name}
                                      </MenuItem>
                                    );
                                  })}

                                {modelLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        )}
                        {errors.vehicleModelId && (
                          <FormHelperText error>
                            {errors.vehicleModelId.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                      <FormControl>
                        <Typography
                          variant="body1"
                          className="label-500"
                          style={{ fontWeight: 400 }}
                        >
                          Trim
                          {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                        </Typography>
                        {editVehicle?.edit &&
                        trimLoader &&
                        trimValue != null ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "3.5rem" }}
                            className="skelton"
                          ></Skeleton>
                        ) : (
                          <Controller
                            name="vehicleModelTrimId"
                            control={control}
                            // rules={{
                            //   required: "Make is required",
                            // }}
                            render={({ field, fieldState }) => (
                              <Select
                                fullWidth
                                disabled={
                                  yearValue && makeValue && modelValue
                                    ? false
                                    : true
                                }
                                value={trimValue}
                                onChange={(event) => {
                                  field.onChange(event);

                                  setTrimValue(event.target.value);
                                  setTrimEngineValue(
                                    trim.find(
                                      (d) => d?._id == event.target.value
                                    )
                                  );
                                }}
                                error={!!errors.vehicleModelTrimId}
                                helperText={
                                  errors.vehicleModelTrimId
                                    ? errors.vehicleModelTrimId.message
                                    : ""
                                }
                                MenuProps={{
                                  style: { maxHeight: "400px" },
                                }}
                                // {...field}
                              >
                                {Array.isArray(trim) &&
                                  trim?.map((m) => {
                                    return (
                                      <MenuItem value={m?._id} key={m?._id}>
                                        <div>
                                          <div style={{ fontWeight: 500 }}>
                                            {m?.name}
                                          </div>
                                          <div>{m?.description}</div>
                                        </div>
                                      </MenuItem>
                                    );
                                  })}
                                {trimLoader && (
                                  <MenuItem disabled>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
                {console.log("trimEngineValue", trimEngineValue)}
                {trimValue &&
                  (trimEngineValue?.bodyType ||
                    trimEngineValue?.engineDriveType ||
                    trimEngineValue?.engineFuelType ||
                    trimEngineValue?.engineTransmission ||
                    trimEngineValue?.engineType) && (
                    <>
                      <Divider
                        textAlign="center"
                        style={{
                          width: "100%",
                          paddingLeft: "24px",
                          marginTop: "24px",
                        }}
                      ></Divider>
                      <Grid
                        container
                        xs={12}
                        md={12}
                        xl={12}
                        spacing={3}
                        style={{
                          // width: "100%",
                          marginLeft: "24px",
                          marginTop: "24px",
                          padding: 0,
                          background: "#F5F5F7",
                        }}
                        borderRadius={2}
                      >
                        {trimEngineValue?.engineType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineType}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.engineFuelType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Fuel Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineFuelType}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.engineDriveType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Drive Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineDriveType}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.engineTransmission && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 0px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Engine Transmission
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.engineTransmission}
                            </Typography>
                          </Grid>
                        )}
                        {trimEngineValue?.bodyType && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16px 0px 16px 16px" }}
                          >
                            <Typography fontWeight={500} fontSize={"16px"}>
                              Body Type
                            </Typography>
                            <Typography
                              fontWeight={400}
                              fontSize={"16px"}
                              color={"rgba(0, 0, 0, 0.6)"}
                            >
                              {trimEngineValue?.bodyType}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
              </Grid>

              <Grid
                item
                container
                justifyContent={"space-between"}
                padding={"24px"}
                paddingTop={"0px"}
              >
                <Grid item>
                  <Stack spacing={2} direction="row">
                    {editVehicle?.edit && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          handelCloseModel();

                          setDeleteVehicleId(editVehicle.id);
                          setOpenVehicleDelete(true);
                        }}
                        disabled={vehicles?.length == 1}
                        size="large"
                      >
                        DELETE
                      </Button>
                    )}
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handelCloseModel}
                      size="large"
                    >
                      Cancel
                    </Button>
                    {step == 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handelCloseModel}
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          setStep(2);
                        }}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handelCloseModel}
                        size="large"
                        type="submit"
                      >
                        Save
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <Dialog open={loader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </div>
  );
}

export default VehicleModel;
