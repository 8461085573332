// const FormatNumber = ({ inputValue }) => {
//   const numericValue = inputValue?.toString().replace(/[^0-9.]/g, "");

//   // Convert to number and format
//   const formattedValue = parseFloat(numericValue).toLocaleString("en-US");

//   //   return `$${parseInt(formattedValue).toFixed(2)}`;
//   return `$${formattedValue}`;
// };

const FormatNumber = ({ inputValue }) => {
  // Extract numeric part from input value and remove commas
  const numericValue = parseFloat(inputValue?.toString().replace(/,/g, ""));

  // Check if the numericValue is a valid number
  if (isNaN(numericValue)) {
    return "Invalid Number";
  }
  // Check if the value is a float (has a decimal part)
  let formattedValue;
  if (numericValue % 1 !== 0) {
    // Check if the decimal part is greater than zero
    if (parseFloat(numericValue) - parseInt(numericValue) > 0) {
      formattedValue = parseFloat(numericValue).toFixed(2);
      // Return as a float with two decimal places
    }
  } else {
    // If the value is an integer or a float with no decimal part greater than zero, return as an integer
    formattedValue = parseInt(numericValue, 10);
  }

  // Add commas and ensure the correct placement of the decimal point
  formattedValue = formattedValue
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${formattedValue}`;
};
export const FormatNumberNegativeValue = ({ inputValue }) => {
  // Extract numeric part from input value and remove commas
  const numericValue = parseFloat(inputValue?.toString().replace(/,/g, ""));

  // Check if the numericValue is a valid number
  if (isNaN(numericValue)) {
    return "Invalid Number";
  }

  // Format the value based on whether it's a float or integer
  let formattedValue;
  if (numericValue % 1 !== 0) {
    // It's a float, format to two decimal places
    formattedValue = numericValue.toFixed(2);
  } else {
    // It's an integer
    formattedValue = numericValue.toString();
  }

  // Add commas to the formatted value
  formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the formatted value with a dollar sign, retaining the sign of the number
  return `${numericValue < 0 ? "-" : ""}$${formattedValue.replace("-", "")}`;
};

export const FormatTotalNumbers = ({ inputValue }) => {
  // Extract numeric part from input value and remove commas
  const numericValue = parseFloat(inputValue?.toString().replace(/,/g, ""));

  // Check if the numericValue is a valid number
  if (isNaN(numericValue)) {
    return "Invalid Number";
  }

  // Format the value to two decimal places regardless of being a float or integer
  let formattedValue = numericValue.toFixed(2);

  // Add commas to the formatted value
  formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the formatted value with a dollar sign, retaining the sign of the number
  return `${numericValue < 0 ? "-" : ""}$${formattedValue.replace("-", "")}`;
};

export const FormatNumberForKm = (inputValue) => {
  if (inputValue) {
    const numericValue = inputValue?.toString().replace(/[^0-9.]/g, "");
    const formattedValue = parseFloat(numericValue).toLocaleString("en-US");
    return formattedValue;
  } else {
    return "";
  }
};

export const FormatNumberForKmFloat = (inputValue) => {
  if (inputValue) {
    const numericValue = inputValue.replace(/[^\d.]/g, ""); // Allow only digits and dot
    const parts = numericValue.split(".");

    // Format the integer part with commas
    const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Format the decimal part with commas
    const decimalPart =
      parts.length > 1
        ? `.${parts[1].replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""}`
        : "";

    const formattedValue = formattedInteger + decimalPart;
    return formattedValue;
  } else {
    return "";
  }
};

export function replaceComma(num) {
  return num?.replace(/,/g, "");
}

export const preventPhoneInputKeys = (event) => {
  // Get the key code of the pressed key
  const keyCode = event.keyCode || event.which;
  // Check if Ctrl key is pressed along with 'a' (Ctrl+A  = 65 ,Ctrl + C =67, Ctrl + V = 86)
  const isCtrlAPressed = (event.ctrlKey || event.metaKey) && keyCode === 65;
  const isCtrlCPressed = (event.ctrlKey || event.metaKey) && keyCode === 67;
  const isCtrlVPressed = (event.ctrlKey || event.metaKey) && keyCode === 86;

  if (
    !(
      (keyCode >= 48 && keyCode <= 57) || // digits 0-9
      keyCode === 8 || // backspace
      keyCode === 9 || // tab
      keyCode === 46 || // delete
      (keyCode >= 37 && keyCode <= 40) || // arrow keys
      (keyCode >= 96 && keyCode <= 105) || // arrow keys
      isCtrlAPressed ||
      isCtrlCPressed ||
      isCtrlVPressed
    )
  ) {
    event.preventDefault(); // Prevent input
  }
};

export const formatPostalCode = (inputValue) => {
  console.log("inputValue", inputValue);
  const trimmedValue = inputValue?.replace(/\s/g, "").toUpperCase(); // Remove spaces and convert to uppercase
  const value1 = trimmedValue?.slice(0, 3);
  const value2 = trimmedValue?.slice(3, 6); // Only take up to 3 more characters
  let formattedValue = value1;
  if (value2?.length > 0) {
    formattedValue += " " + value2;
  }
  return formattedValue; // Return the formatted value
};

export default FormatNumber;
