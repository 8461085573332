import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

export const getServiceCategory = (limit) => async (dispatch) => {
  try {
    const response = await DataService.get(
      `${API.account.shopServices.getServiceCategory}?limit=${limit}`
    );
    if (!response.data.error) {
      dispatch({
        type: API.account.shopServices.getServiceCategory,
        getServiceCategory: response.data.data.list,
      });
      return response.data.data.list;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getServiceSubCategory = (limit, category) => async (dispatch) => {
  try {
    const response = await DataService.get(
      `${API.account.shopServices.getServiceSubCategory}?limit=${limit}&serviceCategory=${category}`
    );
    if (!response.data.error) {
      return response.data.data.list;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const addShopService = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.account.shopServices.addShopService,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const updateShopService = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.account.shopServices.updateShopService}/${id}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const deleteServices = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        `${API.account.shopServices.deleteService}/${policyId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const getShopServicesListing =
  (
    limit,
    page,
    searchValue,
    selectedIds,
    selectedPriceType,
    sortedType,
    sortBy
  ) =>
    async (dispatch) => {
      try {
        const response = await DataService.get(
          `${API.account.shopServices.shopServicesListing
          }?limit=${limit}&page=${page}${searchValue && `&searchText=${searchValue}`
          }${selectedIds.length > 0
            ? `&serviceCategoryId=${selectedIds.join("&serviceCategoryId=")}`
            : ""
          }${selectedPriceType.length > 0
            ? `&priceType=${selectedPriceType.join("&priceType=")}`
            : ""
          }${sortedType ? `&sortType=${sortedType}` : ""}${sortBy ? `&sortBy=${sortBy}` : ""
          }`
        );
        if (!response.data.error) {
          dispatch({
            type: API.account.shopServices.shopServicesListing,
            shopServicesListing: response.data,
          });
          return response.data;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    };
export const updateShopServiceStatus = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.account.shopServices.updateShopServiceStatus}/${id}`,
        payload,

      );

      if (response.data.status) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};