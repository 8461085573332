import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";

import { useTheme } from "@emotion/react";
import { Close, ExpandMore } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  vehicleMake,
  vehicleModel,
  vehicleYear,
} from "../../../redux/Vehicles/action";
import { appointmentList } from "../../../redux/appointment/action";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "490px",
    marginTop: "100px",
    // height: "200px", // Adjust the height as needed
  },
  message: {
    fontSize: "20px",
    textAlign: "center",
  },
};
const PastAppointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showLoader, setShowLoader] = React.useState(false);
  const [showMenuLoader, setShowMenuLoader] = React.useState(false);
  const [id, setId] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [selectDate, setSelectDate] = React.useState(null);
  const [dateData, setDateData] = React.useState("");
  const [list, setList] = React.useState([]);
  const [yearData, setYearData] = React.useState("");
  const [statusData, setStatusData] = React.useState("");

  const [makeData, setMakeData] = React.useState("");
  const [modelData, setModelData] = React.useState("");
  const [makeName, setMakeName] = React.useState("");
  const [modelName, setModelName] = React.useState("");
  const [yearList, setYearList] = React.useState("");
  const [makeList, setMakeList] = React.useState("");
  const [modelList, setModelList] = React.useState("");
  const [sortName, setSortName] = React.useState("ASC");
  const [sortCategory, setSortCategory] = React.useState("ASC");
  const [sortId, setSortId] = React.useState("ASC");
  const [sortByName, setSortByName] = React.useState("");
  const [makeId, setMakeId] = React.useState("");
  const [calender, setCalender] = React.useState(false);

  const [yearServicePageData, setYearServicePageData] = React.useState({
    totalpages: "",
    currentPage: "",
  });
  const [makeServicePageData, setMakeServicePageData] = React.useState({
    totalpages: "",
    currentPage: "",
  });
  const [modelServicePageData, setModelServicePageData] = React.useState({
    totalpages: "",
    currentPage: "",
  });

  const [scrollPage, setScrollPage] = React.useState(1);
  const limit = 1000;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pastAppointmentData(
      "past",
      newPage + 1,
      rowsPerPage,
      search,
      yearData,
      makeData,
      modelData,
      "DSC",
      "dropOffDateTime",
      dateData
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    pastAppointmentData(
      "past",
      1,
      parseInt(event.target.value, 10),
      search,
      yearData,
      makeData,
      modelData,
      "DSC",
      "dropOffDateTime",
      dateData
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl(null);
  };

  const Data = useSelector((state) => state.appointment.appointmentListData);

  useEffect(() => {
    // vehicleYearData(scrollPage, limit);
    vehicleMakeData(scrollPage, limit);
    pastAppointmentData(
      "past",
      1,
      10,
      search,
      yearData,
      makeData,
      modelData,
      "DSC",
      "dropOffDateTime",
      dateData
    );
  }, []);

  const pastAppointmentData = async (
    timeRange,
    page,
    limit,
    search,
    year,
    make,
    model,
    sortType,
    sortBy,
    date,
    status
  ) => {
    setShowLoader(true);
    const data = await dispatch(
      appointmentList(
        timeRange,
        page,
        limit,
        search,
        year,
        make,
        model,
        sortType,
        sortBy,
        timeZone,
        date,
        status
      )
    );
    if (data) {
    }
    setShowLoader(false);
  };
  useEffect(() => {
    if (makeId) {
      vehicleModelData(scrollPage, limit, makeId);
    }
  }, [makeId]);
  const vehicleYearData = async (page, limit) => {
    setShowMenuLoader(true);
    let data = await dispatch(vehicleYear(page, limit));
    if (data) {
      setYearList([...yearList, ...data.data]);
      setShowMenuLoader(false);
      setYearServicePageData({
        totalpages: data?.totalPages,
        currentPage: data?.currentPage,
      });
    }
  };
  const vehicleMakeData = async (page, limit) => {
    setShowMenuLoader(true);
    let data = await dispatch(vehicleMake(page, limit));
    if (data) {
      setMakeList([...makeList, ...data.data]);
      setShowMenuLoader(false);
      setMakeServicePageData({
        totalpages: data?.totalPages,
        currentPage: data?.currentPage,
      });
    }
  };
  const vehicleModelData = async (page, limit) => {
    setShowMenuLoader(true);
    let data = await dispatch(vehicleModel(page, limit, makeId));
    if (data) {
      setModelList([...modelList, ...data.data]);
      setShowMenuLoader(false);
      setModelServicePageData({
        totalpages: data?.totalPages,
        currentPage: data?.currentPage,
      });
    }
  };
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }
  const onChange = async (e) => {
    setSearch(e);
    pastAppointmentData(
      "past",
      1,
      10,
      e,
      yearData,
      makeData,
      modelData,
      "DSC",
      "dropOffDateTime",
      dateData
    );
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      pastAppointmentData(
        "past",
        1,
        10,
        search,
        yearData,
        makeData,
        modelData,
        "DSC",
        "dropOffDateTime",
        dateData
      );
    }, 500); // Set the debounce time (milliseconds)

    // Cleanup function to cancel the previous setTimeout
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const debounceOnChange = React.useCallback(debounce(onChange, 800), []);
  const handleSearchChange = (e) => {
    setSearch(e);
  };
  const handleStatusChange = (event) => {
    setStatusData(event.target.value);
    pastAppointmentData(
      "past",
      1,
      10,
      search,
      yearData,
      makeData,
      modelData,
      "DSC",
      "dropOffDateTime",
      dateData,
      event.target.value
    );
  };

  const handleDateChange = (date) => {
    const newDate = new Date(date);
    let utcDate = moment(newDate).format("YYYY-MM-DD");
    setDateData(utcDate);
    pastAppointmentData(
      "past",
      1,
      10,
      search,
      yearData,
      makeData,
      modelData,
      "DSC",
      "dropOffDateTime",
      utcDate,
      statusData
    );
  };

  const handleYearChange = (event) => {
    setYearData(event.target.value);
    pastAppointmentData(
      "past",
      1,
      10,
      search,
      event.target.value,
      makeData,
      modelData,
      "DSC",
      "dropOffDateTime",
      dateData,
      statusData
    );
  };
  let foundObject;
  const handleMakeChange = (event) => {
    setMakeData(event.target.value);
    setModelData("");
    setMakeId(event.target.value);
    setModelList("");
    pastAppointmentData(
      "past",
      1,
      10,
      search,
      yearData,
      event.target.value,
      "",
      "DSC",
      "dropOffDateTime",
      dateData,
      statusData
    );
    foundObject = makeList.find((item) => item?._id === event.target.value);
    setMakeName(foundObject?.name);
  };

  const handleModelChange = (event) => {
    setModelData(event.target.value);
    pastAppointmentData(
      "past",
      1,
      10,
      search,
      yearData,
      makeData,
      event.target.value,
      "DSC",
      "dropOffDateTime",
      dateData,
      statusData
    );
    foundObject = modelList.find((item) => item?._id === event.target.value);
    setModelName(foundObject?.name);
  };

  const handleSortByName = (status, sortBy) => {
    pastAppointmentData(
      "past",
      page + 1,
      rowsPerPage,
      search,
      yearData,
      makeData,
      modelData,
      status,
      sortBy,
      dateData,
      statusData
    );
    // pastAppointmentData("past", newPage + 1, rowsPerPage, search, yearData, makeData, modelData, "DSC", "dropOffDateTime", dateData);
    if (sortBy == "fullName") {
      if (status == "DSC") {
        setSortName("ASC");
      } else {
        setSortName("DSC");
      }
      setSortCategory(sortCategory);
      setSortId(sortId);
    } else if (sortBy == "dropOffDateTime") {
      if (status == "DSC") {
        setSortCategory("ASC");
      } else {
        setSortCategory("DSC");
      }
      setSortName(sortName);
      setSortId(sortId);
    } else {
      if (status == "DSC") {
        setSortId("ASC");
      } else {
        setSortId("DSC");
      }
      setSortName(sortName);
      setSortCategory(sortCategory);
    }
  };

  const handleScroll = (e, status) => {
    const target = e.target;
    if (status == "year") {
      if (
        parseInt(target.scrollHeight - target.scrollTop) ==
          parseInt(target.clientHeight) &&
        yearServicePageData.totalpages > scrollPage
      ) {
        setScrollPage(scrollPage + 1);
        vehicleYearData(scrollPage + 1, limit);
      }
    } else if (status == "make") {
      if (
        parseInt(target.scrollHeight - target.scrollTop) ==
          parseInt(target.clientHeight) &&
        makeServicePageData.totalpages > scrollPage
      ) {
        setScrollPage(scrollPage + 1);
        vehicleMakeData(scrollPage + 1, limit);
      }
    } else {
      if (
        parseInt(target.scrollHeight - target.scrollTop) ==
          parseInt(target.clientHeight) &&
        modelServicePageData.totalpages > scrollPage
      ) {
        setScrollPage(scrollPage + 1);
        vehicleModelData(scrollPage + 1, limit);
      }
    }
  };
  return (
    <Box>
      {" "}
      <Grid container display={"flex"}>
        <Grid
          container
          marginBottom={"32px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Grid item xs={12} sm={4} md={3} lg={2.4} xl={2.4}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                    name="proposeDate"
                                    value={selectDate}
                                    onChange={(data) => {
                                        const formattedDate = new Date(data);
                                        setSelectDate(formattedDate);
                                        handleDateChange(formattedDate);
                                    }}
                                    style={{ width: "100%" }}
                                />
                            </DemoContainer>
                        </LocalizationProvider> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {" "}
              <DatePicker
                className="DropDatePicker"
                label="Select Drop Off Date"
                name="proposeDate"
                value={selectDate}
                onChange={(data) => {
                  // const formattedDate = new Date(data);
                  setSelectDate(data);
                  handleDateChange(data);
                  setCalender(false);
                }}
                style={{ width: "100%", marginRight: "10px" }}
                open={calender}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ExpandMore
                            onClick={() => {
                              setCalender(!calender);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    },
                  },
                  // field: { clearable: true },
                }}
              />
            </LocalizationProvider>
          </Grid>
          {/* <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Typography
              variant="body1"
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
              }}
              // padding={"5px"}
            >
              Select Year
            </Typography>
            <Select
              value={yearData}
              onChange={handleYearChange}
              displayEmpty
              style={{ width: "100%" }}
              MenuProps={{
                style: { maxHeight: "300px" },
                PaperProps: {
                  onScroll: (event) => {
                    handleScroll(event, "year");
                  },
                },
              }}
            >
              {yearList &&
                yearList?.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              {showMenuLoader && (
                <MenuItem>
                  <CircularProgress />{" "}
                </MenuItem>
              )}
            </Select>
          </Grid> */}
          <Grid item xs={12} sm={4} md={3} lg={2.4} xl={2.4}>
            <Typography
              variant="body1"
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
              }}
              // padding={"5px"}
            >
              Appointment Type
            </Typography>
            <Select
              value={statusData}
              onChange={handleStatusChange}
              displayEmpty
              style={{ width: "100%" }}
            >
              <MenuItem value={"waiting_for_drop_off"}>
                Waiting for Drop Off
              </MenuItem>

              <MenuItem value={"customer_paid"}>Customer Paid</MenuItem>
              <MenuItem value={"mark_as_no_show"}>No Show</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2.4} xl={2.4}>
            <Typography
              variant="body1"
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
              }}
            >
              Select Make
            </Typography>
            <Select
              value={makeData}
              onChange={handleMakeChange}
              displayEmpty
              style={{ width: "100%" }}
              MenuProps={{
                style: { maxHeight: "300px" },
                PaperProps: {
                  onScroll: (event) => {
                    handleScroll(event, "make");
                  },
                },
              }}
            >
              {makeList &&
                makeList?.map((item) => {
                  return <MenuItem value={item?._id}>{item?.name}</MenuItem>;
                })}
              {showMenuLoader && (
                <MenuItem>
                  <CircularProgress />{" "}
                </MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2.4} xl={2.4}>
            <Typography
              variant="body1"
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
              }}
            >
              Select Model
            </Typography>
            <Select
              value={modelData}
              onChange={handleModelChange}
              displayEmpty
              style={{ width: "100%" }}
              MenuProps={{
                style: { maxHeight: "300px" },
                PaperProps: {
                  onScroll: (event) => {
                    handleScroll(event, "model");
                  },
                },
              }}
            >
              {modelList &&
                modelList?.map((item) => {
                  return <MenuItem value={item?._id}>{item?.name}</MenuItem>;
                })}
              {showMenuLoader && (
                <MenuItem>
                  <CircularProgress />{" "}
                </MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={12} sm={8} md={3} lg={2.4} xl={2.4}>
            <Typography
              variant="body1"
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
              }}
            >
              Search
            </Typography>
            <TextField
              id="outlined-adornment-search"
              fullWidth
              placeholder="Name, vehicle, etc..."
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              // label="Search"
              onChange={(e) => handleSearchChange(e.target.value)}
              // style={{ marginTop: "28px" }}
            />
          </Grid>
        </Grid>

        {(statusData || makeData || modelData || yearData || dateData) && (
          <Grid
            container
            item
            padding={"24px 24px 0 16px"}
            columnGap={"8px"}
            alignItems={"center"}
          >
            <Grid item paddingRight={"16px"}>
              <Typography fontWeight={500} variant="h6" fontSize={"20px"}>
                Filters:
              </Typography>
            </Grid>
            {statusData && (
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  // color="success"
                  style={{
                    backgroundColor:
                      statusData == "customer_paid"
                        ? "#00CD44"
                        : statusData == "mark_as_no_show"
                        ? "#F44336"
                        : "#75DBFF",
                    color:
                      statusData == "waiting_for_drop_off" ? "black" : "white",
                  }}
                  endIcon={
                    <Close
                      onClick={() => {
                        setStatusData("");
                        pastAppointmentData(
                          "past",
                          1,
                          10,
                          search,
                          yearData,
                          makeData,
                          "",
                          "DSC",
                          "dropOffDateTime",
                          dateData,
                          ""
                        );
                      }}
                    />
                  }
                >
                  {statusData == "customer_paid" && "Customer Paid"}
                  {statusData == "mark_as_no_show" && "no show"}
                  {statusData == "waiting_for_drop_off" &&
                    "Waiting for drop off"}
                </Button>
              </Grid>
            )}

            {makeData && (
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  endIcon={
                    <Close
                      onClick={() => {
                        setMakeData("");
                        setModelData("");
                        pastAppointmentData(
                          "past",
                          1,
                          10,
                          search,
                          yearData,
                          "",
                          "",
                          "DSC",
                          "dropOffDateTime",
                          dateData,
                          statusData
                        );
                      }}
                    />
                  }
                >
                  {makeName}
                </Button>
              </Grid>
            )}
            {dateData && (
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="warning"
                  endIcon={
                    <Close
                      onClick={() => {
                        setDateData("");
                        setSelectDate(null);
                        pastAppointmentData(
                          "past",
                          1,
                          10,
                          search,
                          yearData,
                          makeData,
                          modelData,
                          "DSC",
                          "dropOffDateTime",
                          "",
                          statusData
                        );
                      }}
                    />
                  }
                >
                  {dateData}
                </Button>
              </Grid>
            )}
            {modelData && (
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="success"
                  endIcon={
                    <Close
                      onClick={() => {
                        setModelData("");
                        pastAppointmentData(
                          "past",
                          1,
                          10,
                          search,
                          yearData,
                          makeData,
                          "",
                          "DSC",
                          "dropOffDateTime",
                          dateData,
                          statusData
                        );
                      }}
                    />
                  }
                >
                  {modelName}
                </Button>
              </Grid>
            )}
            {yearData && (
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  style={{ backgroundColor: "#F48FB1" }}
                  endIcon={
                    <Close
                      onClick={() => {
                        setYearData("");
                        pastAppointmentData(
                          "past",
                          1,
                          10,
                          search,
                          "",
                          makeData,
                          modelData,
                          "DSC",
                          "dropOffDateTime",
                          dateData,
                          statusData
                        );
                      }}
                    />
                  }
                >
                  {yearData}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  setStatusData("");
                  setYearData("");
                  setModelData("");
                  setMakeData("");
                  setDateData("");
                  setSelectDate(null);
                  pastAppointmentData(
                    "past",
                    1,
                    10,
                    search,
                    "",
                    "",
                    "",
                    "DSC",
                    "dropOffDateTime",
                    "",
                    ""
                  );
                }}
              >
                RESET FILTER
              </Button>
            </Grid>
          </Grid>
        )}

        <Grid item container padding={"32px 0px"}>
          {Data?.totalCount > 0 ? (
            <Grid container item boxShadow={2} sx={{ background: "white" }}>
              <Grid item xl={12} md={12} sm={12} xs={12}>
                <TableContainer sx={{ maxHeight: 600 }}>
                  <Table
                    aria-label="caption table"
                    padding="16px"
                    sx={{ width: "100%" }}
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          active={true}
                          direction="desc"
                          sx={{ fontWeight: 500 }}
                        >
                          <TableSortLabel
                            active={true}
                            direction={sortId == "DSC" ? "desc" : "asc"}
                            sx={{ fontWeight: 500 }}
                            onClick={() => {
                              setSortByName("appointmentNumber");
                              handleSortByName(
                                sortId == "DSC" ? "DSC" : "ASC",
                                "appointmentNumber"
                              );
                            }}
                          >
                            Appointment ID
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            onClick={() => {
                              setSortByName("fullName");
                              handleSortByName(
                                sortName == "DSC" ? "DSC" : "ASC",
                                "fullName"
                              );
                            }}
                            active={true}
                            direction={sortName == "DSC" ? "desc" : "asc"}
                            sx={{ fontWeight: 500 }}
                          >
                            Customer Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={true}
                            direction={sortCategory == "DSC" ? "desc" : "asc"}
                            sx={{ fontWeight: 500 }}
                            onClick={() => {
                              setSortByName("dropOffDateTime");
                              handleSortByName(
                                sortCategory == "DSC" ? "DSC" : "ASC",
                                "dropOffDateTime"
                              );
                            }}
                          >
                            Drop Off Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          active={true}
                          direction="desc"
                          sx={{ fontWeight: 500 }}
                        >
                          Completed
                        </TableCell>
                        <TableCell
                          active={true}
                          direction="desc"
                          sx={{ fontWeight: 500 }}
                        >
                          Vehicle
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {Data &&
                        Data?.data?.map((row) => (
                          <>
                            <TableRow
                              key={row.name}
                              onClick={() => {
                                navigate(
                                  `/appointment/past-appointment/${row?._id}`
                                );
                              }}
                            >
                              <TableCell
                                align="left"
                                style={{ cursor: "pointer" }}
                              >
                                {row?.appointmentNumber}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ cursor: "pointer" }}
                              >
                                {row?.customer?.firstName}{" "}
                                {row?.customer?.lastName}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ cursor: "pointer" }}
                              >
                                {row?.dropOffDateTime
                                  ? moment(row?.dropOffDateTime).format(
                                      "MMM D, YYYY"
                                    )
                                  : " - "}{" "}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ cursor: "pointer" }}
                              >
                                {row?.completedAt &&
                                  moment(row?.completedAt).format(
                                    "MMM D, YYYY"
                                  )}
                                {row?.status == "waiting_for_drop_off" && (
                                  <Chip
                                    label="Waiting for Drop Off"
                                    style={{
                                      backgroundColor: "#75DBFF",

                                      color: "#000000",

                                      fontWeight: "500",
                                      // textTransform: "capitalize",
                                    }}
                                  />
                                )}
                                {row?.status == "mark_as_no_show" && (
                                  <Chip
                                    label="No Show"
                                    style={{
                                      backgroundColor: "#F44336",

                                      color: "white",

                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {row?.vehicle?.year +
                                  " " +
                                  row?.vehicle?.vehicleMake?.name +
                                  " " +
                                  row?.vehicle?.vehicleModel?.name +
                                  " " +
                                  (row?.vehicle?.vehicleModelTrim
                                    ? row?.vehicle?.vehicleModelTrim?.name
                                    : "")}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Grid container item alignItems={"center"} justifyContent="end">
                  <Grid item>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={3}
                      count={Data?.totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                        select: {
                          "aria-label": "rows per page",
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <div style={styles.container}>
                <p style={styles.message}>
                  Sorry, we could not find any matches.
                </p>
              </div>
            </>
          )}
        </Grid>
      </Grid>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PastAppointment;
