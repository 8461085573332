import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

// Personal details fetch
export const vehicleDetails = (limits, pages, userId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.vehicle.getVehicleListByUserID}/${userId}`
      );
      if (!response.data.error) {
        dispatch({
          type: API.vehicle.getVehicleListByUserID,
          vehicleData: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const vehicleYear = (pages, limits) => {
  return async () => {
    try {
      const response = await DataService.get(
        `${API.vehicle.getVehicleYearList}?page=${pages}&limit=${limits}`
      );
      if (!response.data.error) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const vehicleMake = (pages, limits) => {
  return async () => {
    try {
      const response = await DataService.get(
        `${API.vehicle.getVehicleMakeList}?page=${pages}&limit=${limits}`
      );
      if (!response.data.error) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const vehicleModel = (pages, limits, id) => {
  return async () => {
    try {
      const response = await DataService.get(
        `${API.vehicle.getVehicleModelList}?page=${pages}&limit=${limits}&vehicleMakeId=${id}`
      );
      if (!response.data.error) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const fetchProvinces = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(`${API.vehicle.getProvinces}`);
      if (!response.data.error) {
        dispatch({
          type: API.vehicle.getProvinces,
          provinces: response.data.data,
          loading: false,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
