const validateNumberPositive = (value, msg) => {
  const isPositiveNumber =
    parseFloat(value) > 0 && /^\d+(,\d+)?(\.\d+)?$/.test(value);
  return isPositiveNumber || msg || "Please enter a positive number";
};

const validateNumberWithZeroPositive = (value) => {
  const isPositiveNumber =
    parseFloat(value) >= 0 && /^\d+(,\d+)?(\.\d+)?$/.test(value);
  return isPositiveNumber || "Please enter a zero or positive number";
};
export { validateNumberPositive, validateNumberWithZeroPositive };
