import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  vehicleMake,
  vehicleModel,
  vehicleYear,
} from "../../redux/Vehicles/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  pt: 2,
  px: 3,
  pb: 1,
};

const SelectVehicleModal = ({
  open,
  handleClose,
  setYearData,
  setMakeData,
  setModelData,
  yearData,
  makeData,
  modelData,
  cancel,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [yearList, setYearList] = React.useState("");
  const [makeList, setMakeList] = React.useState("");
  const [modelList, setModelList] = React.useState("");
  const [yearServicePageData, setYearServicePageData] = React.useState({
    totalpages: "",
    currentPage: "",
  });
  const [makeServicePageData, setMakeServicePageData] = React.useState({
    totalpages: "",
    currentPage: "",
  });
  const [modelServicePageData, setModelServicePageData] = React.useState({
    totalpages: "",
    currentPage: "",
  });

  const [scrollPage, setScrollPage] = React.useState(1);
  const limit = 1000;

  const dispatch = useDispatch();

  useEffect(() => {
    vehicleYearData(scrollPage, limit);
    vehicleMakeData(scrollPage, limit);
    // vehicleModelData(scrollPage, limit);
  }, []);

  const handleCancel = () => {
    setYearData("");
    setMakeData("");
    setModelData("");
    cancel();
  };

  // const vehicleYearData = async () => {
  //     let data = await dispatch(vehicleYear());
  //     if (data) {
  //         setYearList(data.data);
  //     }
  // };
  // const vehicleMakeData = async () => {
  //     let data = await dispatch(vehicleMake());
  //     if (data) {
  //         setMakeList(data.data);
  //     }
  // };
  // const vehicleModelData = async () => {
  //     let data = await dispatch(vehicleModel());
  //     if (data) {
  //         setModelList(data.data);
  //     }
  // };

  const vehicleYearData = async (page, limit) => {
    let data = await dispatch(vehicleYear(page, limit));
    if (data) {
      setYearList([...yearList, ...data.data]);
      setYearServicePageData({
        totalpages: data?.totalPages,
        currentPage: data?.currentPage,
      });
    }
  };
  const vehicleMakeData = async (page, limit) => {
    let data = await dispatch(vehicleMake(page, limit));
    if (data) {
      setMakeList([...makeList, ...data.data]);
      setMakeServicePageData({
        totalpages: data?.totalPages,
        currentPage: data?.currentPage,
      });
    }
  };
  const vehicleModelData = async (page, limit, id) => {
    let data = await dispatch(vehicleModel(page, limit, id));
    if (data) {
      setModelList([...modelList, ...data.data]);
      setModelServicePageData({
        totalpages: data?.totalPages,
        currentPage: data?.currentPage,
      });
    }
  };
  const handleYearChange = (event) => {
    setYearData(event.target.value);
  };

  const handleMakeChange = (event) => {
    setModelData("");
    setMakeData(event.target.value);

    vehicleModelData(scrollPage, limit, event.target.value);
  };
  const handleModelChange = (event) => {
    setModelData(event.target.value);
  };
  const onSubmit = async () => {
    handleClose();
  };

  const handleScroll = (e, status) => {
    const target = e.target;
    if (status == "year") {
      if (
        parseInt(target.scrollHeight - target.scrollTop) ==
          parseInt(target.clientHeight) &&
        yearServicePageData.totalpages > scrollPage
      ) {
        setScrollPage(scrollPage + 1);
        vehicleYearData(scrollPage + 1, limit);
      }
    } else if (status == "make") {
      if (
        parseInt(target.scrollHeight - target.scrollTop) ==
          parseInt(target.clientHeight) &&
        makeServicePageData.totalpages > scrollPage
      ) {
        setScrollPage(scrollPage + 1);
        vehicleMakeData(scrollPage + 1, limit);
      }
    } else {
      if (
        parseInt(target.scrollHeight - target.scrollTop) ==
          parseInt(target.clientHeight) &&
        modelServicePageData.totalpages > scrollPage
      ) {
        setScrollPage(scrollPage + 1);
        vehicleModelData(scrollPage + 1, limit);
      }
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            height: "fit-content",
            width: "450px",
            border: "none",
            borderRadius: "4px",
          }}
        >
          <AppBar
            variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
              backgroundColor: "white",
              color: "black",
              boxShadow: "none",
              borderRadius: "4px",
            }}
          >
            <Toolbar variant="regular">
              <Typography
                variant="h6"
                fontSize={"20px"}
                fontWeight={500}
                width={"98%"}
                color={"black"}
              >
                Filter Vehicle
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div onClick={handleClose} style={{ cursor: "pointer" ,height:'32px' }}>
                  <CloseIcon style={{ color: "#0000008F" , fontSize: 32}} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container disableGutters style={{ marginTop: "46px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} xl={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Select Year
                  </Typography>
                  <Select
                    value={yearData}
                    onChange={handleYearChange}
                    displayEmpty
                    style={{ width: "100%" }}
                    MenuProps={{
                      style: { maxHeight: "300px" },
                      PaperProps: {
                        onScroll: (event) => {
                          handleScroll(event, "year");
                        },
                      },
                    }}
                  >
                    {yearList &&
                      yearList?.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                  </Select>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Select Make
                  </Typography>
                  <Select
                    value={makeData}
                    onChange={handleMakeChange}
                    displayEmpty
                    style={{ width: "100%" }}
                    MenuProps={{
                      style: { maxHeight: "300px" },
                      PaperProps: {
                        onScroll: (event) => {
                          handleScroll(event, "make");
                        },
                      },
                    }}
                  >
                    {makeList &&
                      makeList?.map((item) => {
                        return (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Select Model
                  </Typography>
                  <Select
                    value={modelData}
                    onChange={handleModelChange}
                    displayEmpty
                    style={{ width: "100%" }}
                    MenuProps={{
                      style: { maxHeight: "300px" },
                      PaperProps: {
                        onScroll: (event) => {
                          handleScroll(event, "model");
                        },
                      },
                    }}
                  >
                    {modelList &&
                      modelList?.map((item) => {
                        return (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={"end"}
                  spacing={2}
                  style={{ marginTop: "0px" }}
                >
                  <Grid item>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                      >
                        RESET
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        Done
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default SelectVehicleModal;
