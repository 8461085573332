import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
  loading: false,
  estimateData: {},
  estimateDetailData: {},
  serviceData: [],
  servicePartsNewData: {},
  serviceSubData: {},
};

//DEFINE ACCOUNT REDUCER FUNCTION
const EstimateReducer = (state = initState, action) => {
  switch (action.type) {
    case "servicePartsNewDatas":
      return {
        ...state,
        servicePartsNewData: action.servicePartsNewData,
      };
    case API.estimate.estimateList:
      return {
        ...state,
        estimateData: action.estimateData,
        loading: action.loading,
      };

    case API.estimate.estimateDetail:
      return {
        ...state,
        estimateDetailData: action.estimateDetailData,
        loading: action.loading,
      };
    case API.estimate.servicesCategory:
      return {
        ...state,
        serviceData: action.serviceData,
        loading: action.loading,
      };
    case API.estimate.servicesSubCategory:
      return {
        ...state,
        serviceSubData: action.serviceSubData,
        loading: action.loading,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default EstimateReducer;
