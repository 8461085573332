import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

//Get Billing History
export const billingList =
  ({ page, limit }) =>
  async (dispatch) => {
    try {
      const response = await DataService.get(
        API.account.billing.getBillingHistory + `?page=${page}&limit=${limit}`
      );
      if (response.data.status) {
        dispatch({
          type: API.account.billing.getBillingHistory,
          billingData: response.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

//Get Current Plan
export const currentPlan = () => async (dispatch) => {
  try {
    const id = JSON.parse(localStorage.getItem("apex-saas_details"))._id;
    const response = await DataService.get(
      API.account.billing.getCurrentPlan + id
    );
    if (response.data.status) {
      dispatch({
        type: API.account.billing.getCurrentPlan,
        currentPlan: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

//Get Plans History
export const plansList =
  ({ interval }) =>
  async (dispatch) => {
    try {
      const response = await DataService.get(
        API.account.billing.getPlans + "?interval=" + interval
      );
      if (response.data.status) {
        dispatch({
          type: API.account.billing.getPlans,
          plansList: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const updatePlan =
  ({ oldId, newId }) =>
  async (dispatch) => {
    try {
      const response = await DataService.put(
        API.account.billing.upgradePlan + oldId,
        { priceId: newId }
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
export const addPaymentMethod = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await DataService.post(API.account.billing.addCard, payload);
      if (resp.data.status) {
        return resp.data;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const updateCard = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await DataService.put(
        API.account.billing.updatecard,
        payload
      );
      if (resp.data.status) {
        return resp.data.status;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const updateManualPlan = (payload) => async (dispatch) => {
  console.log("🚀 ~ updateManualPlan ~ payload:", payload);

  try {
    const response = await DataService.put(
      API.account.billing.upgradeManualPlan,
      payload
    );
    if (response.data.status) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
