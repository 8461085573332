import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Grid, Link, Modal, Toolbar, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pl: 3,
  pr: 0,
  pb: 3,
};

const CancelSubscriptionModal = ({
  openCancelSubscriptionModal,
  setOpenCancelSubscriptionModal,
}) => {
  // console.log(
  //   "🚀 ~ file: ServiceModal.jsx:30 ~ ServiceModal ~ getData:",
  //   getData
  // );

  const handleCloseCancelSubscriptionModal = () => {
    setOpenCancelSubscriptionModal(false);
  };

  return (
    <Box>
      <Modal
        open={openCancelSubscriptionModal}
        onClose={handleCloseCancelSubscriptionModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "600px",
            border: "none",
            borderRadius: "8px 8px 0px 0px",
            outline: "none",
          }}
        >
          <AppBar
            color="secondary"
            // variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
              borderRadius: "8px 8px 0px 0px",
              padding: 0,
              margin: 0,
              paddingLeft: "-16px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                Cancel Subscription?
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    handleCloseCancelSubscriptionModal();
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              scrollbarColor: "blue",
              scrollbarWidth: "2px",

              marginTop: "72px",
              // paddingRight: "14px",
            }}
          >
            <Grid
              container
              spacing={2}
              maxHeight={"85vh"}
              style={{ scrollbarColor: "blue", scrollbarWidth: "thin" }}
            >
              <Grid item lg={12}>
                <Typography variant="body1" mb={2}>
                  Please give us a call at 437-961-8836 to cancel your
                  subscription.
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} mb={2}>
                  Call Us: 437-961-8836
                </Typography>
                <Typography variant="subtitle1" fontWeight={500}>
                  Email:{" "}
                  <Link style={{ textDecoration: "none" }}>
                    {" "}
                    support@apexmechanic.com
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>
    </Box>
  );
};

export default CancelSubscriptionModal;
