import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { acceptDeclineRequest } from "../../redux/Dashboard/action";
import "../Estimates/Estimates.css";
import "./modal.css";
import { toast } from "sonner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 491,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // pt: 2,
  // px: 4,
  // pb: 3,
};

const EstimateRequestModal = ({
  open,
  handleClose = () => {},
  estimateData,
  handleCancel,
}) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const requestacceptDecline = async (id, status) => {
    setShowLoader(true);
    const payload = {
      status: status,
    };
    const data = await dispatch(acceptDeclineRequest(id, payload));
    // window.location.reload();
    if (data) {
      toast.success(
        `Estimate request was successfully ${
          status == "accept" ? "accepted" : "declined"
        }.`
      );
    }
    setShowLoader(false);
    handleClose();
  };

  const handleDecline = async () => {
    requestacceptDecline(estimateData?._id, "decline");
  };
  const handleAccept = async () => {
    requestacceptDecline(estimateData?._id, "accept");
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} md={9} xl={9}>
          <Modal
            open={open}
            onClose={handleCancel}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                ...style,
                height: "fit-content",
                maxHeight: "90%",
                width: "900px",
                border: "none",
              }}
            >
              <AppBar
                variant="elevation"
                className="modalHeader"
                sx={{
                  height: "64px",
                  paddingRight: "0px !important",
                }}
              >
                <Toolbar variant="regular">
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    width={"98%"}
                    color={"black"}
                  >
                    Estimate Request
                  </Typography>
                  <Stack direction={"row"} spacing={0}>
                    <div onClick={handleCancel} className="appointmentModal">
                      <CloseIcon />
                    </div>
                  </Stack>
                </Toolbar>
              </AppBar>
              <Container
                gap={2}
                disableGutters
                style={{ marginTop: "45px" }}
                sx={{
                  overflowY: "auto",
                  maxHeight: "80vh",
                  scrollbarWidth: "thin",
                }}
              >
                <Grid
                  container
                  padding={"10px 0px"}
                  style={{ backgroundColor: "#F5F5F7" }}
                >
                  <Grid
                    container
                    item
                    xs={5.5}
                    md={5.5}
                    xl={5.5}
                    className="appointmentPart"
                    style={{ borderRight: "1px solid #C4C4C4" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      xl={12}
                      style={{
                        paddingLeft: "20px",
                        paddingTop: "15px",
                        width: "880px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                      >
                        Customer
                      </Typography>

                      <Typography
                        item
                        md={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"370px"}
                        fontWeight={400}
                      >
                        {/* {estimateData && estimateData?.customer?.firstName + " " + estimateData?.customer?.lastName} */}
                        <Link
                          to={`/customers/${estimateData?.customer?._id}/${estimateData?.customer?.createdFrom}`}
                          style={{ color: "#2196F3", textDecoration: "none" }}
                        >
                          {estimateData &&
                            estimateData?.customer?.firstName +
                              " " +
                              estimateData?.customer?.lastName}
                        </Link>
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                        marginTop={"20px"}
                      >
                        Estimate Information
                      </Typography>
                      <Grid
                        item
                        md={12}
                        lg={12}
                        xl={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"370px"}
                        fontWeight={400}
                      >
                        <Grid container item>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Estimate Fee
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {estimateData &&
                                (estimateData?.shopEstimateFees
                                  ? "$" + estimateData?.shopEstimateFees
                                  : " $ 0")}
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Date of Request
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {estimateData?.estimateRequestDate
                                ? moment(
                                    estimateData?.estimateRequestDate
                                  ).format("MMM D, YYYY")
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                        marginTop={"20px"}
                      >
                        Contact Preferences
                      </Typography>
                      <Typography
                        item
                        md={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        fontWeight={400}
                        marginTop={"7px"}
                        width={"370px"}
                      >
                        {estimateData?.preferancesContactBy &&
                        estimateData?.preferancesContactBy == "phone"
                          ? "Phone Call"
                          : estimateData?.preferancesContactBy == "email"
                          ? "Email"
                          : "In-App Message"}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                        marginTop={"20px"}
                      >
                        Note from {estimateData?.customer?.firstName}
                      </Typography>
                      <Typography
                        item
                        md={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"370px"}
                        minHeight={"42px"}
                        fontWeight={400}
                        marginBottom={"20px"}
                        sx={{
                          overflowY: "scroll",
                          scrollbarWidth: "thin",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {estimateData?.noteForMechanic
                          ? estimateData?.noteForMechanic
                          : " "}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <Divider orientation="vertical" flexItem /> */}
                  <Grid container item xs={6} md={6} xl={6}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      xl={12}
                      style={{ paddingLeft: "20px", paddingTop: "15px" }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                      >
                        Vehicle Information
                      </Typography>
                      <Grid
                        item
                        md={12}
                        lg={12}
                        xl={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        fontWeight={400}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"420px"}
                        bgcolor={"white"}
                      >
                        <Grid container item>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Vehicle
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {estimateData?.vehicle?.year
                                ? estimateData?.vehicle?.year
                                : ""}
                              {estimateData?.vehicle?.vehicleMake?.name
                                ? " " + estimateData?.vehicle?.vehicleMake?.name
                                : ""}
                              {estimateData?.vehicle?.vehicleModel?.name
                                ? " " +
                                  estimateData?.vehicle?.vehicleModel?.name
                                : ""}
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Licence Plate
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {estimateData?.vehicle?.licenseNumber
                                ? estimateData?.vehicle?.licenseNumber
                                : "-"}
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Trim
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {estimateData?.vehicle?.vehicleModelTrim?.name
                                ? estimateData?.vehicle?.vehicleModelTrim?.name
                                : "-"}
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              VIN
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {estimateData?.vehicle?.vin}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      xl={12}
                      style={{ paddingLeft: "20px", paddingTop: "15px" }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                      >
                        Services
                      </Typography>
                      <Grid
                        item
                        md={12}
                        lg={12}
                        xl={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        fontWeight={400}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"420px"}
                        bgcolor={"white"}
                        maxHeight={"40vh"}
                        overflow={"auto"}
                        sx={{ scrollbarWidth: "thin" }}
                      >
                        <Grid container item>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Total Services
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {estimateData?.services?.length}
                            </Typography>
                          </Grid>
                          {estimateData &&
                            estimateData?.services?.map((item) => {
                              return (
                                <>
                                  <Divider
                                    style={{
                                      padding: "5px ",
                                      width: "100%",
                                      borderBottomStyle: "solid",
                                      borderBottomWidth: "2px",
                                      marginBottom: "12px",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    fontWeight={400}
                                    fontSize={"16px"}
                                  >
                                    {item?.name}
                                  </Typography>
                                </>
                              );
                            })}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent={"end"}
                      spacing={2}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <Grid item>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="contained"
                            color="error"
                            onClick={handleDecline}
                          >
                            DECLINE
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAccept}
                          >
                            ACCEPT
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Modal>
        </Grid>
      </Grid>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EstimateRequestModal;
