import React, { useEffect } from "react";
import clsx from "clsx";
import { Autorenew } from "@mui/icons-material";
import "./Refresh.css"; // Import the external CSS file

function Refresh({ getNotificationPopup, loading }) {
  const [spin, setSpin] = React.useState(false);

  const refreshCanvas = () => {
    getNotificationPopup();
    // setSpin(loading);
  };
  useEffect(() => {
    setSpin(loading);
  }, [loading]);

  return (
    <Autorenew
      className={clsx("refresh", {
        spin: spin,
      })}
      onClick={refreshCanvas}
    />
  );
}

export default Refresh;
