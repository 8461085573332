import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { formatPostalCode } from "../FormatNumber/FormatNumber";

function PostalCode({
  name,
  control,
  errors,
  setValueinForm,
  setAddressValue,
  addressValues,
  setError = () => {},
  clearErrors = () => {},
  spacing = 1,
}) {
  console.log("errros", errors);
  return (
    <Stack spacing={spacing}>
      <Typography
        variant="body1"
        style={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "24px",
          letterSpacing: "0.15px",
        }}
      >
        Postal Code
        {(addressValues.streetAddress ||
          addressValues.province ||
          addressValues.city ||
          addressValues.postal) && <span style={{ color: "#D32F2F" }}> *</span>}
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            error={!!errors.postalCode}
            helperText={errors.postalCode ? errors.postalCode.message : ""}
            {...field}
            inputProps={{
              style: { textTransform: "uppercase" },
              maxlength: 7,
              minLength: 7,
            }}
            onChange={(e) => {
              if (formatPostalCode(e.target.value).length != 7) {
                setError(name, { message: "Postal code is required" });
              } else {
                clearErrors(name);
              }
              setValueinForm("postalCode", formatPostalCode(e.target.value));
              setAddressValue({
                ...addressValues,
                postal: formatPostalCode(e.target.value),
              });
            }}
          />
        )}
      />
    </Stack>
  );
}

export default PostalCode;
