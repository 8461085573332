import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

// Personal details fetch
export const dasboardDetails = () => async (dispatch) => {
  try {
    const response = await DataService.get(API.dashboard.dashboardData);
    if (!response.data.error) {
      dispatch({
        type: API.dashboard.dashboardData,
        dashboardData: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const requestEstimateList = (limits, pages) => async (dispatch) => {
  try {
    const response = await DataService.get(
      `${API.dashboard.requestEstimateListing}?limit=${limits}&page=${pages}`
    );
    if (!response.data.error) {
      dispatch({
        type: API.dashboard.requestEstimateListing,
        requestEstimateData: response.data.data,
        loading: false,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const requestEstimateDetails = (id) => async () => {
  try {
    const response = await DataService.get(
      `${API.dashboard.requestEstimateDetail}/${id}`
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const acceptDeclineRequest = (id, payload) => async () => {
  try {
    const response = await DataService.post(
      `${API.dashboard.acceptDeclineRequest}/${id}`,
      payload
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const requestAppointmentList = (limits, pages) => async (dispatch) => {
  try {
    const response = await DataService.get(
      `${API.dashboard.requestAppointmentListing}?limit=${limits}&page=${pages}`
    );
    if (!response.data.error) {
      dispatch({
        type: API.dashboard.requestAppointmentListing,
        requestAppointmentData: response.data.data,
        loading: false,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const requestAppointmentDetails = (id) => async () => {
  try {
    const response = await DataService.get(
      `${API.dashboard.requestApponitmentDetail}/${id}`
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const acceptDeclineAppointment = (id, payload) => async () => {
  try {
    const response = await DataService.put(
      `${API.dashboard.acceptDeclineAppointmentRequest}/${id}`,
      payload
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const updatePickupDateAndTime = (id, payload) => async () => {
  try {
    const response = await DataService.put(
      `${API.dashboard.updateEstimatePickUp}/${id}`,
      payload
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const changeAssignee = (payload) => async () => {
  try {
    const response = await DataService.put(
      `${API.dashboard.changeAssignee}${payload.id}`,
      { assigneeIds: payload.assigneeIds }
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};
