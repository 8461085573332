import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

export const createNewInvoice = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(
      API.feedbackSubmit.submitFeedback,
      payload
    );
    if (response.data.status) {
      // dispatch({
      //     type: API.feedbackSubmit.submitFeedback,
      //     shopInfoData: response.data.data,
      // });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
