import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

// shop details fetch
export const shopDetails = () => async (dispatch) => {
  try {
    const response = await DataService.get(
      API.account.shopDetails.getshopDetails
    );
    if (!response.data.error) {
      dispatch({
        type: API.account.shopDetails.getshopDetails,
        shopDetailsData: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

// Update personal details
export const updateShopDetails = (payload) => async () => {
  try {
    const response = await DataService.put(
      API.account.shopDetails.shopBasicsUpdate,
      payload
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

// Update shop price details
export const updateShopPriceDetails = (payload) => async () => {
  try {
    const response = await DataService.put(
      API.account.shopDetails.updateShopPrice,
      payload
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

// get shop price details
export const getShopPriceDetails = () => async (dispatch) => {
  try {
    const response = await DataService.get(
      API.account.shopDetails.getUpdateShopPrice
    );
    console.log("response", response);
    if (!response.data.error) {
      dispatch({
        type: API.account.shopDetails.getUpdateShopPrice,
        shopPrice: response.data.data.defaultHourlyRate,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

// Update shop certificates details
export const updateShopCertificates = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.account.shopDetails.updateShopCertificates,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// add cancellation policy
export const addCancellationPolicy = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.account.cancellationPolicy.addPolicy,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// update cancellation policy
export const updateCancellationPolicy = (payload, policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.account.cancellationPolicy.updatePolicy}/${policyId}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// delete cancellation policy
export const deleteCancellationPolicy = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        `${API.account.cancellationPolicy.deletePolicy}/${policyId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

//estimates fee
export const updateEstimatesFee = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.account.Estimates.estimateFee,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// add TermsAndWarrenty
export const addTermsAndWarrenty = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.account.termsAndWarrenty.addTermsAndWarrenty,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// update terms and policy
export const updateTermsAndWarrenty = (payload, policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.account.termsAndWarrenty.updateTermsAndWarrenty}/${policyId}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// delete terms and policy
export const deleteTermsAndWarrenty = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        `${API.account.termsAndWarrenty.deleteTermsAndWarrenty}/${policyId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getTermsAndWarrenty = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.account.termsAndWarrenty.getTermsAndWarrenty
      );
      if (!response.data.error) {
        dispatch({
          type: API.account.termsAndWarrenty.getTermsAndWarrenty,
          getTermsAndWarrenty: response.data.data,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getCancellationPolicy = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.account.cancellationPolicy.getpolicy
      );
      if (!response.data.error) {
        dispatch({
          type: API.account.cancellationPolicy.getpolicy,
          getCancellationPolicy: response.data.data,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getAminitiy = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.account.Amenities.getAmenities
      );
      if (!response.data.error) {
        dispatch({
          type: API.account.Amenities.getAmenities,
          getAmenities: response.data.data,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// update aminities
export const updateAminities = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.account.Amenities.putAmenities,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// crud of rescheduling policy

// add rescheduling policy

export const addReschedulingPolicy = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.account.reschedulingPolicy.add,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

//get by id rescheduling  policy

export const getReschedulingPolicy = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.account.reschedulingPolicy.getById}/${policyId}`
      );
      if (!response.data.error) {
        dispatch({
          type: API.account.reschedulingPolicy.getById,
          getReschedulingPolicyData: response.data.data,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// update the rescheduling policy

export const updateReschedulingPolicy = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.account.reschedulingPolicy.update}/${id}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// get list of rescheduling policy

export const getReschedulingPolicyList = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.account.reschedulingPolicy.getList
      );
      if (!response.data.error) {
        dispatch({
          type: API.account.reschedulingPolicy.getList,
          getReschedulingPolicyListData: response.data.data,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

// delete the rescheduling policy

export const deleteReschedulingPolicy = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        `${API.account.reschedulingPolicy.delete}/${policyId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getShopImagesList = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.account.shopImages.get);
      if (!response.data.error) {
        dispatch({
          type: API.account.shopImages.get,
          getShopImagesList: response.data.data,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
// Update shop images details
export const updateShopImages = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.patch(
        API.account.shopImages.update,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
