import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./CurrentApproval.css";

import { useTheme } from "@emotion/react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormatNumber from "../../../components/FormatNumber/FormatNumber";
import { getCurrentApprovals } from "../../../redux/Approvals/action";
import { appointmentDetails } from "../../../redux/appointment/action";
import AppointmentModel from "../../Appointment/AppointmentModel";
import ImageShowModal from "../../modal/ImageShowModal";
import { customisePartName } from "../../../utility/helpers/displayHelper";
import AddOdoModal from "../../modal/AddOdoModal";
import { getFileType } from "../../../utility/getFileType";
import PDFViewModel from "../../modal/PDFViewModal";

const CurrentApproval = () => {
  const ApprovalsListing = useSelector(
    (state) => state?.approvals?.currentApproval
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [imageOpenModalShows, setImageOpenModalShows] = useState(false);
  const [imageView, setImageView] = useState("");
  const [pdfOpenModalShows, setPDFOpenModalShows] = useState(false);
  const [pdfView, setPDFView] = useState("");
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState("");
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [appointmentDropOffODO, setAppointmentDropOffODo] = useState(null);
  const [appointmentCompletionOffODO, setAppointmentCompletionOffODo] =
    useState(null);
  const [openODOModel, setOpenODOModel] = useState(false);
  let total = 0;
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const { id } = useParams();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  useEffect(() => {
    getCurrentApprovalsData(id);
  }, []);

  const getCurrentApprovalsData = async (id) => {
    setLoader(true);
    let res = await dispatch(getCurrentApprovals(id));
    setLoader(false);
  };

  const details = async () => {
    setLoading(true);
    if (id) {
      const detailView = await dispatch(appointmentDetails(id));
      if (detailView) {
        setData(detailView);
        if (detailView?.status == "customer_paid") {
          return navigate(`/appointment/past-appointment/${id}`);
        }
        setStatus(detailView?.status);
        setOpen(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  if (loader) {
    return (
      <Dialog fullScreen={fullScreen} open={loader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  const handleOpen = (detailsData) => {
    setStatus(detailsData?.status);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleStatus = () => setOpenODOModel(true);
  const handleStatusClose = () => {
    setShow(false);
  };

  return (
    <Box padding={"32px 24px"}>
      <ImageShowModal
        setImageOpenModalShows={setImageOpenModalShows}
        imageOpenModalShows={imageOpenModalShows}
        setImageView={setImageView}
        imageView={imageView}
      />
      <PDFViewModel
        setPDFOpenModalShows={setPDFOpenModalShows}
        pdfOpenModalShows={pdfOpenModalShows}
        setPDFView={setPDFView}
        pdfView={pdfView}
      />
      <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/approvals/waiting"
                style={{
                  color: "black",
                  opacity: "0.6",
                  textDecoration: "none",
                }}
              >
                Approvals
              </Link>
              <Link style={{ color: "black" }}>Current Approval</Link>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item lg={12}>
          <Grid container item justifyContent={"space-between"}>
            <Grid item lg={9}>
              <Typography variant="h4" sx={{ fontWeight: "500" }}>
                Current Approval
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{ color: "#002394", border: "1px solid #00239480" }}
                onClick={() => {
                  details();
                }}
                className="btn-text-size-manage"
                disabled={loading}
              >
                {loading && (
                  <div className="btn-loader">
                    <CircularProgress />
                  </div>
                )}{" "}
                VIEW APPOINTMENT DETAILS
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container padding={"24px"} boxShadow={2} margin={"32px 0"}>
        <Grid
          container
          item
          justifyItems={"space-between"}
          spacing={{ lg: 0, md: 0, sm: 2, xs: 2 }}
        >
          <Grid item>
            <Typography variant="h5" fontWeight={400}>
              Services Added
            </Typography>
          </Grid>

          {ApprovalsListing?.services?.map((data, index) => {
            return (
              <>
                {data?.pricingType == "fixed" ? (
                  <>
                    <Grid
                      container
                      item
                      style={{
                        backgroundColor: "#002394",
                        color: "#FFFFFF",
                        marginTop: "20px",
                      }}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight={"500"}
                          padding={"16px"}
                        >
                          {data?.serviceName}
                        </Typography>
                        <Chip
                          style={{
                            background:
                              data.approvalStatus == "waiting"
                                ? "#FFB400"
                                : data.approvalStatus == "approved"
                                ? "#30D158"
                                : data.approvalStatus == "declined"
                                ? "#FF2727"
                                : data.approvalStatus == "deferred"
                                ? "#000000"
                                : "",
                            marginRight: "10px",
                            textTransform: "capitalize",
                          }}
                          className="chip-text-style"
                          label={data.approvalStatus}
                          // onClick={handleCl.ick}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                        <Table
                          aria-label="caption table"
                          padding="16px"
                          style={{ boxShadow: 0 }}
                        >
                          <TableHead
                            style={{
                              backgroundColor: "#f4f6f8",
                              fontWeight: 500,
                            }}
                          >
                            <TableRow>
                              <TableCell width={"120px"}>
                                <Typography
                                  variant="table/header"
                                  fontWeight={500}
                                >
                                  {" "}
                                  No.
                                </Typography>
                              </TableCell>
                              <TableCell> Description</TableCell>
                              <TableCell width={"120px"}> Quantity</TableCell>
                              <TableCell width={"120px"}> Cost</TableCell>
                              <TableCell width={"120px"} align="center">
                                {" "}
                                Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                1
                              </TableCell>

                              <TableCell align="left">
                                {data?.serviceName}
                              </TableCell>

                              <TableCell align="left">1</TableCell>
                              <TableCell align="left">
                                <FormatNumber
                                  inputValue={(data?.fixedPrice).toString()}
                                />
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ paddingRight: "23px" }}
                              >
                                {/* <FormatNumber inputValue={data?.fixedPrice} /> */}
                                <FormatNumber
                                  inputValue={(data?.fixedPrice).toString()}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      item
                      style={{
                        backgroundColor: "#002394",
                        color: "#FFFFFF",
                        marginTop: "20px",
                      }}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight={"500"}
                          padding={"16px"}
                        >
                          {data?.serviceName}
                        </Typography>
                        <Chip
                          style={{
                            background:
                              data.approvalStatus == "waiting"
                                ? "#FFB400"
                                : data.approvalStatus == "approved"
                                ? "#30D158"
                                : data.approvalStatus == "declined"
                                ? "#FF2727"
                                : data.approvalStatus == "deferred"
                                ? "#000000"
                                : "",
                            marginRight: "10px",
                            textTransform: "capitalize",
                          }}
                          className="chip-text-style"
                          label={data.approvalStatus}
                          // onClick={handleCl.ick}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                        <Table
                          aria-label="caption table"
                          padding="16px"
                          style={{ boxShadow: 0 }}
                        >
                          <TableHead
                            style={{
                              backgroundColor: "#f4f6f8",
                              fontWeight: 500,
                            }}
                          >
                            <TableRow>
                              <TableCell width={"120px"}>
                                <Typography
                                  variant="table/header"
                                  fontWeight={500}
                                >
                                  {" "}
                                  No.
                                </Typography>
                              </TableCell>
                              <TableCell> Description</TableCell>
                              <TableCell width={"120px"}> Quantity</TableCell>
                              <TableCell width={"120px"}> Cost</TableCell>
                              <TableCell width={"120px"} align="center">
                                {" "}
                                Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key={0}>
                              <TableCell component="th" scope="row">
                                {1}
                              </TableCell>
                              <TableCell align="left">Labor</TableCell>
                              <TableCell align="left">
                                {data?.labor?.qty}
                              </TableCell>
                              <TableCell align="left">
                                <FormatNumber
                                  inputValue={(data?.labor?.ratePerHour).toString()}
                                />
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ paddingRight: "23px" }}
                              >
                                <FormatNumber
                                  inputValue={(data?.labor?.totalAmount).toString()}
                                />
                              </TableCell>
                            </TableRow>

                            {data?.parts?.map((row, idx) => (
                              <TableRow key={idx}>
                                <TableCell component="th" scope="row">
                                  {idx + 2}
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="body2" fontWeight={400}>
                                    {row?.partName}
                                  </Typography>

                                  <Typography fontWeight={400} fontSize={12}>
                                    Part Provider:{" "}
                                    {customisePartName(row?.partsProvider)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    fontWeight={400}
                                    fontSize={12}
                                  >
                                    Part Number: {row?.partNumber}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    fontWeight={400}
                                    fontSize={12}
                                  >
                                    Description: {row?.description}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{row.qty}</TableCell>
                                <TableCell align="left">
                                  <FormatNumber
                                    inputValue={row.cost.toString()}
                                  />
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ paddingRight: "23px" }}
                                >
                                  <FormatNumber
                                    inputValue={row.totalAmount.toString()}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            );
          })}

          <Grid
            container
            item
            justifyContent={"end"}
            marginTop={"23px"}
            padding={"23px 23px 0 23px"}
            spacing={1}
          >
            <Grid
              item
              container
              justifyContent={"end"}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid item>
                <Typography variant="body1" fontWeight={400}>
                  Total &#160; &#160;
                  {ApprovalsListing?.services?.map((data, index) => {
                    if (data.pricingType == "fixed") {
                      total += +data?.fixedPrice;
                    } else {
                      total += +data?.labor?.totalAmount;
                      data?.parts?.map((row, idx) => {
                        total += row?.totalAmount;
                      });
                    }
                  })}
                  <FormatNumber inputValue={total} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item>
            <Grid item>
              <Typography
                variant="h6"
                fontWeight={"400"}
                fontSize={"24px"}
                padding={"16px"}
                paddingLeft={"0px"}
              >
                Attachments
              </Typography>
            </Grid>
            {ApprovalsListing?.images?.map((data, index) => {
              return (
                <Grid
                  item
                  container
                  justifyContent={"space-between"}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  alignItems={"center"}
                  padding={"24px"}
                  className="img-wraper"
                >
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Stack
                      direction={"row"}
                      alignItems={"start"}
                      flexDirection={"column-reverse"}
                      // spacing={2}
                    >
                      {/* <Stack>
                        <UploadFileIcon color="primary" />
                      </Stack>*/}
                      <Stack>
                        {/* <Typography fontWeight={400}>{data?.path}</Typography> */}
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Typography
                            fontWeight={400}
                            variant="body2"
                            fontSize={"14px"}
                          >
                            <span>{parseInt(data?.size).toFixed(0)} KB </span>
                          </Typography>
                        </Stack>
                      </Stack>

                      {getFileType(data?.path) == "image" ? (
                        <img
                          src={data?.url}
                          alt=".."
                          className="current-app-img"
                          onClick={() => {
                            setImageView(data?.url);
                            setImageOpenModalShows(true);
                          }}
                        />
                      ) : (
                        <div
                          onClick={() => {
                            setPDFView(data?.url);
                            setPDFOpenModalShows(true);
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            cursor: "pointer",
                            columnGap: "22px",
                          }}
                        >
                          View
                          <iframe
                            src={data?.url}
                            width="100%"
                            height="100px"
                            style={{ border: "none" }}
                          />
                        </div>
                      )}
                    </Stack>
                    {/* {file.name} - {uploadProgress[index] ? `${uploadProgress[index].toFixed(2)}%` : 'Uploading...'} */}
                  </Grid>
                  {/* <Grid item>
                <Stack>
                  <Delete
                    style={{ color: "gray", cursor: "pointer" }}
                    //  onClick={() => handleDelete(index)}
                  />
                </Stack>
              </Grid> */}
                </Grid>
              );
            })}
          </Grid>
          {ApprovalsListing?.esignatureUrl && (
            <Grid container item>
              <Grid item>
                <Typography
                  variant="h6"
                  fontWeight={"400"}
                  fontSize={"24px"}
                  padding={"16px"}
                  paddingLeft={"0px"}
                  marginBottom={"10px"}
                >
                  Customer Signature
                </Typography>
                <img
                  src={ApprovalsListing?.esignatureUrl}
                  alt=".."
                  className="sign-img"
                />
                <Typography>
                  {dayjs(ApprovalsListing?.esignatureAddedAt).format(
                    "MMMM DD, YYYY h:mm A"
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
          <>
            <Grid container item justifyContent={"start"} marginTop={"32px"}>
              <Grid item>
                <Stack spacing={1}>
                  <Typography variant="h5" fontWeight={400}>
                    Note for the Customer
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container item marginTop={"16px"}>
              <Stack border={"1px solid gray"} padding={"16px"} width={"100%"}>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  maxWidth={"550px"}
                  fontSize={"14px"}
                  color={"#000"}
                >
                  {/* Optional Description:{" "} */}
                  {ApprovalsListing?.approvalNote != "undefined"
                    ? ApprovalsListing?.approvalNote
                    : "Data not Available"}
                </Typography>
              </Stack>
            </Grid>
          </>
        </Grid>
      </Grid>

      {open && (
        <AppointmentModel
          open={open}
          handleClose={handleClose}
          data={data}
          status={data?.status}
          show={openODOModel}
          handleStatusClose={handleStatusClose}
          handleStatus={handleStatus}
          recallDetailsAPI={details}
          setUpdateStatus={setAppointmentStatus}
          setAppointmentCompletionOffODo={setAppointmentCompletionOffODo}
          setAppointmentDropOffODo={setAppointmentDropOffODo}
        />
      )}
      {(data?.status == "waiting_for_drop_off" ||
        data?.status == "in_progress" ||
        data?.status == "vehicle_dropped_off") && (
        <AddOdoModal
          open={openODOModel}
          handleClose={() => setOpenODOModel(false)}
          updateStatus={appointmentStatus}
          id={data?._id}
          handleClose1={() => setOpenODOModel(false)}
          currentStatus={data?.status}
          handleClose2={() => setOpenODOModel(false)}
          appointmentDropOffODO={appointmentDropOffODO}
          appointmentCompletionOffODO={appointmentCompletionOffODO}
          setAppointmentCompletionOffODo={setAppointmentCompletionOffODo}
          setAppointmentDropOffODo={setAppointmentDropOffODo}
          appointmentView={details}
          // recallDetailsAPI={fetchAppointmentDetailsForModel}
        />
      )}
    </Box>
  );
};

export default CurrentApproval;
