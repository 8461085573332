import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

// Personal details fetch
export const personalDetails = () => async (dispatch) => {
  try {
    const response = await DataService.get(
      API.account.personal.getPersonalDetail
    );
    if (!response.data.error) {
      dispatch({
        type: API.account.personal.getPersonalDetail,
        personalDetailsData: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

// Update personal details
export const updatePersonalDetails = (payload) => async () => {
  try {
    const response = await DataService.put(
      API.account.personal.updatePersonalDetail,
      payload
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
// Personal details fetch
export const configDetails = () => async (dispatch) => {
  try {
    const response = await DataService.get(API.config.get);
    console.log("🚀 ~ configDetails ~ response:", response);
    if (response.status == 200) {
      dispatch({
        type: API.config.get,
        configDetails: response.data.data.config,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
