import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  format,
  startOfToday,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  endOfWeek,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";
import { downloadReport } from "../../redux/Invoice/action";
import { toast } from "sonner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 3,
};

const ExportReportModel = ({ open, close }) => {
  const dispatch = useDispatch();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("");
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const handleClose = () => {
    close(false);
    setFromDate(null);
    setToDate(null);
    setSelectedTimePeriod("");
  };

  const handleChangeTimePeriod = (event) => {
    const selectedType = event.target.value;
    setSelectedTimePeriod(selectedType);

    const today = startOfToday();
    let from = null;
    let to = null;

    switch (selectedType) {
      case "today":
        from = dayjs(today);
        to = dayjs(today);
        break;
      case "thisWeek":
        from = dayjs(startOfWeek(today));
        to = dayjs(endOfWeek(today));
        break;
      case "thisMonth":
        from = dayjs(startOfMonth(today));
        to = dayjs(endOfMonth(today));
        break;
      case "thisQuarter":
        from = dayjs(startOfQuarter(today));
        to = dayjs(endOfQuarter(today));
        break;
      case "thisYear":
        from = dayjs(startOfYear(today));
        to = dayjs(endOfYear(today));
        break;
      case "lastWeek":
        from = dayjs(startOfWeek(subWeeks(today, 1)));
        to = dayjs(endOfWeek(subWeeks(today, 1)));
        break;
      case "lastMonth":
        from = dayjs(startOfMonth(subMonths(today, 1)));
        to = dayjs(endOfMonth(subMonths(today, 1)));
        break;
      case "lastQuarter":
        from = dayjs(startOfQuarter(subQuarters(today, 1)));
        to = dayjs(endOfQuarter(subQuarters(today, 1)));
        break;
      case "lastYear":
        from = dayjs(startOfYear(subYears(today, 1)));
        to = dayjs(endOfYear(subYears(today, 1)));
        break;
      case "allTime":
        from = dayjs("01/01/2024");
        to = dayjs(today);
        break;
      case "custom":
        // Allow manual input
        break;
      default:
        from = null;
        to = null;
        break;
    }

    setFromDate(from);
    setToDate(to);
  };
  const handleDownload = async (type) => {
    console.log(
      "type",
      type,
      dayjs(fromDate).format("YYYY-MM-DD"),
      dayjs(toDate).format("YYYY-MM-DD")
    );
    if (
      (!fromDate && !toDate) ||
      (dayjs(fromDate).format("YYYY-MM-DD") == "Invalid Date" &&
        dayjs(toDate).format("YYYY-MM-DD") == "Invalid Date")
    ) {
      return toast.error("Please enter from date and to date ");
    }
    if (!fromDate || dayjs(fromDate).format("YYYY-MM-DD") == "Invalid Date")
      return toast.error("Please enter from date");
    if (!toDate || dayjs(toDate).format("YYYY-MM-DD") == "Invalid Date")
      return toast.error("Please enter to date");

    if (fromDate && toDate) {
      setShowLoader(true);
      const payload = {
        fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
        toDate: dayjs(toDate).format("YYYY-MM-DD"),
        format: type,
      };
      const resp = await dispatch(downloadReport(payload));
      setShowLoader(false);
      if (resp?.status == 200) {
        const contentType = resp.headers["content-type"];
        const contentDisposition = resp.headers["content-disposition"];

        if (
          (contentType &&
            contentType.includes(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            )) ||
          contentType.includes("text/csv") ||
          contentType.includes("application/pdf")
        ) {
          const blob = new Blob([resp.data], { type: contentType });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const filename = contentDisposition
            ? contentDisposition.split("filename=")[1].replace(/"/g, "")
            : `Invoice-sales-report.${type}`;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
          handleClose();
          toast.success("Sales report exported successfully");
          return "File download initiated.";
        } else {
          handleClose();

          return resp.data;
        }
      } else if (resp?.status == 400) {
        toast.info("No invoice data record found for the date range.");
      } else {
        toast.info("Internal Server Error");
      }
    }
  };
  const disablePrevDates = (toDate) => {
    const startSeconds = Date.parse(toDate);
    return (date) => {
      return Date.parse(date) > startSeconds;
    };
  };
  const disableFutureDates = (toDate) => {
    const startSeconds = Date.parse(toDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    };
  };
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Box
          sx={{
            ...style,
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                Export Invoices
              </Typography>
              <Stack direction={"row"}>
                <div
                  onClick={handleClose}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            // gap={2}
            disableGutters
            style={{
              marginTop: "64px",
            }}
          >
            <Grid
              container
              // spacing={3}
              style={{
                scrollbarColor: "blue",
                scrollbarWidth: "thin",
                overflowY: "auto",
                maxHeight: "78vh",
              }}
              padding={"24px 24px 0 24px"}
            >
              {/* <hr style={{ width: "100%" }} /> */}
              <Divider style={{ width: "100%", marginBottom: "24px" }} />
              <Grid container gap={3}>
                <Grid md={3.71}>
                  <Stack>
                    <div>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                        // padding={"5px"}
                      >
                        Select Time Period
                      </Typography>
                      <Select
                        fullWidth
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 300, // Adjust this value as needed for your design
                            },
                          },
                        }}
                        value={selectedTimePeriod}
                        onChange={(e) => handleChangeTimePeriod(e)}
                      >
                        <MenuItem key={"allTime"} value="allTime">
                          All Time
                        </MenuItem>
                        <MenuItem key={"custom"} value="custom">
                          Custom
                        </MenuItem>
                        <MenuItem key={"today"} value="today">
                          Today
                        </MenuItem>{" "}
                        <MenuItem key={"thisWeek"} value="thisWeek">
                          This Week
                        </MenuItem>{" "}
                        <MenuItem key={"thisMonth"} value="thisMonth">
                          This Month
                        </MenuItem>{" "}
                        <MenuItem key={"thisQuarter"} value="thisQuarter">
                          This Quarter
                        </MenuItem>{" "}
                        <MenuItem key={"thisYear"} value="thisYear">
                          This Year
                        </MenuItem>{" "}
                        <MenuItem key={"lastWeek"} value="lastWeek">
                          Last Week
                        </MenuItem>
                        <Divider style={{ width: "100%" }} />
                        <MenuItem key={"lastMonth"} value="lastMonth">
                          Last Month
                        </MenuItem>
                        <MenuItem key={"lastQuarter"} value="lastQuarter">
                          Last Quarter
                        </MenuItem>
                        <MenuItem key={"lastYear"} value="lastYear">
                          Last Year
                        </MenuItem>
                      </Select>
                    </div>
                  </Stack>
                </Grid>
                <Grid md={3.71}>
                  <Stack>
                    <div>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                        // padding={"5px"}
                      >
                        From
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={fromDate}
                          onChange={(newValue) => {
                            setFromDate(newValue);
                            setSelectedTimePeriod("custom");
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                          // disabled={selectedTimePeriod !== "custom"}
                          shouldDisableDate={disablePrevDates(toDate)}
                        />
                      </LocalizationProvider>
                    </div>{" "}
                  </Stack>
                </Grid>
                <Grid md={3.71}>
                  <Stack>
                    <div>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                        // padding={"5px"}
                      >
                        To
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={toDate}
                          onChange={(newValue) => {
                            setToDate(newValue);
                            setSelectedTimePeriod("custom");
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                          // disabled={selectedTimePeriod !== "custom"}
                          shouldDisableDate={disableFutureDates(fromDate)}
                        />
                      </LocalizationProvider>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container justifyContent={"space-between"} p={3}>
              <div>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    CANCEL
                  </Button>
                </Stack>
              </div>

              <div>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownload("pdf")}
                  >
                    EXPORT PDF
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownload("csv")}
                  >
                    EXPORT CSV
                  </Button>
                </Stack>
              </div>
            </Grid>
          </Container>
        </Box>
        {/* </form> */}
      </Modal>
      <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ExportReportModel;
