import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import "./Appointment.css";

import { useLocation, useNavigate } from "react-router-dom";
import PastAppointment from "./PastAppointment/PastAppointment";
import TodayAppointment from "./TodayAppointment/TodayAppointment";
import UpcomingAppointment from "./UpcomingAppointment/UpcomingAppointment";

const Appointments = () => {
  const navigate = useNavigate();
  const path = useLocation();
  const today = path?.pathname;
  const [value, setValue] = React.useState(today);

  useEffect(() => {
    const nameArray = window.location.pathname.split("/");
    const name = nameArray[nameArray.length - 1];
    if (name == "today") {
      setValue(0);
    }
    if (name == "upcoming-appointments") {
      setValue(1);
    }
    if (name == "past-appointments") {
      setValue(2);
    }
  }, [window.location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 0) {
      navigate("/appointment/today");
    }
    if (newValue == 1) {
      navigate("/appointment/upcoming-appointments");
    }
    if (newValue == 2) {
      navigate("/appointment/past-appointments");
    }
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ height: 0 }}
      >
        {value === index && (
          <Box sx={{ pb: "32px" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box padding={"32px 24px"} bgcolor="background.main">
      <Grid container padding={"16px 0px"} justifyContent={"space-between"}>
        <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
          <Typography variant="h4" fontWeight={400} fontSize={"34px"}>
            Appointments
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => navigate("/appointment/new-appointment")}
          >
            NEW APPOINTMENT
          </Button>
        </Grid>
      </Grid>
      <Grid container padding={"32px 0px"}>
        <Box sx={{ width: "100%" }}>
          <Box paddingBottom={"32px"}>
            <Tabs
              value={value}
              className="tab"
              onChange={handleChange}
              sx={{ textColor: "tabColor.main" }}
              aria-label="basic tabs example"
            >
              <Tab
                color="primary"
                label=<Typography
                  fontSize={"15px"}
                  fontWeight={500}
                  lineHeight={"26px"}
                >
                  TODAY
                </Typography>
                {...a11yProps(0)}
              />
              <Tab
                label=<Typography
                  fontSize={"15px"}
                  fontWeight={500}
                  lineHeight={"26px"}
                >
                  UPCOMING
                </Typography>
                {...a11yProps(1)}
              />
              <Tab
                label=<Typography
                  fontSize={"15px"}
                  fontWeight={500}
                  lineHeight={"26px"}
                >
                  PAST
                </Typography>
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <div className="TodayAppointment">
              <TodayAppointment
              // open={openWaiting} handleClose={() => setOpenWaiting(false)} handleOpen={handleOpen}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <UpcomingAppointment />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <PastAppointment />
          </CustomTabPanel>
        </Box>
      </Grid>
    </Box>
  );
};
export default Appointments;
