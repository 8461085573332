import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";
import { setItem } from "../../utility/localStorage";

//LOGIN API SETUP
export const handleLogin = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(API.auth.login, payload);

    if (response.data.status) {
      // setItem("apex-saas_token", response.data.data.token);
    //FIRST TIME LOGIN
      if (response.data.data.isFirstTimeLogin) {

        setItem("apex-saas_token", response.data.data.token);
        setItem("apex-saas-id", response.data.data._id);
      } else {
        setItem("apex-saas_email", response.data.data.email);
        setItem("apex-saas_token", response.data.data.token);

        // setItem(
        //   "apex-saas_token",
        //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1M2M5M2VmMGE3ZDIwMDliNzMyNzFjYyIsInJvbGUiOiJzaG9wX293bmVyIiwic2hvcCI6IjY1M2M5M2VjMGE3ZDIwMDliNzMyNzBkYiIsImlhdCI6MTY5ODQ2ODkyMywiZXhwIjoxNzAxMDYwOTIzfQ.qjmCCLvPcvbaKQjKNTXSJ2YnVrg1BAcASnfsrNrk_3A"
        // );
        let data = response.data.data.shop;
        data.shopOwnerId = response.data.data._id;
        setItem("apex-saas_details", data);

        setItem("loggedIn", true);
        dispatch({
          type: API.auth.login,
          userData: response.data.data,
          login: true,
          loading: false,
        });
      }

      return response;
    } else {
      return response;
    }
  } catch (error) {
    return false;
  }
};

//register api setup
export const handleRegister = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(API.auth.register, payload);
    if (response.data.status) {
      dispatch({
        type: API.auth.register,
        shopInfoData: response.data.data,
        loading: false,
      });

      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

//LOGOUT API SETUP
export const handlelogout = () => async () => {
  try {
    const response = await DataService.post(API.auth.logout);
    if (!response.data.error) {
      window.localStorage.clear();
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

//FORGOT PASSWORD SETUP
export const handleForgot = (payload) => async () => {
  try {
    const response = await DataService.post(API.auth.forgetpass, payload);

    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

//VERIFY OTP API SETUP
export const handleVerifyotp = (payload) => async () => {
  try {
    const response = await DataService.post(API.auth.verifyotp, payload);
    if (!response.data.error) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

//RESET PASSWORD API SETUP
export const handleResetPassword = (payload) => async () => {
  try {
    const response = await DataService.post(API.auth.resetpassword, payload);
    if (!response.data.error) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const handleChangePassword = (payload) => async () => {
  try {
    const response = await DataService.post(API.auth.changePassword, payload);
    if (!response.data.error) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

//FIRST TIME LOGIN
export const setFirstTimePassword = (payload) => async (dispatch) => {
  console.log("payload",payload);
  const id=localStorage.getItem("apex-saas-id");
  try {
    const response = await DataService.post(API.auth.setFirstPassword+id, {password:payload.password});
    if (response.data.status) {
      setItem("apex-saas_token", response.data.data.token);
      setItem("apex-saas_email", response.data.data.email);

      // setItem(
      //   "apex-saas_token",
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1M2M5M2VmMGE3ZDIwMDliNzMyNzFjYyIsInJvbGUiOiJzaG9wX293bmVyIiwic2hvcCI6IjY1M2M5M2VjMGE3ZDIwMDliNzMyNzBkYiIsImlhdCI6MTY5ODQ2ODkyMywiZXhwIjoxNzAxMDYwOTIzfQ.qjmCCLvPcvbaKQjKNTXSJ2YnVrg1BAcASnfsrNrk_3A"
      // );
      let data = response.data.data.shop;
      data.shopOwnerId = response.data.data._id;
      setItem("apex-saas_details", data);

      setItem("loggedIn", true);
      dispatch({
        type: API.auth.login,
        userData: response.data.data,
        login: true,
        loading: false,
      });
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
