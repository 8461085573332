import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  Modal,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { createNewInvoice } from "../../redux/SendFeedBack/action";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pl: 4,
  pr: 0,
  pb: 3,
};
const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    height: "115px", // Adjust the height as needed
  },
});
const ServiceModal = ({
  open,
  open1,
  handleClose,
  handleOpen1,
  handleClose1,
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    let res = await dispatch(createNewInvoice(data));
    handleClose();
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              ...style,
              width: "720px",
              border: "none",
              borderRadius: "8px",
            }}
          >
            <AppBar
              color="secondary"
              variant="elevation"
              sx={{
                height: "64px",
                paddingRight: "0px !important",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <Toolbar variant="regular">
                <Typography variant="h6" width={"98%"} color={"white"}>
                  Send Feedback
                </Typography>

                <Stack direction={"row"} spacing={0}>
                  <div onClick={handleClose} style={{ cursor: "pointer",height:'32px' }}
                  >
                    <CloseIcon style={{ color: "#FFFFFF" ,fontSize: 32 }} />
                  </div>
                </Stack>
              </Toolbar>
            </AppBar>
            <Container
              gap={"24px"}
              disableGutters
              style={{ marginTop: "66px" }}
            >
              <Stack>
                <div>
                  <Typography variant="body1" fontWeight={"medium"}>
                    Your feedback is extremely valuable to us. We are committed
                    and dedicated to delivering an experience that continuously
                    meets your needs.
                  </Typography>
                </div>
                <div
                  style={{
                    marginTop: "9px",
                    height: "160px",
                    paddingRight: "25px",
                  }}
                >
                  {/* <TextField
                    placeholder="Type your feedback here"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    rows={5}
                    maxRows={5}
                    multiline
                  /> */}

                  <Controller
                    name="description"
                    control={control}
                    // rules={{
                    //   required: "Shop Name is required",
                    // }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Type your feedback here"
                        id="outlined-basic"
                        // label="Shop Name"
                        variant="outlined"
                        fullWidth
                        required
                        rows={5}
                        maxRows={5}
                        multiline
                        error={!!errors.description}
                        helperText={
                          errors.description ? errors.description.message : ""
                        }
                        {...field}
                        InputLabelProps={{ shrink: field.value?.length > 0 }}
                      />
                    )}
                  />
                </div>
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                style={{ float: "right", paddingRight: "25px" }}
              >
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={handleClose}
                  >
                    CANCEL
                  </Button>
                </div>
                <div>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    type="submit"
                  >
                    SEND
                  </Button>
                </div>
              </Stack>
            </Container>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

export default ServiceModal;
