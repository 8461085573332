import InfoIcon from "@mui/icons-material/Info";
import {
  Grid,
  styled,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {
  ContentState,
  convertFromHTML,
  Editor,
  EditorState,
  Modifier,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Context, REQUIRED_RED } from "../../../App";
import {
  emailThankuDetails,
  sendTestThankuEmail,
  updateEmailThankuDetails,
} from "../../../redux/Account/peronalIfno/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import "./EmailCustomer.css";
import { useTheme } from "@emotion/react";
const EmailCustomer = ({ setShowLoader }) => {
  const dispatch = useDispatch();
  // const [showLoader, setShowLoader] = useState(false);
  const { isOpenSideBar } = useContext(Context);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [thankuSwitch, setThankuSwitch] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const {
    handleSubmit,

    setValue,
    control,
    formState: { errors },
  } = useForm();
  console.log("isOpenSideBar", isOpenSideBar);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const islgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    fetchThankYouEmailDetails();
  }, []);

  const fetchThankYouEmailDetails = async () => {
    setShowLoader(true);

    const data = await dispatch(emailThankuDetails());

    if (data) {
      setShowLoader(false);
    }
  };

  const DetailsData = useSelector(
    (state) => state?.account?.thankyouDetailData
  );
  function cleanHtmlContent(html) {
    // Remove unnecessary <br> tags and newlines between <p> tags
    const cleanedHtml = html
      .replace(/<pre>/g, "<p>") // Remove opening <pre> tag
      .replace(/<\/pre>/g, "</p>") // Remove closing </pre> tag
      .replace(/&#10;/g, "</p><p>") // Replace newline characters with closing and opening <p> tags

      .replace(/<p>\s*<\/p>/g, "") // Remove empty <p> tags
      .replace(/\n\s*<p>/g, "<p>") // Remove newline before <p> tags
      .replace(/<code>/g, '<code style="font-family:Roboto">')
      .replace(/<p>/g, '<p style="margin:0">');

    return cleanedHtml;
  }

  useEffect(() => {
    // setTimeout(() => {
    setValue("shopEmail", DetailsData?.replyToEmail);
    setThankuSwitch(DetailsData?.isShopSendThankYouEmail);

    // Convert HTML to ContentState
    const blocksFromHTML = convertFromHTML(
      DetailsData?.shopThankYouEmailSignature
        ? cleanHtmlContent(DetailsData?.shopThankYouEmailSignature)
        : ""
    );

    // Safeguard to check if the conversion returned valid blocks
    const contentBlocks = blocksFromHTML.contentBlocks;
    let contentState =
      contentBlocks.length > 0
        ? ContentState.createFromBlockArray(
            contentBlocks,
            blocksFromHTML.entityMap
          )
        : ContentState.createFromText(""); // Fallback to an empty content state

    // Apply the Roboto style to every character
    const selectionState = contentState.getSelectionAfter();
    const blockMap = contentState.getBlockMap();

    blockMap.forEach((block) => {
      const blockKey = block.getKey();
      const length = block.getLength();

      for (let i = 0; i < length; i++) {
        const updatedSelection = selectionState.merge({
          anchorKey: blockKey,
          anchorOffset: i,
          focusKey: blockKey,
          focusOffset: i + 1,
        });

        contentState = Modifier.applyInlineStyle(
          contentState,
          updatedSelection,
          "ROBOTO"
        );
      }
    });
    setEditorState(EditorState.createWithContent(contentState));

    // }, 800);
  }, [DetailsData]);
  console.log("DetailsDatasss", DetailsData);

  const onSubmitEmailDetails = async (values) => {
    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText();

    if (text.trim() === "") {
      return setError(true);
    } else {
      setError(false);
    }

    let htmlContent = stateToHTML(editorState.getCurrentContent());
    htmlContent = cleanHtmlContent(htmlContent);
    console.log("htmlContent", htmlContent, values, thankuSwitch);
    let payload = {
      isShopSendThankYouEmail: thankuSwitch,
      replyToEmail: values?.shopEmail,
      shopThankYouEmailSignature: htmlContent,
    };
    setShowLoader(true);
    let resp = "";
    if (isTesting) {
      delete payload.isShopSendThankYouEmail;
      payload.shopThankYouEmailSignature = `${htmlContent}`;
      resp = await dispatch(sendTestThankuEmail(payload));
    } else {
      if (thankuSwitch == false) {
        delete payload.replyToEmail;
        delete payload.shopThankYouEmailSignature;
      }
      resp = await dispatch(updateEmailThankuDetails(payload));
    }
    if (resp && !isTesting) {
      fetchThankYouEmailDetails();
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
    }

    if (resp && isTesting) {
      setOpenSuccessModal(true);
      setSuccessMessage("Email Sent");
    }

    setShowLoader(false);
  };

  const cancelHandle = () => {
    setValue("shopEmail", DetailsData?.replyToEmail);
    setThankuSwitch(DetailsData?.isShopSendThankYouEmail);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(DetailsData?.shopThankYouEmailSignature ?? "")
        )
      )
    );
  };

  const validateNoLeadingSpaces = (value) => {
    if (value && value.trim() !== value) {
      return "Leading spaces are not allowed";
    }
    return true;
  };

  const ThankyouToggle = styled((props) => (
    <Switch
      // disabled={disabledSwitch}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      defaultChecked={thankuSwitch}
      onChange={(e) => setThankuSwitch(e.target.checked)}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#92BC94",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light"
          ? "rgba(120, 120, 128, 0.16)"
          : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const handleEditorChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const text = contentState.getPlainText();

    if (text.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
    // const rawContent = convertToRaw(newEditorState.getCurrentContent());
    // const text = rawContent.blocks.map((block) => block.text).join("");
    // console.log("text", text);

    setEditorState(newEditorState);
  };
  const blockStyleFn = (block) => {
    if (block.getType() === "header-one") {
      return "header-one";
    } else if (block.getType() === "header-two") {
      return "header-two";
    } else if (block.getType() === "ROBOTO_BLOCK") {
      return "roboto-block";
    } else {
      return null;
    }
  };

  // Handle pasted text to apply the Roboto style
  const handlePastedText = (text, html, editorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Replace pasted content with plain text and apply Roboto style
    const newContentState = Modifier.replaceText(
      contentState,
      selection,
      text,
      editorState.getCurrentInlineStyle().add("ROBOTO")
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    setEditorState(newEditorState);
    return "handled";
  };

  return (
    <>
      <Box>
        {" "}
        <form
          onSubmit={handleSubmit(onSubmitEmailDetails)}
          style={{ marginTop: "20px" }}
        >
          <Grid
            container
            sx={{ boxShadow: 3, bgcolor: "white" }}
            justifyContent={"start"}
            // alignItems={"center"}
            marginTop={"32px"}
            padding={"24px"}
            borderRadius={"10px"}
            // marginTop={"20px"}
          >
            <Grid
              item
              xs={12}
              sm={isOpenSideBar ? 12 : 6}
              md={isOpenSideBar ? 6 : 6}
              lg={4}
              xl={4}
            >
              <div style={{ display: "flex" }}>
                <Typography variant="h5">Email for Customers </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "12px",
                  }}
                >
                  <Tooltip
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -4],
                            },
                          },
                        ],
                      },
                    }}
                    title={
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: "12px",
                          width: "140px",
                        }}
                      >
                        Send automated emails to customers to thank them for
                        their business.
                      </div>
                    }
                  >
                    {" "}
                    <InfoIcon
                      style={{ color: "#0000008F", display: "block" }}
                    />{" "}
                  </Tooltip>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={isOpenSideBar ? 12 : 6}
              md={isOpenSideBar ? 6 : 6}
              lg={0}
              xl={0}
              sx={{ display: islgUp ? "none" : "flex", justifyContent: "end" }}
            >
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                className="btn-text-size-manage"
                onClick={() => {
                  setIsTesting(true);
                }}
                disabled={thankuSwitch == false}
              >
                Send Test Email
              </Button>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={isOpenSideBar ? 12 : 7}
              lg={8}
              xl={8}
              gap={3}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"end"}
              >
                <div style={{ display: "flex" }}>
                  <ThankyouToggle size="large" />
                  <Typography pl={"14px"}>Thank You Emails</Typography>
                </div>

                {islgUp && (
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    className="btn-text-size-manage"
                    onClick={() => {
                      setIsTesting(true);
                    }}
                    disabled={thankuSwitch == false}
                  >
                    Send Test Email
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  Shop Email for Thank You{" "}
                  <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="shopEmail"
                  control={control}
                  rules={{
                    required: "Shop Email for Thank You is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid email address",
                    },
                    validate: validateNoLeadingSpaces,
                  }}
                  render={({ field }) => (
                    <TextField
                      // label="Email"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      error={!!errors.shopEmail}
                      helperText={
                        errors.shopEmail ? errors.shopEmail.message : ""
                      }
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                      disabled={thankuSwitch == false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Typography variant="body1" className="label-500">
                  Email Signature (From){" "}
                  <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>

                <div
                  style={{
                    border: error
                      ? "1px solid rgb(211,47,47)"
                      : "1px solid #ACACAC",
                    minHeight: "60px",
                    maxHeight: "80px",

                    padding: "12px",
                    borderRadius: "4px",
                    overflow: "auto",
                  }}
                >
                  <Editor
                    editorState={editorState}
                    onChange={handleEditorChange}
                    blockStyleFn={blockStyleFn}
                    readOnly={thankuSwitch == false}
                    handlePastedText={handlePastedText}
                    customStyleMap={{
                      ROBOTO: {
                        fontFamily: "Roboto, sans-serif",
                      },
                    }}
                  />
                </div>

                {error && (
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "rgb(211,47,47)",
                    }}
                  >
                    Email Signature is required
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"end"}
              // spacing={2}
              style={{ marginTop: "10px" }}
            >
              <Grid item spacing={2}>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn-text-size-manage"
                    onClick={cancelHandle}
                  >
                    Cancel Changes
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className="btn-text-size-manage"
                    onClick={() => {
                      setIsTesting(false);
                    }}
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>{" "}
        </form>
      </Box>
      {/* <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog> */}
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
};

export default EmailCustomer;
