import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
  loading: false,
  calendarList: {},
  availablity: [],
  settings: [],
  appointmentsStatus: [],
};

//DEFINE ACCOUNT REDUCER FUNCTION
const CalendarReducer = (state = initState, action) => {
  switch (action.type) {
    case API.calendar.listing:
      return {
        ...state,
        calendarList: action.calendarData,
        loading: action.loading,
      };
    case API.calendar.availablityStatus:
      return {
        ...state,
        availablity: action.availablity,
        loading: action.loading,
      };
    case API.calendar.getSetting:
      return {
        ...state,
        settings: action.settings,
        loading: action.loading,
      };
    case API.calendar.getAppointmentsStatus:
      return {
        ...state,
        appointmentsStatus: action.appointmentsStatus,
        loading: action.loading,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default CalendarReducer;
