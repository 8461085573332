import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

// Personal details fetch
export const calendarListing = (payload) => {
  console.log();
  return async (dispatch) => {
    let queryParameter = `?startDate=${payload.startDate}&endDate=${
      payload.endDate
    }&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    payload.assignee?.map((d, index) => {
      queryParameter += `&assignee[${index}]=${d}`;
    });
    payload.calendarViewPreferences?.map((d, index) => {
      queryParameter += `&calendarViewPreferences[${index}]=${d}`;
    });

    try {
      const response = await DataService.get(
        API.calendar.listing + queryParameter
      );

      if (!response.data.error) {
        dispatch({
          type: API.calendar.listing,
          calendarData: response.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getAvailablity = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.calendar.availablityStatus);

      if (!response.data.error) {
        dispatch({
          type: API.calendar.availablityStatus,
          availablity: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const updateAvailablity = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.calendar.updateStatus,
        payload
      );

      if (response.data.status) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getCalendarSetting = ({ page, limit }) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.calendar.getSetting + `?page=${page}&limit=${limit}`
      );

      if (response.data.status) {
        dispatch({
          type: API.calendar.getSetting,
          settings: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const editCalendarSetting = ({ id, name }) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.calendar.editSetting + id,
        name
      );

      if (response.data.status) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const deleteCalendarSetting = ({ id }) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        API.calendar.deleteSetting + id
      );

      if (response.data.status) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const addCalendarSetting = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.calendar.addSetting, payload);

      if (response.data.status) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const getAppointmentCalendarPrefrences = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.calendar.getAppointmentsStatus
      );

      if (!response.data.error) {
        dispatch({
          type: API.calendar.getAppointmentsStatus,
          appointmentsStatus: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const getCalendarSettingAssignee = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.calendar.getAssigneesSetting);
      if (!response.data.error) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const editCalendarSettingAssignee = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.calendar.editAssigneesSetting,
        payload
      );
      if (!response.data.error) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
