export const handlePreventKeyDown = (event) => {
  console.log("event", event);
  const validKeys = [
    "Backspace",
    "Tab",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    "Enter",
    "Home",
    "End",
  ];
  if (validKeys.includes(event.key)) {
    return;
  }

  if (/^[0-9]$/.test(event.key)) {
    if (event.target.value.length >= 12) {
      event.preventDefault();
    }
  } else {
    event.preventDefault();
  }
};

export const handlePreventKeyDownDecimal = (event) => {
  const validKeys = [
    "Backspace",
    "Tab",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    "Enter",
    "Home",
    "End",
  ];

  if (validKeys.includes(event.key)) {
    return;
  }

  const { value, selectionStart, selectionEnd } = event.target;

  if (event.key === "." && !value.includes(".")) {
    return;
  }

  if (/^[0-9]$/.test(event.key)) {
    const [integerPart, decimalPart] = value.split(".");
    if (
      decimalPart &&
      selectionStart > value.indexOf(".") &&
      decimalPart.length >= 2
    ) {
      event.preventDefault();
    } else if (
      integerPart.length >= 12 &&
      selectionStart <= value.indexOf(".")
    ) {
      event.preventDefault();
    }
  } else {
    event.preventDefault();
  }
};

export const formatNumberWithCommas = (number) => {
  if (!number) return "";
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const parseNumberFromCommas = (numberString) => {
  if (!numberString) return "";
  return numberString.replace(/,/g, "");
};
