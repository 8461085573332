import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  AppBar,
  CircularProgress,
  Dialog,
  DialogContent,
  Modal,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useTheme } from "@emotion/react";
import FormatNumber from "../../components/FormatNumber/FormatNumber";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  pt: 2,
  pl: 4,
  pr: 0,
  pb: 3,
};
const styleTag = {
  borderRadius: "48px",
  color: "black",
  padding: "10px 20px",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  margin: "10px",
  width: "200px",
};
const serviceStyle = {
  fontSize: "13px",
  fontWeight: 500,
  // borderStyle: "solid",
  // borderWidth: "1px",
  // borderColor: "black",
  padding: "5px 10px",
  borderRadius: "20px",
  // color: "white",
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AppointmentCancelModel = ({
  open,
  setOpen,
  appointmentData,
  data,
  showLoader,
  setShowLoader,
  status,
  show,
  handleStatusClose,
  handleStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [updateStatus, setUpdateStatus] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [showLoader, setShowLoader] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const open1 = Boolean(anchorEl);
  const handlePreviewPdf = () => {
    const pdfUrl = appointmentData?.estimate?.pdfUrl;
    window.open(pdfUrl, "_blank");
  };
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const filteredData = appointmentData?.services?.filter(
    (item) => item.newAdded
  );

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "930px", height: "674px", border: "none" }}>
          <AppBar
            variant="outlined"
            className="modalHeader"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography
                variant="h6"
                gutterBottom={false}
                color={"black"}
                width={"98%"}
                fontSize={"20px"}
              >
                Appointment Details
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleClose}
                  style={{
                    cursor: "pointer",
                    color: "black",
                    height: "32px",
                  }}
                  className="appointmentModal"
                >
                  <CloseIcon style={{ fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Grid
            container
            marginTop={"22px"}
            padding={"25px 0px 0px 10px "}
            fontSize={"15px"}
            fontWeight={500}
            height="100%"
          >
            <Grid item md={4}>
              <Stack gap={"10px"} mt={2}>
                {appointmentData?.hasEstimate && (
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      width: "fit-content",
                      fontSize: "15px",
                      marginBottom: "6px",
                      // marginTop:
                      //   status == "waiting_for_drop_off" ||
                      //   status == "in_progress"
                      //     ? "0px"
                      //     : "8px",
                    }}
                    onClick={() => {
                      navigate(
                        `/estimates/sent/${appointmentData?.estimate?._id}`
                      );
                      handleClose();
                    }}
                  >
                    VIEW ESTIMATE
                  </Button>
                )}

                <Button
                  variant="contained"
                  size="large"
                  style={{ width: "fit-content", fontSize: "15px" }}
                  onClick={() => {
                    navigate(`/customerChat/${appointmentData?.customer?._id}`);
                    handleClose();
                  }}
                >
                  SEND MESSAGE
                </Button>
              </Stack>
            </Grid>
            {/* <Divider orientation="vertical" flexItem /> */}
            <Grid
              item
              md={8}
              style={{
                backgroundColor: "#F4F4F4",
                borderLeft: "1px solid #E0E0E0",
                padding: "24px",
              }}
            >
              <Box marginBottom={"16px"}>
                <Alert
                  variant="filled"
                  severity="error"
                  sx={{ fontWeight: "500" }}
                  style={{ fontSize: "16px" }}
                >
                  <AlertTitle>Appointment Cancelled</AlertTitle>
                  Cancellation Fee:{" "}
                  {appointmentData?.cancellationObj &&
                  Object.keys(appointmentData?.cancellationObj)?.length > 0 &&
                  appointmentData?.cancellationObj?.amount
                    ? FormatNumber({
                        inputValue: appointmentData?.cancellationObj?.amount,
                      })
                    : "No Fee"}
                  {/* $15.00 */}
                </Alert>
              </Box>
              <Box sx={{ width: "100%", border: "1px" }}>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      style={{ fontSize: "14px" }}
                      label="OVERVIEW"
                      {...a11yProps(0)}
                    />

                    <Tab
                      style={{ fontSize: "14px" }}
                      label="VEHICLE"
                      {...a11yProps(1)}
                    />
                    <Tab
                      style={{ fontSize: "14px" }}
                      label="SERVICES"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Grid container item spacing={2}>
                    <Grid container item>
                      <Grid item md={4} fontSize={"14px"} fontWeight={400}>
                        Customer
                      </Grid>

                      <Link
                        // to={`/customers/${data?.customer?._id}`}
                        style={{
                          color: "#000000",
                          textDecoration: "none",
                          fontSize: "16px",
                        }}
                      >
                        {appointmentData &&
                          appointmentData?.customer &&
                          appointmentData?.customer?.firstName +
                            " " +
                            appointmentData?.customer?.lastName}
                      </Link>
                    </Grid>
                    <Grid container item>
                      <Grid item md={4} fontSize={"14px"} fontWeight={400}>
                        Vehicle
                      </Grid>
                      <Grid item md={8} fontSize={"16px"} color={"#000000"}>
                        {appointmentData?.vehicle?.year
                          ? appointmentData?.vehicle?.year + " "
                          : " "}
                        {appointmentData?.vehicle?.vehicleMake?.name
                          ? appointmentData?.vehicle?.vehicleMake?.name + " "
                          : ""}
                        {appointmentData?.vehicle?.vehicleModel?.name
                          ? appointmentData?.vehicle?.vehicleModel?.name
                          : ""}
                        {/* {data?.vehicle?.year +
                          " " +
                          data?.vehicle?.vehicleMake?.name +
                          " " +
                          data?.vehicle?.vehicleModel?.name} */}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={4} fontSize={"14px"}>
                        Drop Off
                      </Grid>
                      <Grid item md={8} fontSize={"16px"} color={"#000000"}>
                        {/* {data?.dropOffDateTime
                          ? moment(data?.dropOffDateTime).format(
                              "MMM D, YYYY hh:mm A"
                            )
                          : " - "} */}
                        <del>
                          {appointmentData &&
                            appointmentData?.dropOffDateTime &&
                            moment(appointmentData?.dropOffDateTime).format(
                              "MMM D, YYYY hh:mm A"
                            )}
                        </del>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={4} fontSize={"14px"} fontWeight={"400"}>
                        Estimated Pick Up
                      </Grid>
                      <Grid item md={8} color={"#2196F3"} fontSize={"16px"}>
                        <del>
                          {appointmentData?.pickupDateTime != null
                            ? moment(
                                new Date(appointmentData?.pickupDateTime)
                              ).format("MMM D, YYYY h:mm A")
                            : appointmentData?.pickupDateTime
                            ? moment(appointmentData?.pickupDateTime).format(
                                "MMM D, YYYY hh:mm A"
                              )
                            : moment(appointmentData?.shopClosingTime).format(
                                "MMM D, YYYY hh:mm A"
                              )}
                        </del>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={4} fontSize={"14px"} fontWeight={"400"}>
                        Customer Wants To
                      </Grid>
                      <Grid item md={8} fontSize={"16px"} color={"#000000"}>
                        {appointmentData?.waitingOnSite &&
                        appointmentData?.waitingOnSite == "dropping_off_car"
                          ? "Dropping Off Car"
                          : " Wait on Site "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={4} fontSize={"14px"} fontWeight={"400"}>
                        Contact Preferences
                      </Grid>
                      <Grid item md={8} fontSize={"16px"} color={"#000000"}>
                        {/* {data?.contactPreferences ? data?.contactPreferences : " - "} */}
                        {appointmentData?.contactPreferences &&
                        appointmentData?.contactPreferences == "phone"
                          ? "Phone Call"
                          : appointmentData?.contactPreferences == "email"
                          ? "Email"
                          : "In-App Message"}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={4} fontSize={"14px"} fontWeight={"400"}>
                        Note from Customer
                      </Grid>
                      <Grid
                        item
                        md={8}
                        fontSize={"14px"}
                        fontWeight={"400"}
                        border={"1px solid gray"}
                        borderRadius={"8px"}
                        padding={"16px 16px"}
                      >
                        <Typography
                          variant="value"
                          fontWeight={400}
                          fontSize={"16px"}
                          lineHeight={"24px"}
                        >
                          {appointmentData?.cancellationNoteForMechanic
                            ? appointmentData?.cancellationNoteForMechanic
                            : " "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Grid container item spacing={2}>
                    <Grid container item>
                      <Grid item md={5} fontSize={"14px"} fontWeight={"400"}>
                        Year
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {appointmentData?.vehicle?.year
                          ? appointmentData?.vehicle?.year
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={5} fontSize={"14px"} fontWeight={"400"}>
                        Make
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {appointmentData?.vehicle?.vehicleMake?.name
                          ? appointmentData?.vehicle?.vehicleMake?.name
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={5} fontSize={"14px"} fontWeight={"400"}>
                        Model
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {appointmentData?.vehicle?.vehicleModel?.name
                          ? appointmentData?.vehicle?.vehicleModel?.name
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={5} fontSize={"14px"} fontWeight={"400"}>
                        Trim
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {appointmentData?.vehicle?.vehicleModelTrim?.name
                          ? appointmentData?.vehicle?.vehicleModelTrim?.name
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={5} fontSize={"14px"} fontWeight={"400"}>
                        Licence Plate
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {appointmentData?.vehicle?.licenseNumber
                          ? appointmentData?.vehicle?.licenseNumber
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={5} fontSize={"14px"} fontWeight={"400"}>
                        VIN
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {appointmentData?.vehicle?.vin
                          ? appointmentData?.vehicle?.vin
                          : " - "}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item md={5} fontSize={"14px"} fontWeight={"400"}>
                        Odometer
                      </Grid>
                      <Grid item md={7} fontSize={"16px"} color={"#000000"}>
                        {appointmentData?.vehicle?.odo
                          ? appointmentData?.vehicle?.odo + "km"
                          : " - "}
                        {/* <br></br>Last Updated:{" "} */}
                        {/* {moment(appointmentData?.vehicle?.odoUpdatedAt).format(
                          "MMM D, YYYY"
                        )} */}
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel
                  value={value}
                  index={2}
                  style={
                    {
                      // maxHeight: "61%",
                      // overflow: "auto",
                      // position: "absolute",
                    }
                  }
                >
                  <Grid container item rowGap={3}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      item
                      boxShadow={2}
                      padding={"16px"}
                      style={{ backgroundColor: "white" }}
                    >
                      <Grid item md={6}>
                        <Typography
                          style={{ fontSize: "16px", fontWeight: 500 }}
                          variant="title"
                        >
                          {" "}
                          Total Services
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ fontSize: "16px", fontWeight: 500 }}
                          variant="title"
                        >
                          {appointmentData?.services?.length}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        maxHeight: "292px",
                        overflowY: "auto",
                        width: "100%",
                      }}
                    >
                      {appointmentData?.services?.map((item) => {
                        return (
                          <>
                            <Grid
                              container
                              item
                              justifyContent={"space-between"}
                              rowGap={0}
                              boxShadow={2}
                              padding={"16px"}
                              style={{ backgroundColor: "white" }}
                            >
                              <Grid container justifyContent={"space-between"}>
                                <Grid item md={6}>
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                    }}
                                    variant="body1"
                                  >
                                    {" "}
                                    {item?.serviceName}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 400,
                                    }}
                                    variant="body1"
                                  >
                                    {item?.pricingType === "no_price"
                                      ? "No Price"
                                      : item?.pricingType === "fixed"
                                      ? item?.fixedPrice
                                        ? FormatNumber({
                                            inputValue: item?.fixedPrice,
                                          })
                                        : ""
                                      : item?.pricingType === "range"
                                      ? item?.priceMin
                                        ? FormatNumber({
                                            inputValue: item?.priceMin,
                                          })
                                        : "" + " - " + item?.priceMax
                                        ? FormatNumber({
                                            inputValue: item?.priceMax,
                                          })
                                        : ""
                                      : "-"}
                                  </Typography>
                                </Grid>
                                {/* {Object?.keys(item?.questionAnswer).length >
                                  0 && (
                                  <>
                                    <Divider style={{ width: "100%" }} />
                                    <Grid item md={12}>
                                      <Typography
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 500,
                                          marginTop: "5px",
                                        }}
                                        variant="body1"
                                      >
                                        {" "}
                                        {item?.questionAnswer?.question}
                                      </Typography>
                                    </Grid>
                                    <Grid item md={12}>
                                      <Typography
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 500,
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                        variant="body1"
                                      >
                                        {" "}
                                        {item?.questionAnswer?.answer}
                                      </Typography>
                                    </Grid>
                                  </>
                                )} */}
                              </Grid>
                            </Grid>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AppointmentCancelModel;
