import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
  loading: false,
  vehicleData: {},
  provinces: [],
};

//DEFINE ACCOUNT REDUCER FUNCTION
const VehicleReducer = (state = initState, action) => {
  switch (action.type) {
    case API.vehicle.getVehicleListByUserID:
      return {
        ...state,
        vehicleData: action.vehicleData,
        loading: action.loading,
      };
    case API.vehicle.getProvinces:
      return {
        ...state,
        provinces: action.provinces,
        loading: action.loading,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default VehicleReducer;
