import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  APPROVALS_ACCEPTED,
  APPROVALS_DECLINED,
  APPROVALS_WAITING,
} from "../../App";
import { getServiceSubCategory } from "../../redux/Approvals/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Approvals = () => {
  const pageRef = useRef(0);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const nameArray = window.location.pathname.split("/");
  const name = nameArray[nameArray.length - 1];
  const [ApprovalsListing, setApprovalListing] = useState([]);
  const ApprovalsListingData = useSelector(
    (state) => state?.approvals?.getApprovalsListing
  );
  useEffect(() => {
    setApprovalListing(ApprovalsListingData);
  }, [ApprovalsListingData]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = useState(false);
  const [countedData, setCountedData] = useState({
    waiting: "",
    responded: "",
    deferred: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [listingOfApproval, setListingOfApproval] = useState();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    pageRef.current = 0;

    ListingOfApprovals(
      true,
      name,
      1,
      searchValue,
      parseInt(event.target.value)
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pageRef.current = newPage;
    ListingOfApprovals(true, name, newPage + 1, searchValue, rowsPerPage);
  };

  const [value, setValue] = React.useState(0);
  useEffect(() => {
    setListingOfApproval({});
    const nameArray = window.location.pathname.split("/");
    const name = nameArray[nameArray.length - 1];
    if (name == "waiting") {
      setValue(0);
    }
    if (name == "responded") {
      setValue(1);
    }
    if (name == "deferred") {
      setValue(2);
    }
  }, [window.location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setApprovalListing([]);

    if (newValue == 0) {
      navigate("/approvals/waiting");
    }
    if (newValue == 1) {
      navigate("/approvals/responded");
    }
    if (newValue == 2) {
      navigate("/approvals/deferred");
    }
  };

  // const handleChange = (event, newValue) => {
  //     setValue(newValue);
  // };
  const { id } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  const navigate = useNavigate();

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  useEffect(() => {
    setPage(0);
    pageRef.current = 0;
    setSearchValue("");
    ListingOfApprovals(true, name, 1, "", rowsPerPage);
  }, [window.location.pathname]);

  const ListingOfApprovals = async (
    loading,
    category,
    page,
    search,
    rowsPerPage
  ) => {
    if (loading) setLoader(true);
    let res = await dispatch(
      getServiceSubCategory(rowsPerPage, category, page, search)
    );
    setListingOfApproval(res);

    pageRef.current = page - 1;

    if (loading) setLoader(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSerachVal(searchValue);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const setSerachVal = (value) => {
    if (value !== "" || searchValue == "") {
      setPage(1);
      pageRef.current = 1;

      ListingOfApprovals(true, name, 1, value, rowsPerPage);
    }
  };

  function capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  return (
    <>
      {" "}
      <Dialog fullScreen={fullScreen} open={loader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Box sx={{ bgcolor: "background.main", padding: '32px 24px', width:'100%' }}>
        <Grid container>
          <Grid container item justifyContent={"space-between"}>
            <Grid item lg={9}>
              <Typography variant="h4" sx={{ fontWeight: 400, padding:'16px 0px 16px 0px' }}>
                Approvals{" "}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate("/Approvals/new");
                }}
              >
                NEW Approvals
              </Button>
            </Grid> */}
          </Grid>
            <Grid
              container
              item
              // gap={2}
              margin={"12px 0"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item container alignItems={"center"}>
                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="aproval-tab_color"
                    >
                      <Tab
                        label={
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            lineHeight={"24px"}
                          >
                            WAITING &nbsp; &nbsp;
                            <Typography
                              sx={{
                                backgroundColor: "accent.yellow",
                                borderRadius: "4px",
                                color: "black",
                              }}
                              height={25}
                              width={25}
                            >
                              {listingOfApproval?.waiting}
                            </Typography>
                          </Stack>
                        }
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            lineHeight={"24px"}
                          >
                            RESPONDED &nbsp; &nbsp;{" "}
                            <Typography
                              sx={{
                                backgroundColor: "accent.main",
                                borderRadius: "4px",
                                color: "black",
                              }}
                              height={25}
                              width={25}
                            >
                              {listingOfApproval?.responded}
                            </Typography>
                          </Stack>
                        }
                        {...a11yProps(1)}
                      />
                      <Tab
                        label={
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            lineHeight={"24px"}
                          >
                            DEFERRED &nbsp; &nbsp;
                            <Typography
                              sx={{
                                backgroundColor: "black",
                                borderRadius: "4px",
                                color: "white",
                              }}
                              height={25}
                              width={25}
                            >
                              {listingOfApproval?.deferred}
                            </Typography>
                          </Stack>
                        }
                        {...a11yProps(2)}
                      />
                    </Tabs>
                  </Box>
                  {/* value={value} index={0} */}
                  <CustomTabPanel padding={"24px"}>
                    <Grid container item>
                      <Grid
                        container
                        marginBottom={"32px"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        // columnGap={1}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormControl fullWidth lineHeight={"24px"}>
                            <InputLabel
                              htmlFor="outlined-adornment-search"
                              lineHeight={"24px"}
                            >
                              Search
                            </InputLabel>
                            <OutlinedInput
                              style={{ lineHeight: "24px" }}
                              id="outlined-adornment-search"
                              placeholder="Name, category, etc..."
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                              label="Search"
                              value={searchValue}
                              onChange={(value) => {
                                setSearchValue(value.target.value);
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        padding={"24px"}
                        boxShadow={2}
                        bgcolor={"white"}
                      >
                        {listingOfApproval?.data?.length == 0 &&
                        loader == false ? (
                          <div className="not-found-class">
                            Sorry, we could not find any matches.
                          </div>
                        ) : (
                          <Grid item xl={12} md={12} sm={12} xs={12}>
                            <TableContainer sx={{ maxHeight: 600 }}>
                              <Table
                                stickyHeader
                                aria-label="caption table"
                                padding="16px"
                                style={{ boxShadow: 0 }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ fontWeight: 500 }}>
                                      Appointment ID
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 500 }}>
                                      {" "}
                                      Customer Name
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 500 }}>
                                      {" "}
                                      Date of Request
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 500 }}>
                                      Vehicle
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 500 }}>
                                      Services Added
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 500 }}>
                                      Status
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {listingOfApproval?.data?.map((row) => (
                                    <TableRow
                                      onClick={() =>
                                        navigate(`/approval/${row?._id}`)
                                      }
                                      key={row.appointmentNumber}
                                      hover={true}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {row?.appointmentNumber}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row?.customer?.firstName +
                                          " " +
                                          row?.customer?.lastName}
                                      </TableCell>
                                      <TableCell align="left">
                                        {dayjs(row?.approvalRequestedAt)
                                          .locale("en")
                                          .format("MMMM DD, YYYY")}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row?.vehicleDetails?.year
                                          ? " " + row?.vehicleDetails?.year
                                          : ""}

                                        {row?.vehicleDetails?.vehicleMake?.name
                                          ? " " +
                                            row?.vehicleDetails?.vehicleMake
                                              ?.name
                                          : ""}
                                        {row?.vehicleDetails?.vehicleModel?.name
                                          ? " " +
                                            row?.vehicleDetails?.vehicleModel
                                              ?.name
                                          : ""}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row?.serviceCount}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Chip
                                          label={capitalize(row?.status)}
                                          onClick={handleClick}
                                          style={{
                                            backgroundColor:
                                              row?.status == "waiting"
                                                ? APPROVALS_WAITING
                                                : row?.status == "accepted"
                                                ? APPROVALS_ACCEPTED
                                                : row?.status == "declined"
                                                ? APPROVALS_DECLINED
                                                : row?.status == "responded"
                                                ? "#75DBFF"
                                                : "black",
                                            color:
                                              row?.status == "waiting" ||
                                              row?.status == "responded"
                                                ? "black"
                                                : "white",
                                            fontWeight: "500",
                                            textTransform: "capitalize",
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Grid
                              container
                              item
                              alignItems={"center"}
                              justifyContent="end"
                            >
                              {/* listingOfApproval?.totalPages > 1 */}
                              <Grid item>
                                {loader !== true ? (
                                  <TablePagination
                                    // hasNextPage={false}
                                    // hasPreviousPage={false}
                                    backIconButtonProps={{
                                      disabled:
                                        listingOfApproval?.totalPages <= 1,
                                    }}
                                    colSpan={3}
                                    count={
                                      listingOfApproval?.totalCount
                                        ? listingOfApproval?.totalCount
                                        : "0"
                                    }
                                    rowsPerPage={rowsPerPage}
                                    page={
                                      pageRef.current ? pageRef.current : "0"
                                    }
                                    slotProps={{
                                      select: {
                                        "aria-label": "rows per page",
                                      },
                                      actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                      },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  {/* <CustomTabPanel value={value} index={1}>
                  <Grid
                    container
                    marginBottom={"32px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-search">
                          Search
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-search"
                          placeholder="Name, category, etc..."
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          label="Search"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container item padding={"24px"} boxShadow={2}>
                    <Grid item xl={12} md={12} sm={12} xs={12}>
                      <Table
                        aria-label="caption table"
                        padding="16px"
                        style={{ boxShadow: 0 }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 500 }}>
                              Appointment ID
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {" "}
                              Customer Name
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {" "}
                              Date of Request
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              <Typography fontWeight={500}>Vehicle</Typography>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              <Typography fontWeight={500}>
                                Services Added
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              <Typography fontWeight={500}>Status</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell component="th" scope="row">
                                {row.Inumber}
                              </TableCell>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="left">{row.date}</TableCell>
                              <TableCell align="left">{row.total}</TableCell>
                              <TableCell align="left">{row.Status}</TableCell>
                              <TableCell align="left">{row.Actions}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Grid
                        container
                        item
                        alignItems={"center"}
                        justifyContent="end"
                      >
                        <Grid item>
                          <TablePagination
                            component="div"
                            count={100}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Grid
                    container
                    marginBottom={"32px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-search">
                          Search
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-search"
                          placeholder="Name, category, etc..."
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          label="Search"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container item padding={"24px"} boxShadow={2}>
                    <Grid item xl={12} md={12} sm={12} xs={12}>
                      <Table
                        aria-label="caption table"
                        padding="16px"
                        style={{ boxShadow: 0 }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 500 }}>
                              Appointment ID
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {" "}
                              Customer Name
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              {" "}
                              Date of Request
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              <Typography fontWeight={500}>Vehicle</Typography>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              <Typography fontWeight={500}>
                                Services Added
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>
                              <Typography fontWeight={500}>Status</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell component="th" scope="row">
                                {row.Inumber}
                              </TableCell>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="left">{row.date}</TableCell>
                              <TableCell align="left">{row.total}</TableCell>
                              <TableCell align="left">{row.Status}</TableCell>
                              <TableCell align="left">{row.Actions}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Grid
                        container
                        item
                        alignItems={"center"}
                        justifyContent="end"
                      >
                        <Grid item>
                          <TablePagination
                            component="div"
                            count={100}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomTabPanel> */}
                </Box>
              </Grid>
            </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Approvals;
