import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
// import IconButton from "@material-ui/core/IconButton";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useNavigate } from "react-router-dom";
import { CheckBox, Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { handleChangePassword, handleForgot } from "../../../redux/Auth/action";
import Modals from "./Modals";
import SuccessMessageModal from "../../Common/SuccessMessage";
import Loader from "../../Common/Loader";
import { getPasswordUpdate } from "../../../redux/Account/peronalIfno/action";

// import Input from "@mui/material/Input";
// import FilledInput from "@mui/material/FilledInput";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormHelperText from "@mui/material/FormHelperText";
// import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const PasswordAndSecurity = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [nextOpen, setNexOpen] = React.useState(false);
  const [nextNexOpen, setNexNexOpen] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [currentPasswordLengthValid, setCurrentPasswordLengthValid] =
    useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [validUppercase, setValidUppercase] = useState(false);
  const [validSpacialCharacter, setValidSpacialCharacter] = useState(false);
  const [confirmPasswordMatch, setConfirmPasswordMatch] = useState("");
  const [passwordUpdated, setPasswordUpdated] = useState("");
  const [validationError, setValidationError] = useState("");

  // const checkValidationError = (passwordLengthValid,validNumber,validUppercase, validSpacialCharacter) => {
  //   if (!(passwordLengthValid&&validNumber)) {
  //     setValidationError(true); // Set to true if both are empty

  //   } else {
  //     setValidationError(false);
  //   }
  // };
  const checkValidationError = (password) => {
    const isLengthValid = password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(
      password
    );

    setValidationError(
      !(isLengthValid && hasNumber && hasUppercase && hasSpecialCharacter)
    );
  };

  // const [showPassword, setShowPassword] = React.useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const handleOpen = () => {
    setOpen(true);
    // setNexNexOpen(true);
    // if (nextNexOpen) {
    //     setNexNexOpen(false)
    // }
    // else {
    //     setNexNexOpen(true)
    // }
  };

  const handleNextOpen = () => {
    setNexOpen(true);
    // setOpen(false);
  };

  const handleNextNextOpen = () => {
    setNexNexOpen(true);
    // setOpen(false);
    // setNexOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setNexNexOpen(false);
    setNexOpen(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // handleSubmit(onSubmit)();
    }
  };
  const [showPassword, setShowPassword] = React.useState({
    one: false,
    two: false,
    three: false,
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmitChangePass = async (data) => {
    setLoading(true);

    try {
      const { password, oldPassword, confirmPassword } = data;

      // Perform password validations
      const isLengthValid = password.length >= 8; // Minimum length requirement
      const hasNumber = /\d/.test(password); // Check for at least one digit
      const hasUppercase = /[A-Z]/.test(password); // Check for at least one uppercase letter
      const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      const isPasswordMatch = password === confirmPassword;

      // Update state based on validations
      setPasswordLengthValid(isLengthValid);
      setValidNumber(hasNumber);
      setValidUppercase(hasUppercase);
      setValidSpacialCharacter(hasSpecialCharacter);
      setConfirmPasswordMatch(isPasswordMatch);

      // Check if all validations pass
      // Check if all validations pass

      if (
        isLengthValid &&
        hasNumber &&
        hasUppercase &&
        hasSpecialCharacter &&
        isPasswordMatch
      ) {
        let res = await dispatch(handleChangePassword(data));

        if (res) {
          setOpenSuccessModal(true);
          setSuccessMessage("Changes Saved");
          setValue("confirmPassword", "");
          setValue("oldPassword", "");
          setValue("password", "");
          setPasswordLengthValid(false);
          setValidNumber(false);
          setValidUppercase(false);
          setValidSpacialCharacter(false);
          setConfirmPasswordMatch("");
          setValidationError(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const cancelOFPassword = () => {
    setValue("confirmPassword", "");
    setValue("oldPassword", "");
    setValue("password", "");
    setValidNumber(false);
    setValidUppercase(false);
    setValidSpacialCharacter(false);
    setPasswordLengthValid(false);
  };

  const EmailVerification = async (data) => {
    let res = await dispatch(handleForgot(data));
    if (res) handleNextOpen(true);
  };
  const onInvalid = (errors) => console.error(errors);

  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      setPasswordLengthValid(true);
    } else {
      setPasswordLengthValid(false);
    }
  };
  const checkCurrentPasswordLength = (password) => {
    if (password.length >= 8) {
      setCurrentPasswordLengthValid(false);
    } else {
      setCurrentPasswordLengthValid(true);
    }
  };
  const checkConfirmPasswordMatch = (password, confirmPassword) => {
    if (password === "" && confirmPassword === "") {
      setConfirmPasswordMatch(true); // Set to true if both are empty
    } else if (password === confirmPassword) {
      setConfirmPasswordMatch(true);
    } else {
      setConfirmPasswordMatch(false);
    }
  };

  const updatePassword = async () => {
    const res = await dispatch(getPasswordUpdate());

    setPasswordUpdated(res?.passwordUpdatedAt);
  };

  useEffect(() => {
    updatePassword();
  }, []);

  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setValidSpacialCharacter(true);
    } else {
      setValidSpacialCharacter(false);
    }
  };
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setValidUppercase(true);
    } else {
      setValidUppercase(false);
    }
  };
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setValidNumber(true);
    } else {
      setValidNumber(false);
    }
  };

  return (
    <Box
      sx={{ width: "100%", height: "100%", boxShadow: 3, bgcolor: "white" }}
      padding={"24px"}
      marginTop={"32px"}
      borderRadius={"10px"}
    >
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Grid
        container
         justifyContent={"space-between"}
        style={{ display: "flex" }}
      >
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <Modals />
        </Grid>
        <Grid item xs={12} sm={6} md={9} xl={9}>
          <form
            onSubmit={handleSubmit(onSubmitChangePass)}
            style={{ marginTop: "20px" }}
          >
            <Grid container item xs={12} sm={12} md={12} xl={12} spacing={3}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <div className="password">
                  <Typography variant="body1" className="label-500">
                    Current Password
                  </Typography>
                  <Controller
                    name="oldPassword"
                    control={control}
                    // rules={{ required: "Password is required" }}
                    rules={{
                      required: "Password is required",
                      minLength: 8,
                    }}
                    render={({ field }) => (
                      <TextField
                        /*label="current Password"*/
                        variant="outlined"
                        // helperText="Last Changed: 8/09/2023"
                        fullWidth
                        type={showPassword.one ? "text" : "password"} // <-- This is where the magic happens
                        // onChange={someChangeHandler}
                        required
                        // required
                        error={!!errors.oldPassword}
                        helperText={
                          errors?.oldPassword
                            ? errors?.oldPassword?.message
                            : ""
                        }
                        {...field}
                        onChange={(e) => {
                          setValue("oldPassword", e.target.value);
                          checkCurrentPasswordLength(e.target.value);
                        }}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  const newData = { ...showPassword };
                                  newData.one = !newData.one;
                                  setShowPassword(newData);
                                }}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword.one ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                    )}
                  />
                  {currentPasswordLengthValid ? (
                    <Typography color={"error"}>
                      Current password length should be 8
                    </Typography>
                  ) : (
                    ""
                  )}
                  <Typography
                    className="last-changed-font"
                    sx={{ padding: "10px 14px", color: "text/secondary" }}
                  >
                    {/* {passwordUpdated} */}
                    Last Changed:
                    {passwordUpdated && passwordUpdated
                      ? dayjs(passwordUpdated).format("DD/MM/YYYY")
                      : ""}
                    {/* Last Changed: 8/09/2023 */}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <div className="password">
                  <Typography variant="body1" className="label-500">
                    New Password
                  </Typography>
                  <Controller
                    name="password"
                    control={control}
                    // rules={{ required: "Password is required" }}
                    rules={{
                      required: "Password is required",
                      minLength: 8,
                      pattern: {
                        message:
                          "Password must be at least 8 characters long, contain at least one number, one special character, and one uppercase letter.",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        type={showPassword.two ? "text" : "password"}
                        required
                        error={!!errors.password}
                        {...field}
                        onChange={(e) => {
                          setValue("password", e.target.value);
                          checkPasswordLength(e.target.value);
                          checkSpecialCharacters(e.target.value);
                          checkUppercase(e.target.value);
                          checkNumber(e.target.value);
                          setPassword(e.target.value);
                          checkValidationError(e.target.value);
                        }}
                        helperText={
                          errors?.password ? errors?.password?.message : ""
                        }
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  const newData = { ...showPassword };
                                  newData.two = !newData.two;
                                  setShowPassword(newData);
                                }}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword.two ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <Typography color={"error"}>
                  {validationError
                    ? "Your password does meet the requirements."
                    : " "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <div className="password">
                  <Typography variant="body1" className="label-500">
                    Confirm Password
                  </Typography>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        /*label="Confirm Password"*/
                        variant="outlined"
                        fullWidth
                        type={showPassword.three ? "text" : "password"} // <-- This is where the magic happens
                        // onChange={someChangeHandler}

                        error={!!errors.confirmPassword}
                        helperText={
                          errors.confirmPassword
                            ? errors.confirmPassword.message
                            : ""
                        }
                        required
                        {...field}
                        onChange={(e) => {
                          setValue("confirmPassword", e.target.value);
                          checkConfirmPasswordMatch(password, e.target.value);
                        }}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                // onClick={handleClickShowPassword}
                                onClick={() => {
                                  const newData = { ...showPassword };
                                  newData.three = !newData.three;
                                  setShowPassword(newData);
                                }}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword.three ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {confirmPasswordMatch === "" ? (
                    ""
                  ) : confirmPasswordMatch === false ? (
                    <Typography
                      sx={{ marginTop: "8px" }}
                      variant="body"
                      color={"error"}
                    >
                      New password and Confirm password do not match
                    </Typography>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>

              <Grid
                item
                justifyContent={"start"}
                spacing={2}
                style={{ marginTop: "20px" }}
              >
                <Stack spacing={2} direction="column" marginLeft={"16px"}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Password Qualifications
                  </Typography>
                  <Stack
                    spacing={2}
                    direction={"row"}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    {" "}
                    <CheckCircleIcon
                      style={{
                        color: passwordLengthValid ? "green" : "gray",
                        marginRight: "20px",
                      }}
                    />{" "}
                    Password must be at least 8 characters.
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    <CheckCircleIcon
                      style={{
                        color: validNumber ? "green" : "gray",
                        marginRight: "20px",
                      }}
                    />
                    At least 1 Number
                  </Stack>
                  <Stack
                    spacing={2}
                    direction={"row"}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    {" "}
                    <CheckCircleIcon
                      style={{
                        color: validSpacialCharacter ? "green" : "gray",
                        marginRight: "20px",
                      }}
                    />{" "}
                    1 Special Character
                  </Stack>
                  <Stack
                    spacing={2}
                    direction={"row"}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    {" "}
                    <CheckCircleIcon
                      style={{
                        color: validUppercase ? "green" : "gray",
                        marginRight: "20px",
                      }}
                    />{" "}
                    1 Uppercase Letter
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"end"}
              spacing={2}
              style={{ marginTop: "20px" }}
            >
              <Grid item spacing={2}>
                <Stack spacing={2} direction="row">
                  <Button
                    className="btn-text-size-manage"
                    variant="outlined"
                    color="primary"
                    onClick={cancelOFPassword}
                  >
                    Cancel Changes
                  </Button>
                  <Button
                    className="btn-text-size-manage"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>{" "}
        </Grid>
      </Grid>{" "}
      <Loader showLoader={loading} />
    </Box>
  );
};

export default PasswordAndSecurity;
